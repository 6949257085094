import React from 'react';

const Checked = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Raggruppa_1386" data-name="Raggruppa 1386" transform="translate(-257 -1382)">
                <path id="Icon_ionic-md-checkbox-outline" data-name="Icon ionic-md-checkbox-outline" d="M22.278,22.278H6.722V6.722H17.833V4.5H6.722A2.229,2.229,0,0,0,4.5,6.722V22.278A2.229,2.229,0,0,0,6.722,24.5H22.278A2.229,2.229,0,0,0,24.5,22.278V13.389H22.278Z" transform="translate(252.5 1377.5)" fill="#3fb6e8"/>
                <path id="Icon_ionic-md-checkbox-outline-2" data-name="Icon ionic-md-checkbox-outline" d="M9.944,12.389,8.389,13.944l5,5L24.5,7.833,22.945,6.278l-9.556,9.5Z" transform="translate(252.5 1377.5)" fill="#3fb6e8"/>
            </g>
        </svg>
    )
}

export default Checked;