import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useLocation, useNavigate} from 'react-router-dom'
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import {Button, InputAdornment, Slide, TextField} from "@mui/material";
import wideLogoLightBetaBig from '../../images/wide-logo-white.svg';
import back from "../../images/back-icon.svg";
import wideLogoLight from "../../images/wideLogo-icon.svg";
import emailPlaceholder from "../../images/email-placeholder.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import floodPlaceholder from "../../images/flood-placeholder.svg";
import DialogSmall from "../components/DialogSmall.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function RequestResetPassword() {
    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const defaultValues = {
        email: ""
    };
    const formValues = useRef(defaultValues);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = sessionStorage.getItem('token');
    const requestResetPasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/startResetPassword`;
    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [emailError, setEmailError] = useState(false)
    const [serviceError, setServiceError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const notFoundTitleError = `${t('requestCredentials.user-not-found-title')}`
    const titleError = `${t('requestCredentials.error-title')}`
    const notFoundError1 = `${t('requestCredentials.user-not-found-description-1')}`
    const notFoundError2 = `${t('requestCredentials.user-not-found-description-2')}`
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [openFloodDialog, setOpenFloodDialog] = useState(false)


    useEffect(() => {
        if (navigator.onLine) {
            //
        }
    }, []);


    const getLang = () => {
        switch (navigator.language) {
            case 'it':
            case 'it-IT':
                return 'it';
            case 'en':
            case 'en-EN':
            case 'en-US':
                return 'en';
            case 'de':
            case 'de-DE':
                return 'de';
            default:
                return 'it';
        }
    }

    const startResetPassword = () => {
        setIsLoadingData(true)
        const enteredEmail = formValues.current.email;
        if (enteredEmail !== "") {
            fetch(requestResetPasswordUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: enteredEmail
                }),
            }).then((res) => {
                return res.json();
            }).then((res) => {
                if (res.ok) {
                    setIsCompleted(true)
                } else {
                    setIsCompleted(false)
                    if (res.message === 'err.email.restore.flood') {
                        setOpenFloodDialog(true)
                    } else {
                        if (res.status === 400) {
                            setErrorTitle(notFoundTitleError)
                            setErrorMessage(notFoundError1 + ` ` + enteredEmail + ` ` + notFoundError2)
                            formValues.current.email = ""
                        } else {
                            setErrorTitle(titleError)
                            setErrorMessage(enteredEmail + ` ` + res.error[0].message)
                            formValues.current.email = ""
                        }
                        setOpenDialog(true)
                    }
                }
                setIsLoadingData(false)
            }).catch(() => {
                setServiceError(true)
            })
        } else {
            setIsLoadingData(false)
            setEmailError(true)
        }
    }

    const handleInputChange = (e) => {
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const navigateToLogin = () => {
        return navigate('/login');
    }

    const handleClose = () => {
        setOpenDialog(false);
    };


    return (
        <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image">
                    <Row style={{'margin': 'auto'}}>
                        <Col>
                            <img style={{
                                'height': '74.67px',
                                'marginBottom': '27px'
                            }} src={wideLogoLightBetaBig} alt="wide-logo-light"/>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-1')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-2')}</h6>
                            <h6 style={{
                                'color': themeContext.color.white,
                                'fontSize': '16px',
                                'fontWeight': '300'
                            }}>{t('login.payoff-left-3')}</h6>
                        </Col>
                    </Row>
                </div>
                <div className="col-md-6">
                    <div className="login d-flex">
                        <div className="container">
                            <Row className="d-flex justify-content-center">
                                {
                                    serviceError &&
                                    <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                                        <Row className="nav-top container" style={{
                                            'backgroundColor': themeContext.color.white,
                                            'paddingTop': '19.5px',
                                            'paddingLeft': '0',
                                            'paddingRight': '0',
                                        }}>
                                            <Col>
                                                <Row className="first-nav-top-row-shadow">
                                                    <Col className="col-2 float-left">
                                                        <img src={back} onClick={turnBack}
                                                             className="nav-top-logo float-left cursor-pointer"
                                                             height="27px"
                                                             alt="back"/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Container style={{'paddingTop': '25%'}}></Container>
                                        <Row>
                                            {
                                                <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'}
                                                     className="noMessages"
                                                     alt="service-error-image"></img>
                                            }
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span
                                                    className="no-messages-text">{t('serviceError.service-error-text')}</span>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '5%'}}>
                                            <Col>
                                                <a className="reload-btn"
                                                   onClick={reload}>{t('serviceError.reload')}</a>
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                                {!serviceError && isLoadingData &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }

                                {!serviceError && !isLoadingData && !isCompleted &&
                                    <Container style={{'backgroundColor': themeContext.color.white}}>
                                        <Row className="nav-top container"
                                             style={{
                                                 'backgroundColor': themeContext.color.white,
                                                 'paddingTop': '19.5px',
                                                 'paddingBottom': '20px',
                                                 'paddingLeft': '0',
                                                 'paddingRight': '0',
                                             }}>
                                            <Col className="col-2 float-left">
                                                <img src={back} onClick={turnBack}
                                                     className="nav-top-logo float-left cursor-pointer"
                                                     height="27px"
                                                     alt="back"/>
                                            </Col>
                                        </Row>
                                        <Container className="top-of-the-preferences"></Container>
                                        <Container>
                                            <div className=" justify-content-center ">
                                                <Row className="justify-content-center" style={{'marginTop': '35%'}}>
                                                    <Row>
                                                        <img className="wide-logo" src={wideLogoLight} height="50px"
                                                             alt="wide-logo"/>
                                                    </Row>
                                                    <Row style={{'marginTop': '32px'}}>
                                                        <Col>
                                                            <h6 className="login-title align-center">{t('requestCredentials.title-1')}</h6>
                                                            <h6 className="login-title align-center">{t('requestCredentials.title-2')}</h6>
                                                            <h6 className="mt-4" style={{
                                                                'color': themeContext.color.neutral600,
                                                                'fontSize': '14px'
                                                            }}>{t('requestCredentials.description-1')}</h6>
                                                        </Col>
                                                    </Row>
                                                    <TextField
                                                        id="email"
                                                        className="custom-textfield mb-4"
                                                        style={{
                                                            'marginTop': '48px'
                                                        }}
                                                        fullWidth
                                                        label={t('requestCredentials.email')}
                                                        variant="outlined"
                                                        name="email"
                                                        type="email"
                                                        value={formValues.email}
                                                        onChange={handleInputChange}
                                                        color="primary"
                                                        error={emailError}
                                                        InputProps={{
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600,
                                                                //'height': '50px'
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                    <span className={emailError ? "material-icons red" : "material-icons darkgrey"}
                                          style={{'transform': 'rotate(90deg)'}}>key</span>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <Row style={{
                                                        'marginTop': '60px'
                                                    }}>
                                                        <Col>
                                                            <Button className="btn-custom text-uppercase"
                                                                    variant="outlined"
                                                                    size="large"
                                                                    type="submit"
                                                                    style={{
                                                                        'color': themeContext.color.white,
                                                                        'width': '190px'
                                                                    }}
                                                                    onClick={startResetPassword}
                                                            >{t('requestCredentials.button')}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </div>
                                        </Container>
                                    </Container>
                                }
                                {!serviceError && !isLoadingData && isCompleted &&
                                    <Container style={{'backgroundColor': themeContext.color.white}}>
                                        <div className=" justify-content-center ">
                                            <Row className="justify-content-center" style={{'marginTop': '35%'}}>
                                                <Row>
                                                    <img src={emailPlaceholder}
                                                         alt="email-sent"/>
                                                </Row>
                                                <Row style={{'marginTop': '32px', 'width': '100%'}}>
                                                    <Col>
                                                        <h6 className="login-title align-center">{t('requestCredentials.isCompleted-title')}</h6>
                                                        <h6 className="mt-4" style={{
                                                            'color': themeContext.color.neutral600,
                                                            'fontSize': '14px'
                                                        }}>{t('requestCredentials.isCompleted-text-1')}</h6>
                                                        <h6 className="mt-4" style={{
                                                            'color': themeContext.color.neutral600,
                                                            'fontSize': '14px'
                                                        }}>{t('requestCredentials.isCompleted-text-2')}</h6>
                                                        <h6 className="mt-4" style={{
                                                            'color': themeContext.color.neutral600,
                                                            'fontSize': '14px'
                                                        }}>{t('requestCredentials.isCompleted-text-3')}</h6>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    'marginTop': '60px'
                                                }}>
                                                    <Col>
                                                        <Button className="btn-custom-3 text-uppercase"
                                                                variant="outlined"
                                                                size="large"
                                                                type="submit"
                                                                style={{
                                                                    'color': themeContext.color.white,
                                                                    'width': '246px'
                                                                }}
                                                                onClick={navigateToLogin}
                                                        >{t('requestCredentials.isCompleted-button')}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </div>
                                    </Container>
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <DialogSmall open={openDialog}
                         title={errorTitle}
                         description={errorMessage}
                         handleClose={handleClose}
                         buttonText={t('requestCredentials.user-not-found-button')}/>
            <Dialog
                components={{Backdrop: StyledBackdrop}}
                onClose={() => {
                    setOpenFloodDialog(false)
                }}
                fullScreen={!isDesktop}
                fullWidth={isDesktop}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        bottom: isDesktop ? '5%' : '0',
                        color: themeContext.color.neutral600,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        borderRadius: isDesktop ? '20px' : 'none',
                        height: isDesktop ? '583px' : '100%',
                        marginTop: '10%'
                    }
                }}
                open={openFloodDialog}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                             style={{textAlign: 'center'}}>
                    <Row style={{justifyContent: 'end'}}>
                        <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                            setOpenFloodDialog(false)
                        }} style={{float: 'inline-end'}}/>
                    </Row>
                </DialogTitle>
                <DialogContent>
                    <Container className="dialog-main-container">
                        <Row>
                            <Col>
                                <img src={floodPlaceholder} alt="email-error" style={{maxHeight: '258px', width: '100%'}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="ml-4 mr-4 mt-4">
                                    <Col className="mt-4 text-center dialog-form-title-3 red font-weight-bold">
                                        {t('requestResetPassword.dialog-message')}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Container className={!isDesktop ? 'fixed-bottom' : {}} style={
                                       isDesktop ? {
                                           background: 'transparent',
                                           opacity: '1',
                                           marginTop: '48px'
                                       } : {
                                           background: 'transparent',
                                           opacity: '1',
                                           paddingBottom: "48px"
                                       }
                                   }>
                            <Row style={{textAlign: 'center'}}>
                                <Col>
                                    <Button className="close-button boxShadow"
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            disabled={false}
                                            onClick={() => {
                                                setOpenFloodDialog(false)
                                            }}
                                    >{t('requestResetPassword.dialog-button')}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    )
}