import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Button} from "@mui/material";
import back from "../../images/back-icon.svg";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import {useForm} from "react-hook-form"
import MaterialPhoneNumber from '../middleware/PhoneNumber.js'
import exit from "../../images/exit-icon.svg";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import DialogSmall from "../components/DialogSmall.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

export default function OtpVerifyID() {
    const {control, handleSubmit} = useForm()

    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const navigate = useNavigate();
    const sendOtpUrl = `${process.env.REACT_APP_BASE_URL}/management/otp/send`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [openOTPDialog, setOpenOTPDialog] = useState(false);
    const [phoneValue, setPhoneValue] = useState('')
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const personId = params.personId;
    const documentId = query.get('documentId');
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const isCie = query.get('isCie');
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);
    const {t, i18n} = useTranslation();


    useEffect(() => {
        if (navigator.onLine) {
            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                if (authCtx.backReqImageBase64 && authCtx.backReqImage && authCtx.reqImage && authCtx.reqImageBase64) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            } else {
                if (authCtx.reqImage && authCtx.reqImageBase64) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, []);

    const handleOTPClose = () => {
        setOpenOTPDialog(false)
        if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
            return navigate(`/signing/${personId}/uploadBack/uploadFront/identify/otp/confirm?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`)
        } else {
            return navigate(`/signing/${personId}/upload/personalDocuments/${documentGroupId}/identify/otp/confirm?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        }
    }

    const onSubmit = (data) => {
        sessionStorage.setItem('phone', data.phoneInput)
        const lang = document.querySelector("select[name='phoneInputCountry']").value.toLowerCase();
        sessionStorage.setItem('phone-lang', lang)
        fetch(sendOtpUrl, {
            method: 'POST',
            body: JSON.stringify({phone: data.phoneInput, lang: lang}),
            headers: {'Content-Type': 'application/json'},
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.ok) {
                setOpenOTPDialog(true)
            } else {
                setOpenErrorDialog(true)
            }
        });
    }

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
            return navigate(`/signing/${personId}/uploadBack/uploadFront/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`);
        } else {
            return navigate(`/signing/${personId}/upload/personalDocuments/${documentGroupId}/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`);
        }
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('otpVerifyID.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height linear-gradient">
                <div style={{'opacity': '1'}}>
                    <Row className="nav-top container border-bottom"
                         style={{
                             'backgroundColor': themeContext.color.white,
                             'paddingTop': '19.5px',
                             'paddingBottom': '20px',
                             'paddingLeft': '0',
                             'paddingRight': '0',
                         }}>
                        <Col className="col-1 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-10">
                            <h6 className="header-title ellipsis-1-v2">{t('otpVerifyID.title')}</h6>
                        </Col>
                        <Col className="col-1">
                            <img src={exit} onClick={() => setOpenExitDialog(true)}
                                 className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                        </Col>
                    </Row>
                    <Container className="top-of-the-preferences"></Container>
                    <Row className="pr-0 pl-0">
                        <Col className="col-list">
                            {
                                isLoadingData &&
                                <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                            }
                            {
                                !isLoadingData &&
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Container>
                                        <Row style={{'marginTop': '43.5px'}}>
                                            <Col className="pl-5 pr-5">
                                                <span style={{
                                                    'fontSize': '16px',
                                                    'color': themeContext.color.neutral600,
                                                    'fontWeight': 'bold'
                                                }}>
                                                    {t('otpVerifyID.subtitle')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '12px', 'marginBottom': '44.5px'}}>
                                            <Col>
                                                <span style={{
                                                    'fontSize': '14px',
                                                    'color': themeContext.color.neutral600,
                                                    'fontWeight': 'normal'
                                                }}>
                                                    {t('otpVerifyID.description')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{'marginTop': '54px'}}>
                                            <Col>
                                                <PhoneInputWithCountry
                                                    label={t('newOtp.phone')}
                                                    value={phoneValue}
                                                    onChange={setPhoneValue}
                                                    defaultCountry="IT"
                                                    name="phoneInput"
                                                    control={control}
                                                    rules={{required: true}}
                                                    smartCaret
                                                    limitMaxLength
                                                    inputComponent={MaterialPhoneNumber}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '86px'}}>
                                            <Col>
                                                <Button className="btn-custom text-uppercase"
                                                        variant="outlined"
                                                        size="large"
                                                        style={{'color': themeContext.color.white}} type="submit"
                                                >{t('otpVerifyID.button')}</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </form>
                            }
                        </Col>
                    </Row>
                    <DialogDoubleAction open={openExitDialog}
                                        title={t('uploadDocumentToVerify.exit-title')}
                                        description={t('uploadDocumentToVerify.exit-description')}
                                        handleClose={() => setOpenExitDialog(false)}
                                        handleNo={() => setOpenExitDialog(false)}
                                        handleYes={() => {
                                            localStorage.removeItem('base64-wide')
                                            localStorage.removeItem('imageUrl-wide')
                                            navigate('/signing');
                                        }}
                                        buttonYesText={t('uploadDocumentToVerify.exit-yes-button')}
                                        buttonNoText={t('uploadDocumentToVerify.exit-no-button')}/>
                    <DialogSmall open={openOTPDialog}
                                 title={t('newOtp.dialog-title')}
                                 description={t('newOtp.dialog-text')}
                                 handleClose={handleOTPClose}
                                 buttonText={t('newOtp.dialog-button')}/>

                    <DialogSmall open={openErrorDialog}
                                 title={t('otpVerifyID.dialog-error-title')}
                                 description={t('otpVerifyID.dialog-error-description')}
                                 handleClose={() => setOpenErrorDialog(false)}
                                 buttonText={t('newOtp.dialog-button')}/>
                    <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                                 title={t('uploadDocumentToVerify.fallback-dialog-title')}
                                 description={t('uploadDocumentToVerify.fallback-dialog-description')}
                                 handleClose={() => {
                                     if (window.location.href.includes('uploadBack')) {
                                         navigate(`/signing/${personId}/uploadBack?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                     } else {
                                         navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                     }
                                     setOpenFallbackDialog(false)
                                 }}
                                 open={openFallbackDialog}/>
                </div>
            </Container>
    )
}