import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import * as idb from "idb";
import back from "../../images/back-icon.svg";
import dots from "../../images/dots-icon.svg";
import {makeStyles} from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Menu, MenuItem, TextField} from "@mui/material";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import goTo from "../../images/goto-icon.svg";
import sinistri from "../../images/claims-icon.svg";
import policies from "../../images/shield-contracts-icon.svg";
import carIcon from "../../images/car-icon.svg";
import motorbikeIcon from "../../images/motorbike-icon.svg";
import Resizer from "react-image-file-resizer";
import FsLightbox from "fslightbox-react";
import CropDialog from "../components/CropDialog.js";
import {useTranslation} from "react-i18next";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import themeContext from "../components/ThemeContext.js";
import DialogSmall from "../components/DialogSmall.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

export default function VehicleDetail() {
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getVehicle();
        } else {
            getOfflineInfo();
        }
    }, []);

    const useStyles = makeStyles((theme) => ({
        divList: {
            width: '100% !important',
            backgroundColor: `${theme.palette.background.paper} !important`,
            padding: "0 0 0 0 !important"
        },
        list: {
            paddingTop: '0 !important',
            paddingBottom: '30px !important',
        },
        listItem: {
            color: `${themeContext.color.neutral400} !important`,
            padding: '16px 0 0 0 !important',
            fontSize: '18px !important'
        },
        listFullItem: {
            color: `${themeContext.color.neutral400} !important`,
            paddingTop: '18px !important',
            paddingBottom: '18px !important',
            fontSize: '18px !important'
        },
        listTextRight: {
            textAlign: "right !important",
            font: "normal normal bold 12px/22px Inter !important",
            letterSpacing: "0px !important",
            color: `${themeContext.color.neutral400} !important`,
            opacity: "1 !important",
            justifyContent: "end !important"
        },
        avatar: {
            margin: '0 auto !important',
            width: '94px !important',
            height: '94px !important',
        },
    }));

    const [vehicle, setVehicle] = useState([]);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(null);
    const vehicleInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/vehicle`;
    const changeImageUrl = `${process.env.REACT_APP_BASE_URL}/media/vehicle/changeImage`;
    const deleteImageUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/vehicle`;
    const token = sessionStorage.getItem('token');
    const params = useParams();
    const vehicleId = params.vehicleId;
    const [activePoliciesNr, setActivePoliciesNr] = useState(0);
    const [serviceError, setServiceError] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [toggler, setToggler] = useState(false);
    const {t, i18n} = useTranslation();
    const [openDialogDoubleAction, setOpenDialogDoubleAction] = useState(false);
    const hasProfileImage = vehicle.image !== null
    const [image, setImage] = useState(1)
    const [openSizeExceededDialog, setOpenSizeExceededDialog] = useState(false);
    const [openTypeNotSupportedDialog, setOpenTypeNotSupportedDialog] = useState(false);


    const defaultValues = {
        name: vehicle.name,
        plate: vehicle.plate
    };

    const formValues = useRef(defaultValues);

    const classes = useStyles();


    function getVehicle() {
        setIsLoadingData(true)
        fetch(`${vehicleInfoUrl}/${vehicleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setVehicle(data);
            }
            return data.policies;
        }).then((policies) => {
            let count = 0;
            policies.map((item, index) => {
                if (item.status !== -1 && item.status !== 1) {
                    count++;
                }
            })
            setActivePoliciesNr(count)
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getOfflineInfo() {
        const dbPromise = idb.openDB('appDB', 1, {
            upgrade(upgradeDb) {
                if (!upgradeDb.objectStoreNames.contains('profileInfo')) {
                    upgradeDb.createObjectStore('profileInfo', {keyPath: 'key'});
                }
            }
        });
        dbPromise.then((db) => {
            let tx = db.transaction('profileInfo', 'readwrite').objectStore('profileInfo');
            return tx.getAll();
        }).then((array) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].key === 'firstName') {
                    vehicle.firstName = array[i].value;
                    //setFirstName(array[i].value)
                }
                if (array[i].key === 'lastName') {
                    vehicle.lastName = array[i].value;
                    //setLastName(array[i].value)
                }
                if (array[i].key === 'userID') {
                    vehicle.id = array[i].value;
                    //setUserID(array[i].value)
                }
            }
        });
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleNameChange = () => {
        //
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    function turnBack() {
        return navigate(-1);
    }

    function reload() {
        window.location.reload();
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image')) {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
            if (err === 'Maximum upload size exceeded') {
                setOpenSizeExceededDialog(true)
            } else if (err === 'File not supported') {
                setOpenTypeNotSupportedDialog(true)
            }
        }
    };

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName: string = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(changeImageUrl, {
                method: 'POST',
                body: JSON.stringify({
                    vehicleId: vehicleId,
                    mimeType: mimetype,
                    fileName: randomName,
                    base64: base64_string,
                    type: "vehicle"
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false)
                setOpenMenu(false);
                getVehicle()
            }).catch(() => {
                setServiceError(true)
            });
        }
    }

    function handleDeleteImage(image) {
        if (image !== null)
            fetch(`${deleteImageUrl}/${vehicleId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
                .then((responseData) => {
                    if (responseData.ok) {
                        // Image removed successfully
                        setIsLoadingData(true)
                        // Refresh vehicle details
                        fetch(`${vehicleInfoUrl}/${vehicleId}`, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                setVehicle(data);
                                setIsLoadingData(false)
                                setOpenMenu(false)
                                setOpenDialogDoubleAction(false)
                            })
                            .catch(() => {
                                setOpenMenu(false)
                                setOpenDialogDoubleAction(false)
                                setServiceError(true);
                            });
                    } else {
                        // Error removing image
                        setOpenMenu(false)
                        setOpenDialogDoubleAction(false)
                        setServiceError(true);
                    }
                })
                .catch(() => {
                    setOpenMenu(false)
                    setOpenDialogDoubleAction(false)
                    setServiceError(true);
                });
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2"></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row>
                                <Col className='col-2 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{vehicle.plate}</h6>
                                </Col>
                                <Col className="col-2 float-right">
                                    <img src={dots} onClick={handleOpenMenu}
                                         className="nav-top-logo float-right cursor-pointer" alt="dots" height="27px"/>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={openMenu}
                                        keepMounted
                                        open={Boolean(openMenu)}
                                        onClose={handleCloseMenu}
                                        className="menu"
                                    >
                                        <MenuItem className="menu-item" onClick={handleChangeImage}>
                                            <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                                   accept="image/*"/>
                                            {t('personDetail.change-image')}
                                        </MenuItem>
                                        <MenuItem className="menu-item"
                                                  onClick={() => setOpenDialogDoubleAction(true)}
                                                  style={{color: themeContext.color.red,
                                                      display: vehicle.image ? 'flex' : 'none'}}>
                                            <input hidden id="image-file" type="file" accept="image/*"/>
                                            {t('personDetail.delete-image')}
                                        </MenuItem>
                                    </Menu>
                                </Col>
                            </Row>
                            {
                                isLoadingImg ?
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    :
                                    <Row style={{
                                        'marginTop': '33.5px',
                                        'marginBottom': '20px'
                                    }}>
                                        <Col>
                                            {
                                                (vehicle.image === null && vehicle.vehicleType !== "CICLOMOTORE" && vehicle.vehicleType !== "MOTOCICLO") &&
                                                <Avatar src={carIcon} className={classes.avatar} id="vehicle-image"
                                                        style={{
                                                            filter: `${setGrayScale(vehicle.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(vehicle.status)}`
                                                        }}/>
                                            }
                                            {
                                                (vehicle.image === null && (vehicle.vehicleType === "CICLOMOTORE" || vehicle.vehicleType === "MOTOCICLO")) &&
                                                <Avatar src={motorbikeIcon} className={classes.avatar}
                                                        id="vehicle-image"
                                                        style={{
                                                            filter: `${setGrayScale(vehicle.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(vehicle.status)}`
                                                        }}/>
                                            }
                                            {
                                                vehicle.image !== null &&
                                                <Avatar src={vehicle.image} className={classes.avatar}
                                                        id="vehicle-image"
                                                        onClick={() => setToggler(!toggler)}
                                                        style={{
                                                            filter: `${setGrayScale(vehicle.status)}`,
                                                            'border': `${themeContext.spacing.borders.border3} ${setStatusColor(vehicle.status)}`
                                                        }}/>
                                            }
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                    <Container className="top-of-the-person-detail"></Container>
                    {
                        isLoadingData ?
                            <div style={{'height': '60vh'}} className="background-loading-spinner"></div>
                            :
                            <Fragment>
                                <Container style={{
                                    'backgroundColor': themeContext.color.white,
                                    'paddingLeft': '0',
                                    'paddingRight': '0',
                                }}>
                                    <Row>
                                        <Col style={{
                                            'paddingRight': '0px',
                                            'paddingLeft': '19.5px'
                                        }}>
                                            <div className={classes.divList}>
                                                <List className={classes.list}>
                                                    <ListItem className={classes.listItem}>
                                                        <TextField
                                                            label={t('vehicleDetail.name')}
                                                            name="name"
                                                            id="name"
                                                            fullWidth
                                                            className="custom-textField-baseline-disable"
                                                            value={vehicle.modellocostruttore}/>
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <TextField
                                                            label={t('vehicleDetail.plate')}
                                                            name="plate"
                                                            id="plate"
                                                            fullWidth
                                                            className="custom-textField-baseline-disable"
                                                            value={vehicle.plate}/>
                                                    </ListItem>
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            <div>
                                                <List style={{'paddingTop': '0px'}}>
                                                    <Divider/>
                                                    <Link to={`/settings/vehicles/${vehicleId}/policies`}
                                                          style={{'textDecoration': 'none'}}>
                                                        <ListItem className={classes.listFullItem} button>
                                                            <ListItemIcon>
                                                                <img src={policies} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('vehicleDetail.list-policies')}/>
                                                            <ListItemIcon className="justify-content-end">
                                                        <span
                                                            className={classes.listTextRight}>{t('vehicleDetail.list-active-policies')} {activePoliciesNr}</span>
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Link>
                                                    <Divider/>
                                                    <Link to={`/settings/vehicles/${vehicleId}/claims`}
                                                          style={{'textDecoration': 'none'}}>
                                                        <ListItem className={classes.listFullItem} button>
                                                            <ListItemIcon>
                                                                <img src={sinistri} alt="image" width="28px"/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('vehicleDetail.list-claims')}/>
                                                            <ListItemIcon className="justify-content-end">
                                                        <span
                                                            className={classes.listTextRight}>{t('vehicleDetail.list-opened-claims')} {vehicle.openClaimsNr}</span>
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Link>
                                                    <Divider/>
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Fragment>
                    }
                </Container>
                <FsLightbox
                    toggler={toggler}
                    sources={[vehicle.image]}
                    type="image"
                />
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => {
                                if(data) {
                                    setCropper(data)
                                }
                            }}/>
                <DialogDoubleAction
                    open={openDialogDoubleAction}
                    onClose={() => setOpenDialogDoubleAction(false)}
                    title={t('personDetail.delete-image')}
                    description={t('personDetail.dialog-delete-image')}
                    buttonYesText={t('personDetail.remove-image-confirm')}
                    handleYes={() => handleDeleteImage(image)}
                    buttonNoText={t('dialog.button-cancel')}
                    handleNo={() => setOpenDialogDoubleAction(false)}
                    deleteAction={true}
                />
                <DialogSmall open={openSizeExceededDialog} title={t('Maximum upload size exceeded')}
                             buttonText={t('dialog.button-ok')}
                             handleClose={() => {
                                 setOpenSizeExceededDialog(false)
                             }} description={t('The maximum image upload size is 20MB')}/>
                <DialogSmall open={openTypeNotSupportedDialog} title={t('The uploaded format is not supported')}
                             buttonText={t('dialog.button-ok')}
                             handleClose={() => {
                                 setOpenTypeNotSupportedDialog(false)
                             }} description={t('Only image formats are supported')}/>
            </Fragment>
    )
}