import React, {Fragment} from "react";
import {Col, Row} from "react-bootstrap";
/*
*
* */
const Header = ({title, iconSrc, leftSrc, leftAction, rightSrc, rightAction, borderBottom, email}) => {
    return (
        <Fragment>
            <Row
                className={borderBottom ? "container px-0 first-nav-top-row-shadowless nav-top border-bottom" : "first-nav-top-row-shadow"}>
                <Col className="col-2 float-left align-self-center">
                    {leftSrc &&
                        <img className="float-left cursor-pointer" src={leftSrc} onClick={leftAction} height="27px"
                             alt="left action"/>
                    }
                </Col>
                <Col className="col w-100 px-0">
                    {
                        iconSrc &&
                        <img src={iconSrc} height="26px" alt="wide-logo"/>
                    }
                    {
                        title &&
                        <h6 className="header-title ellipsis-1-v2">{title}</h6>
                    }
                </Col>
                <Col className="col-2 float-right align-self-center">
                    {rightSrc &&
                        <img className="float-right cursor-pointer" src={rightSrc} onClick={rightAction} height="27px"
                             alt="right action"/>
                    }
                </Col>
            </Row>
        </Fragment>
    )
}


export default Header;
