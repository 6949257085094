import React from 'react'
import Dialog from "@mui/material/Dialog";
import themeContext from "./ThemeContext.js";
import {DialogTitle, DialogAction, DialogButton, DialogDescription, StyledBackdrop} from "../styles/Styles.js";

const DialogDoubleAction = ({
                                open,
                                handleClose,
                                title,
                                description,
                                buttonYesText,
                                handleYes,
                                buttonNoText,
                                handleNo,
                                deleteAction
                            }) => {
    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{description}</DialogDescription>
            <DialogAction>
                <DialogButton onClick={handleNo} style={{color: themeContext.color.neutral200}}>
                    {buttonNoText}
                </DialogButton>
                {deleteAction ?
                    <DialogButton onClick={handleYes} style={{color: themeContext.color.red}}>
                        {buttonYesText}
                    </DialogButton> :
                    <DialogButton onClick={handleYes} color="primary">
                        {buttonYesText}
                    </DialogButton>
                }
            </DialogAction>
        </Dialog>
    )
}
export default DialogDoubleAction;