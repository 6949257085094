import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import themeContext from "./ThemeContext.js";

const Chips = ({chips, onChange, selected}) => {
    const [selectedChip, setSelectedChip] = useState(selected ? parseInt(selected) : chips[0].value);

    const handleChip = (item) => {
        setSelectedChip(item)
        onChange(item)
    }

    return (
        <Row className={'container px-0 bg-white'} style={{paddingTop: 24, paddingBottom: 24}}>
            <Col className={'chip-container no-scrollbar px-0'}>
                <div className={'chip-row'}>
                    {chips.map((chip, index) => (
                        <div key={index} className={selectedChip === chip.value ? 'chip-selected' : 'chip'}
                             onClick={() => handleChip(chip.value)}>
                            {chip.label}
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default Chips;