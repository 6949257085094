import React from 'react'
import Dialog from "@mui/material/Dialog";
import themeContext from "./ThemeContext.js";
import {DialogAction, DialogButton, DialogDescription, DialogTitle, StyledBackdrop} from "../styles/Styles.js";

const DialogSmall = ({open, handleClose, title, description, buttonText}) => {
    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>
                {typeof description === 'string' ?
                    <span dangerouslySetInnerHTML={{__html: description}}/> : description}
            </DialogDescription>
            <DialogAction>
                <DialogButton onClick={handleClose} color="primary">
                    {buttonText}
                </DialogButton>
            </DialogAction>
        </Dialog>
    )
}
export default DialogSmall;