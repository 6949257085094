import React, {Fragment, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import nothing from '../../images/nothing-icon.svg';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/it';
import back from '../../images/back-icon.svg';
import 'react-swipeable-list/dist/styles.css';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import car from "../../images/car-blue.svg";
import activeFromIcon from "../../images/active-from-icon.svg";
import {Button} from "@mui/material";
import useAnalyticsEventTracker from "../middleware/useAnalyticsEventTracker.js";
import {useMediaQuery} from "react-responsive";
import cardPlaceholder_min from "../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../images/card-placeholder_full.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important",
        width: "100% !important",
    },
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    cardTextRight: {
        color: `${themeContext.color.neutral400} !important`,
        textAlign: 'right !important',
        lineHeight: '0.6 !important',
        fontSize: '14px !important'
    },
    renewButton: {
        fontWeight: '600 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        letterSpacing: '2.07px !important',
        color: `${themeContext.color.mainActive} !important`,
        textTransform: 'uppercase !important',
    }
}));


export default function PeoplePolicies(styles) {
    const [isLoadingSX, setIsLoadingSX] = useState(true);
    const [isLoadingDX, setIsLoadingDX] = useState(true);
    const {t, i18n} = useTranslation();
    const params = useParams();
    const personId = params.personId;

    useEffect(() => {
        getPerson();
        getPeoplePolicies();
    }, []);

    const [activePolicies, setActivePolicies] = useState([]);
    const [notActivePolicies, setNotActivePolicies] = useState([]);
    const [person, setPerson] = useState([]);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const [tab, setTab] = useState(sessionStorage.getItem('tab_peoplePolicies') ? parseInt(sessionStorage.getItem('tab_peoplePolicies')) : 0);
    const classes = useStyles();
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const peoplePoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/people`;
    const [serviceError, setServiceError] = useState(false);
    const gaEventTrackerPolicyDetail = useAnalyticsEventTracker('PolicyDetail');


    let tabStyle = {
        borderBottom: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    };
    let tabClass = {
        //
    };
    let labelStyle = {
        textTransform: "none",
        fontWeight: "700"
    };

    const token = sessionStorage.getItem('token');

    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })

    function handleTabs(event, value) {
        setTab(value);
        sessionStorage.setItem('tab_peoplePolicies', value);
    }

    function getPeoplePolicies() {
        setIsLoadingSX(true);
        setIsLoadingDX(true);
        fetch(`${peoplePoliciesUrl}?personId=${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((item, index) => {
                if (item.status !== -1) {
                    if (item.status !== 1) {
                        setActivePolicies(current => [...current, item]);
                    } else {
                        setNotActivePolicies(current => [...current, item]);
                    }
                }
            })
            setIsLoadingDX(false);
            setIsLoadingSX(false);
        }).catch(() => {
            setServiceError(true)
        })
    }

    function reload() {
        window.location.reload();
    }

    function getPerson() {
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPerson(data);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const onClickPolicy = (id) => {
        gaEventTrackerPolicyDetail('Click', 'Clicked Policy Detail Button')
        return navigate(`/settings/people/${personId}/policies/${id}`);
    }

    function turnBack() {
        sessionStorage.removeItem('tab_peoplePolicies');
        return navigate(-1);
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2"></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                        'backgroundColor': themeContext.color.neutral10,
                        //'borderLeft': '1px solid #EEE9E5',
                        //'borderRight': '1px solid #EEE9E5'
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className='col-6 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px" alt="back"/>
                                </Col>
                            </Row>
                            <Row style={{
                                paddingTop: '11px',
                                background: themeContext.color.white
                            }}>
                                <Col className="col-10">
                                    <h2 className="float-left title-page ellipsis-1">{t('policies.title')}
                                        {
                                            ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                <span> {person.name}</span> :
                                                <span> {person.firstName}</span>
                                        }
                                    </h2>
                                </Col>
                                <Col className="col-2 mt-1">
                                </Col>
                            </Row>
                            <Row>
                                <div className={classes.root}>
                                    <Tabs
                                        value={tab}
                                        onChange={handleTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth">
                                        <Tab
                                            key="Attive"
                                            label={<span style={labelStyle}>{t('policies.tab-active')}</span>}
                                            classes={tabClass}
                                            style={tabStyle}/>
                                        <Tab
                                            key="Non attive"
                                            label={<span style={labelStyle}>{t('policies.tab-inactive')}</span>}
                                            classes={tabClass}
                                            style={tabStyle}/>
                                    </Tabs>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Container className="top-of-the-messages"></Container>
                    <Row style={{
                        //'borderLeft': '1px solid #EEE9E5', 'borderRight': '1px solid #EEE9E5'
                    }}>
                        <Col>
                            {
                                isLoadingSX && tab === 0 &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                isLoadingDX && tab === 1 &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                !isLoadingSX && activePolicies.length === 0 && tab === 0 &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('policies.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('policies.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('policies.nothing-text-2')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingDX && notActivePolicies.length === 0 && tab === 1 &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('policies.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('policies.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('policies.nothing-text-2')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingSX && tab === 0 && activePolicies.length > 0 &&
                                activePolicies.map((value, index) => (
                                    <Card className={classes.card}
                                          key={index}
                                          onClick={() => onClickPolicy(value.id)}
                                          style={{'borderRight': `12px solid ${setStatusColor(value.status)}`}}>
                                        <CardContent style={{'paddingBottom': '16px'}}>
                                            <Row>
                                                <Col>
                                                    <h6 className={'card-text-mid'}>{value.type}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">{t('policies.expiration-label')}
                                                        <span
                                                            className="font-weight-bold black">
                                                    <Moment locale="it"
                                                            format="DD/MM/YYYY">{value.insuranceEnd}</Moment>
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('policies.object-label')}
                                                        <span className="font-weight-bold black">
                                                            {value.object}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('dashboard.company-label')}
                                                        <span className="font-weight-bold black">
                                                    {value.company}
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="col-8" style={{'paddingRight': '0'}}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('dashboard.policy-number-label')}
                                                                <span className="font-weight-bold black">
                                                        {value.policyNr}
                                                    </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        value.plate !== null &&
                                                        <Row>
                                                            <div className="col-plate">
                                                                <img src={car} alt="car icon"/>
                                                                <span className="card-text-content"
                                                                      style={{
                                                                          'marginLeft': '7px',
                                                                          'color': themeContext.color.neutral600
                                                                      }}>{value.plate}</span>
                                                            </div>
                                                        </Row>
                                                    }
                                                </Col>
                                                {
                                                    value.status === 5 &&
                                                    <Col style={{
                                                        'textAlign': 'right',
                                                        'paddingLeft': '0'
                                                    }}>
                                                        <Row>
                                                            <Col>
                                                                <img src={activeFromIcon} alt="active-from-icon"
                                                                     style={{
                                                                         'paddingTop': '5px',
                                                                         'paddingBottom': '9px'
                                                                     }}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <h6 className={classes.cardTextRight}>
                                                                    {t('dashboard.active-from-label')}
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <span className={classes.cardTextRight}>
                                                    <Moment locale="it"
                                                            format="DD/MM/YYYY"
                                                            className={classes.cardTextRight}
                                                            style={{'fontWeight': '700'}}>{value.insuranceStart}</Moment>
                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                                {
                                                    (value.status === 3 || value.status === 4) &&
                                                    <Col style={{
                                                        'textAlign': 'right',
                                                        'paddingLeft': '0',
                                                        'paddingBottom': '0px',
                                                        'marginBottom': '0px',
                                                        'marginTop': '8px'
                                                    }}>
                                                        <Row>
                                                            <Col>
                                                                {
                                                                    value.plate === null &&
                                                                    <Button style={{'marginTop': '0px'}} disabled
                                                                            className={classes.renewButton}>{t('dashboard.renew-button')}</Button>
                                                                }
                                                                {
                                                                    value.plate !== null &&
                                                                    <Button style={{'marginTop': '24px'}} disabled
                                                                            className={classes.renewButton}>{t('dashboard.renew-button')}</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                            </Row>
                                        </CardContent>
                                    </Card>
                                ))
                            }
                            {!isLoadingDX && tab === 1 && notActivePolicies.length > 0 &&
                                notActivePolicies.map((value, index) => (
                                    <Card className={classes.card}
                                          key={index}
                                          onClick={() => onClickPolicy(value.id)}
                                          style={{'borderRight': `12px solid ${setStatusColor(value.status)}`}}>
                                        <CardContent style={{'paddingBottom': '16px'}}>
                                            <Row>
                                                <Col className="col-8">
                                                    <h6 className={'card-text-mid'}>{value.type}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">{t('policies.expiration-label')}<span
                                                        className="font-weight-bold black">
                                                    <Moment locale="it"
                                                            format="DD/MM/YYYY">{value.insuranceEnd}</Moment>
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('policies.object-label')}
                                                        <span className="font-weight-bold black">
                                                            {value.object}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('dashboard.company-label')}
                                                        <span className="font-weight-bold black">
                                                            {value.company}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="col-8" style={{'paddingRight': '0'}}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content">
                                                                <span className="ellipsis-1-v2">
                                                                   {t('dashboard.policy-number-label')}
                                                                    <span className="font-weight-bold black">
                                                                        {value.policyNr}
                                                                    </span>
                                                                 </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        value.plate !== null &&
                                                        <Row>
                                                            <div className="col-plate">
                                                                <img src={car} alt="car icon"/>
                                                                <span className="card-text-content "
                                                                      style={{
                                                                          'marginLeft': '7px',
                                                                          'color': themeContext.color.neutral600
                                                                      }}>{value.plate}</span>
                                                            </div>
                                                        </Row>
                                                    }
                                                </Col>
                                                {
                                                    value.status === 5 &&
                                                    <Col style={{
                                                        'textAlign': 'right',
                                                        'paddingLeft': '0'
                                                    }}>
                                                        <Row>
                                                            <Col>
                                                                <img src={activeFromIcon} alt="active-from-icon"
                                                                     style={{
                                                                         'paddingTop': '5px',
                                                                         'paddingBottom': '9px'
                                                                     }}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <h6 className={classes.cardTextRight}>
                                                                    {t('dashboard.active-from-label')}
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <span className={classes.cardTextRight}>
                                                    <Moment locale="it"
                                                            format="DD/MM/YYYY"
                                                            className={classes.cardTextRight}
                                                            style={{'fontWeight': '700'}}>{value.insuranceStart}</Moment>
                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                                {
                                                    (value.status === 3 || value.status === 4) &&
                                                    <Col style={{
                                                        'textAlign': 'right',
                                                        'paddingLeft': '0',
                                                        'paddingBottom': '0px',
                                                        'marginBottom': '0px',
                                                        'marginTop': '8px'
                                                    }}>
                                                        <Row>
                                                            <Col>
                                                                {
                                                                    value.plate === null &&
                                                                    <Button style={{'marginTop': '0px'}} disabled
                                                                            className={classes.renewButton}>{t('dashboard.renew-button')}</Button>
                                                                }
                                                                {
                                                                    value.plate !== null &&
                                                                    <Button style={{'marginTop': '24px'}} disabled
                                                                            className={classes.renewButton}>{t('dashboard.renew-button')}</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                            </Row>
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                </Container>
                {
                    /*
                    <Container className="end-of-the-page" style={{
                    'background': themeContext.color.white,
                    'borderLeft': '1px solid #EEE9E5',
                    'borderRight': '1px solid #EEE9E5',
                    'height': '100%',
                    'position': 'fixed',
                    'right': '0',
                    'left': '0'
                }}></Container>
                     */
                }

                <Container className="mb-2 fixed-bottom">
                </Container>
            </Fragment>

    )
}