import React, {Fragment, useContext, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import back from '../../images/back-icon.svg';
import {Button, InputAdornment, TextField} from "@mui/material";
import DialogSmall from "../components/DialogSmall.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {regexPassword} from "../service/UtilityService.js";

export default function Password() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = sessionStorage.getItem('token');
    const [passwordDialog, setPasswordDialog] = useState(false);
    const changePasswordUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/changePassword`;
    const [oldPasswordVisibility, setOldPasswordVisibility] = useState(false);
    const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
    const [confirmNewPasswordVisibility, setConfirmNewPasswordVisibility] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [openConfirmChangePasswordDialog, setOpenConfirmChangePasswordDialog] = useState(false);

    const defaultValues = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    };
    const formValues = useRef(defaultValues);

    const submitHandler = () => {
        if (formValues.current.newPassword === formValues.current.confirmNewPassword && (formValues.current.newPassword !== "" && formValues.current.confirmNewPassword !== "")) {
            if (formValues.current.newPassword.match(regexPassword)) {
                fetch(changePasswordUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        oldPassword: formValues.current.oldPassword,
                        newPassword: formValues.current.newPassword,
                    }),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    return res.json();
                }).then((res) => {
                    if (res.ok) {
                        setOpenConfirmChangePasswordDialog(true)
                    } else {
                        setPasswordDialog(true)
                        /*
                        return res.then((data) => {
                            let errorMessage = 'Authentication failed!';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }
                            throw new Error(errorMessage);
                        });
                        */
                    }
                }).catch(() => {
                    setServiceError(true)
                });
            } else {
                setPasswordDialog(true);
            }
        } else {
            setPasswordDialog(true);
        }
    }

    const handleInputChange = (e) => {
        const {
            name,
            value
        } = e.target;
        formValues.current = {
            ...formValues.current,
            [name]: value,
        }
    };

    const handlePasswordDialog = () => {
        setPasswordDialog(current => !current);
    }

    const handleOldPasswordVisibility = e => {
        const password = document.querySelector("#oldPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setOldPasswordVisibility(current => !current)
    }

    const handleNewPasswordVisibility = e => {
        const password = document.querySelector("#newPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setNewPasswordVisibility(current => !current)
    }

    const handleConfirmNewPasswordVisibility = e => {
        const password = document.querySelector("#confirmNewPassword")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setConfirmNewPasswordVisibility(current => !current)
    }

    function turnBack() {
        return navigate(-1);
    }

    function reload() {
        window.location.reload();
    }

    function handleCloseConfirmChangePasswordDialog() {
        setOpenConfirmChangePasswordDialog(false)
        navigate('/settings/profile');
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="full-height mt-0" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row style={{paddingTop: '19.5px', paddingBottom: '16px'}}>
                                <Col className='col-6 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px" alt="back"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container className="top-of-the-password"></Container>
                    <Container style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingLeft': '5px',
                        'paddingRight': '5px'
                    }}>
                        <Row style={{'marginTop': '11px'}}>
                            <Col>
                                <h2 className="float-left title-page">{t('change-password.title')}</h2>
                            </Col>
                        </Row>
                        <Row style={{'marginTop': '28px'}}>
                            <Col>
                                <TextField
                                    id="oldPassword"
                                    className="custom-textfield mb-4"
                                    fullWidth
                                    label={t('change-password.old-password')}
                                    variant="outlined"
                                    name="oldPassword"
                                    type="password"
                                    value={formValues.oldPassword}
                                    defaultValue={defaultValues.oldPassword}
                                    onChange={handleInputChange}
                                    color="primary"
                                    InputProps={{
                                        style: {
                                            'borderRadius': '4px',
                                            //'height': '50px',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {oldPasswordVisibility &&
                                                    <span
                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                        onClick={handleOldPasswordVisibility}>visibility_off</span>}
                                                {!oldPasswordVisibility &&
                                                    <span
                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                        onClick={handleOldPasswordVisibility}>visibility</span>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="newPassword"
                                    className="custom-textfield mb-4"
                                    fullWidth
                                    label={t('change-password.new-password')}
                                    variant="outlined"
                                    name="newPassword"
                                    type="password"
                                    value={formValues.newPassword}
                                    onChange={handleInputChange}
                                    color="primary"
                                    InputProps={{
                                        style: {
                                            'borderRadius': '4px',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {newPasswordVisibility &&
                                                    <span
                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                        onClick={handleNewPasswordVisibility}>visibility_off</span>}
                                                {!newPasswordVisibility &&
                                                    <span
                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                        onClick={handleNewPasswordVisibility}>visibility</span>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    id="confirmNewPassword"
                                    className="custom-textfield"
                                    fullWidth
                                    label={t('change-password.confirm-new-password')}
                                    variant="outlined"
                                    name="confirmNewPassword"
                                    type="password"
                                    value={formValues.confirmNewPassword}
                                    onChange={handleInputChange}
                                    color="primary"
                                    InputProps={{
                                        style: {
                                            'borderRadius': '4px',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {confirmNewPasswordVisibility &&
                                                    <span
                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                        onClick={handleConfirmNewPasswordVisibility}>visibility_off</span>}
                                                {!confirmNewPasswordVisibility &&
                                                    <span
                                                        className="material-icons toggle-password float-right unselectable darkgrey"
                                                        onClick={handleConfirmNewPasswordVisibility}>visibility</span>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row style={{'marginTop': '20px'}}>
                            <Col>
                                <h6 className="paragraph-text">
                                    {t('change-password.info')}
                                </h6>
                            </Col>
                        </Row>

                        <Row style={{'marginTop': '48px'}}>
                            <Col>
                                <Button onClick={submitHandler} className="btn-custom-2 text-uppercase"
                                        variant="outlined"
                                        size="large" style={{'color': themeContext.color.white}}
                                        type="submit">
                                    {t('change-password.button')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <DialogSmall title={t('newPassword.modal-criterias-title')}
                             description={t('newPassword.tooltip-title') + '<ul>' +
                                 '<li>' + t('newPassword.tooltip-1') + '</li>' +
                                 '<li>' + t('newPassword.tooltip-2') + '</li>' +
                                 '<li>' + t('newPassword.tooltip-3') + '</li>' +
                                 '<li>' + t('newPassword.tooltip-4') + '</li>' +
                                 '<li>' + t('newPassword.tooltip-5') + '</li></ul>'
                             }
                             buttonText={t('change-password.modal-button')}
                             open={passwordDialog}
                             handleClose={handlePasswordDialog}
                ></DialogSmall>
                <DialogSmall title={t('change-password.completed-reset-password-title')}
                             description={t('change-password.completed-reset-password-description')}
                             buttonText={t('dialog.button-ok')}
                             open={openConfirmChangePasswordDialog}
                             handleClose={handleCloseConfirmChangePasswordDialog}
                ></DialogSmall>
            </Fragment>
    )
}