import { styled } from "@mui/system";
import {Backdrop, Button} from "@mui/material";
import themeContext from "../components/ThemeContext.js";
import {withStyles} from "@mui/styles";
import Badge from "@mui/material/Badge";

export const Title = styled('h6')({
    color: themeContext.color.neutral200,
    textAlign: 'left',
    lineHeight: '1',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
});

export const CardTextMid = styled('h6')({
    color: themeContext.color.neutral400,
    textAlign: 'left',
    lineHeight: '1',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    paddingTop: '10px',
    paddingBottom: '7px',
});

export const CardTextCenter = styled('h6')({
    color: themeContext.color.neutral400,
    textAlign: 'center',
    lineHeight: '0.6',
    fontSize: '14px',
    paddingBottom: '0',
    marginBottom: '0',
});

export const StyledBackdrop = styled(Backdrop)({
    backdropFilter: 'blur(16px)',
    backgroundColor: themeContext.color.shadowBlack,
    opacity: 1,
});

export const DialogTitle = styled("h6")({
    textAlign: 'left',
    font: 'normal normal 700 20px/28px Inter',
    paddingBottom: '24px'
});

export const DialogDescription = styled('h6')({
    textAlign: 'left',
    font: 'normal normal normal 14px/140% Inter',
    paddingBottom: '24px'
});

export const DialogAction = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const DialogButton = styled(Button)({
    display: 'flex',
    justifyContent: 'flex-end',
    textTransform: 'none',
});

export const StyledBadge = withStyles((theme) => ({
    badge: {
        border: `${themeContext.spacing.borders.border2} ${themeContext.color.white}`,
        background: themeContext.color.red,
        color: themeContext.color.white,
        padding: '0 4px',
    },
}))(Badge);

export const StyledBadgeZero = withStyles((theme) => ({
    badge: {
        border: `${themeContext.spacing.borders.border2} ${themeContext.color.white}`,
        background: themeContext.color.neutral200,
        color: themeContext.color.white,
        padding: '0 4px',
    },
}))(Badge);
