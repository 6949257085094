import React, {Fragment, useEffect, useState} from 'react'
import {Col, Row} from "react-bootstrap";
import LinkLabel from "@mui/material/Link";
import shareIcon from "../../images/share-icon.svg";
import {useTranslation} from "react-i18next";
import themeContext from "./ThemeContext.js";

const AppShareComponent = ({type, person}) => {
    const [canShare, setCanShare] = useState(false);
    const {t, i18n} = useTranslation();


    useEffect(() => {
        const checkShareSupport = () => {
            const shareData = {
                title: t('personDetail.share-title'),
                text: t('personDetail.share-text'),
                url: `/login`,
            };
            if (navigator.share && navigator.canShare(shareData)) {
                setCanShare(true);
            } else {
                setCanShare(false);
            }
        };

        checkShareSupport();
    }, [t]);

    const handleShare = async () => {
        const shareData = {
            title: t('personDetail.share-title'),
            text: t('personDetail.share-text'),
            url: `/login`,
        };

        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error('Error sharing:', err);
        }
    };

    if (!canShare) {
        return null;
    }

    return (
        <div className={'mb-4'}>
            {type === 'people' && person &&
                <Row style={{'marginTop': '36px'}}>
                    <Col>
                            <span style={{
                                'color': themeContext.color.neutral400,
                                'fontSize': '12px'
                            }}>
                                {
                                    ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                        <span>
                                            {t('personDetail.share-label-1')} {person.name}
                                        </span>
                                        :
                                        <span>
                                            {t('personDetail.share-label-1')} {person.firstName} {t('personDetail.share-label-2')}
                                        </span>
                                }
                            </span>
                    </Col>
                </Row>}
            {type === 'signing' &&
                <Row className="mt-4" style={{textAlign: 'center', fontSize: '14px', color: themeContext.color.neutral200}}>
                    <Col>
                        <span>{t('signing.info')}</span>
                    </Col>
                </Row>
            }
            <Row className="mt-2 mb-4">
                <Col>
                    <LinkLabel
                        className="mt-4 login-subtitle cursor-pointer"
                        color="primary"
                        onClick={handleShare}
                        style={{color: themeContext.color.main}}
                    >
                        {type === 'people' ? t('personDetail.share-action') : t('signing.share')}
                        {type === 'signing' && <img className="ml-1" src={shareIcon} alt="share-icon"/>}
                    </LinkLabel>
                </Col>
            </Row>
        </div>
    )
}
export default AppShareComponent;