import React, {forwardRef} from 'react'
import TextField from '@mui/material/TextField'
import themeContext from "../components/ThemeContext.js";


const phoneInput = (props, ref) => {

    return (
        <TextField
            {...props}
            InputProps={{
                style: {
                    'borderRadius': '4px',
                    'color': themeContext.color.neutral600,
                },
            }}
            inputRef={ref}
            fullWidth
            variant='outlined'
            name='phone'
            color='primary'
        />
    )
}
export default forwardRef(phoneInput)