import { useState, useEffect, useRef } from 'react';

function useCountdown(initialSeconds) {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [disabled, setDisabled] = useState(false);
    const intervalRef = useRef(null);

    const startCountdown = () => {
        // Evita di avviare un altro countdown se già in esecuzione
        setSeconds(initialSeconds)
        if (intervalRef.current) return;

        setDisabled(true);
        intervalRef.current = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    setDisabled(false);
                    return 0;
                }
            });
        }, 1000);
    };

    // Cleanup when component unmounts or seconds changes
    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                console.log('clear interval')
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return { seconds, disabled, startCountdown };
}

export default useCountdown;