import React, {useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Dashboard from './js/pages/Dashboard.js'
import Login from './js/pages/Login.js'
import AuthContextMiddleware from "./js/middleware/AuthContextMiddleware.js";
import Profile from "./js/pages/Profile.js";
import CssBaseline from '@mui/material/CssBaseline';
import Pin from './js/pages/Pin.js';
import ConfirmedPin from "./js/pages/ConfirmedPin.js";
import SPIDMiddleware from "./js/middleware/SPIDMiddleware.js";
import SPIDAuthorize from "./js/pages/SPIDAuthorize.js";
import Messages from "./js/pages/Messages.js";
import MessageDetails from "./js/pages/MessageDetails.js";
import Settings from "./js/pages/Settings.js";
import Language from "./js/pages/Language.js";
import {useTranslation} from "react-i18next";
import Password from "./js/pages/Password.js";
import PersonDetail from "./js/pages/PersonDetail.js";
import People from "./js/pages/People.js";
import Vehicles from "./js/pages/Vehicles.js";
import PeoplePolicies from "./js/pages/PeoplePolicies.js";
import Claims from "./js/pages/Claims.js";
import PeopleClaims from "./js/pages/PeopleClaims.js";
import PolicyDetails from "./js/pages/PolicyDetails";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import homeIcon from './images/nav-home-icon.svg';
import homeHoverIcon from './images/nav-home-hover.svg';
import claimsIcon from './images/nav-claims-icon.svg';
import claimsHoverIcon from './images/nav-claims-hover.svg';
import brokerIcon from './images/nav-broker-icon.svg';
import brokerHoverIcon from './images/nav-broker-hover.svg';
import VehicleDetail from "./js/pages/VehicleDetail";
import TitlesHistory from "./js/pages/TitlesHistory.js";
import VehiclePolicies from "./js/pages/VehiclePolicies.js";
import ClaimDetail from "./js/pages/ClaimDetail";
import PersonalDocuments from "./js/pages/PersonalDocuments.js";
import PersonalDocumentsList from "./js/pages/PersonalDocumentsList.js";
import VehicleClaims from "./js/pages/VehicleClaims";
import Documents from "./js/pages/Documents.js";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import {Button, Slide, createTheme, ThemeProvider} from "@mui/material";
import {isIos, isIpad, isMac, isSafari, isStandalone} from './js/service/UtilityService.js';
import iosAddIcon from "./images/ios-add-icon.svg";
import iosSafariIcon from "./images/ios-safari-icon.svg";
import iosShareIcon from "./images/ios-share-icon.svg";
import chromeIcon from "./images/chrome-icon.svg";
import PolicyClaims from "./js/pages/PolicyClaims.js";
import {ClientJS} from "clientjs";
import Contacts from "./js/pages/Contacts.js";
import ServiceError from "./js/pages/ServiceError.js";
import {GlobalDebug} from "./js/service/remove-consoles.js";
import ReactGA from 'react-ga4';
import useAnalyticsEventTracker from './js/middleware/useAnalyticsEventTracker';
import Payments from "./js/pages/Payments.js";
import paymentIcon from './images/nav-payment-icon.svg';
import paymentHoverIcon from './images/nav-payment-hover.svg';
import PaymentsSummary from "./js/pages/PaymentsSummary.js";
import NewPassword from "./js/pages/NewPassword.js";
import Maintenance from "./js/pages/Maintenance.js";
import PaymentsMethod from "./js/pages/PaymentsMethod";
import PaymentsTitleDetail from "./js/pages/PaymentsTitleDetail";
import PaymentsCheckout from "./js/pages/PaymentsCheckout";
import RequestCredentials from "./js/pages/RequestCredentials";
import NewOtp from "./js/pages/NewOtp.js";
import SendOtp from "./js/pages/SendOtp.js";
import LoginOtp from "./js/pages/LoginOtp";
import LoginOtpVerify from "./js/pages/LoginOtpVerify";
import RequestResetPassword from "./js/pages/RequestResetPassword";
import CIEAuthorize from "./js/pages/CIEAuthorize.js";
import Signing from "./js/pages/Signing";
import signingIcon from './images/nav-signing-icon.svg';
import regulationsIcon from './images/nav-regulations-icon.svg';
import regulationsHoverIcon from './images/nav-regulations-hover.svg';
import signingHoverIcon from './images/nav-signing-hover.svg';
import signingRedIcon from './images/nav-signing-red.svg';
import refundsHoverIcon from './images/nav-refunds-hover.svg';
import refundsIcon from './images/nav-refunds-icon.svg';
import UploadDocumentToVerify from "./js/pages/UploadDocumentToVerify";
import PersonalDocumentsSelection from "./js/pages/PersonalDocumentsSelection.js";
import TestCameraRecognition from "./js/pages/TestCameraRecognition";
import CameraRecognition from "./js/pages/CameraRecognition";
import OtpVerifyID from "./js/pages/OtpVerifyID";
import ConfirmOtpVerifyID from "./js/pages/ConfirmOtpVerifyID";
import PaymentsStatus from "./js/pages/PaymentsStatus";
import ResetPassword from "./js/pages/ResetPassword.js";
import OfflineError from "./js/pages/OfflineError.js";
import OpenClaim from "./js/pages/OpenClaim.js";
import CarClaimForm from "./js/pages/CarClaimForm.js";
import AttachImagesToClaimForm from "./js/pages/AttachImagesToClaimForm.js";
import GenericClaimForm from "./js/pages/GenericClaimForm.js";
import {subscribe} from "./js/service/EventService.js";
import Regulations from "./js/pages/Regulations.js";
import {useMediaQuery} from "react-responsive";
import UploadNewDocument from "./js/pages/UploadNewDocument.js";
import LoginProducer from "./js/pages/LoginProducer.js";
import InvitationScreen from "./js/pages/InvitationScreen.js";
import exitIconWhite from './images/exit-icon-white.svg';
import MessagePreferences from "./js/pages/MessagePreferences.js";
import Refunds from "./js/pages/Refunds.js";
import BankReferences from "./js/pages/BankReferences.js";
import UploadBankRefs from "./js/pages/UploadBankRefs.js";
import ClaimsHistory from "./js/pages/ClaimsHistory.js";
import TelegramHook from "./js/pages/TelegramHook.js";
import themeContext from "./js/components/ThemeContext.js";
import Support from "./js/pages/Support.js";
import TicketDetail from "./js/pages/TicketDetail.js";
import {StyledBackdrop, StyledBadge} from "./js/styles/Styles.js";

if (process.env.REACT_APP_GA_EGGON_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_EGGON_TRACKING_ID);
}

if (process.env.REACT_APP_GA_WIDE_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_WIDE_TRACKING_ID);
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function App() {
    const [value, setValue] = useState(0);
    const [isVisible, setVisibleState] = useState(false);
    const hide = () => setVisibleState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const gaEventTrackerHome = useAnalyticsEventTracker('Home')
    const gaEventTrackerClaims = useAnalyticsEventTracker('Claims')
    const gaEventTrackerContacts = useAnalyticsEventTracker('Contacts')
    const gaEventTrackerInstalled = useAnalyticsEventTracker('Installed')
    const gaEventTrackerPayments = useAnalyticsEventTracker('Payments')
    const gaEventTrackerSigning = useAnalyticsEventTracker('Signing')
    const gaEventTrackerRegulations = useAnalyticsEventTracker('Regulations')
    const [isUnderMaintenanceMode, setIsUnderMaintenanceMode] = useState(false)
    const isLoggedIn = sessionStorage.getItem('login');
    const token = sessionStorage.getItem('token');
    const root = sessionStorage.getItem('root');
    const [countNotification, setCountNotification] = useState(0)
    const [countSigning, setCountSigning] = useState(0)
    const [countRegulations, setCountRegulations] = useState(0)
    const [countRefunds, setCountRefunds] = useState(0)
    const [serviceError, setServiceError] = useState(false);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})

    const clickMenu = (value) => {
        switch (value) {
            case 'Home':
                gaEventTrackerHome('Click', 'Clicked Home navigation Button');
                reloadMenu();
                break;
            case 'Contacts':
                gaEventTrackerContacts('Click', 'Clicked Contacts navigation Button');
                reloadMenu();
                break;
            case 'Claims':
                gaEventTrackerClaims('Click', 'Clicked Claims navigation Button');
                reloadMenu();
                break;
            case 'Payments':
                gaEventTrackerPayments('Click', 'Clicked Payments navigation Button');
                reloadMenu();
                break;
            case 'Signing':
                gaEventTrackerSigning('Click', 'Clicked Signing navigation Button');
                reloadMenu();
                break;
            case 'Regulations':
                gaEventTrackerRegulations('Click', 'Clicked Regulations navigation Button');
                reloadMenu();
                break;
            case 'Refunds':
                //gaEventTrackerRegulations('Click', 'Clicked Regulations navigation Button');
                reloadMenu();
                break;
        }
    }

    const reloadMenu = (token) => {
        getPeoplePaymentNotification(token);
        getWhitelistPolicies(token);
        getRegulations(token);
        getRefunds(token);
        navValue();
    }

    useEffect(() => {
        subscribe('reloadMenu', (l) => {
            reloadMenu(l.detail); //auth token
        });
        subscribe('navigateToClaims', () => {
            setValue(2)
        });
        subscribe('navigateToPayments', () => {
            setValue(3)
        });
        subscribe('navigateToSigning', () => {
            setValue(4)
        });
        subscribe('navigateToRegulations', () => {
            setValue(6)
        });
        subscribe('navigateToRefunds', () => {
            setValue(7)
        });
        isUnderMaintenance();
        if (!isStandalone()) {
            if ((isMac() && isSafari()) || isIos() || isIpad()) {
                if (!sessionStorage.getItem('login')) {
                    setVisibleState(true);
                }
            } else {
                window.addEventListener('beforeinstallprompt', function (e) {
                    // Prevent Chrome 67 and earlier from automatically showing the prompt
                    e.preventDefault()
                    // Stash the event so it can be triggered later.
                    setDeferredPrompt(e)
                    if (!sessionStorage.getItem('login')) {
                        setVisibleState(true);
                    }
                })
            }
        }
        let client = new ClientJS();
        if (isIos()) {
            let OS = client.getOSVersion();
            if (OS < 16) {
                localStorage.removeItem('biometrics')
            } else {
                localStorage.setItem('biometrics', true)
            }
        } else if (!client.isMobile()) {
            localStorage.removeItem('biometrics')
        } else {
            localStorage.setItem('biometrics', true)
        }
        (process.env.REACT_APP_ENV === "STAGE" | process.env.REACT_APP_ENV === "PROD") && GlobalDebug(false);

        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search
        });
        window.addEventListener('appinstalled', () => {
            // send analytics event to indicate successful install
            gaEventTrackerInstalled('Click', 'Pwa Installed')
        });
        if (isLoggedIn) {
            getPeoplePaymentNotification();
            getWhitelistPolicies();
            getRegulations();
            getRefunds();
            navValue();
        }
    }, [isLoggedIn]);

    const authCtx = useContext(AuthContextMiddleware);
    const pinIsActive = (process.env.REACT_APP_ENV !== "PROD" && process.env.REACT_APP_ENV !== "STAGE") ? localStorage.getItem('pinIsActive') : false;
    //const isSetFirstPin = true;
    //const isSetConfirmedPin = true;
    //const isSetFirstPin = sessionStorage.getItem('pin');
    const isSetConfirmedPin = sessionStorage.getItem('confirmedPin');
    const isUnderMaintenanceUrl = `${process.env.REACT_APP_BASE_URL}/maintenance/isUnderMaintenance`;
    const isUpdatedUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/isUpdated`;
    const getPeoplePaymentNotificationUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/people?payableOnly=true`;
    const getDocumentToSignedUrl = `${process.env.REACT_APP_BASE_URL}/media/expectedDocuments/getAllDocuments`;
    const getRegulationsUrl = `${process.env.REACT_APP_BASE_URL}/payments/regulations/getAllRegulations`;
    const getLastSignedDocumentsUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/lastSignedDocuments`;
    const getWhitelistPoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/whitelistPolicies`;
    const getAllRefundPremiumUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/getAllRefundPremium`;
    const [isDocumentToSigned, setIsDocumentToSigned] = useState(false)
    const [isLastDocumentSigned, setIsLastDocumentSigned] = useState(false)
    const client = new ClientJS();
    const error = authCtx.error;
    const countError = authCtx.countError;
    const navigate = useNavigate(); //do NOT remove

    const navValue = () => {
        if (window.location.href.includes('/dashboard')) {
            setValue(0);
        } else if (window.location.href.includes('/contacts')) {
            setValue(1);
        } else if (window.location.href.includes('/claims')) {
            setValue(2);
        } else if (window.location.href.includes('/payments')) {
            setValue(3);
        } else if (window.location.href.includes('/signing')) {
            setValue(4);
        } else if (window.location.href.includes('/regulations')) {
            setValue(6);
        } else if (window.location.href.includes('/refunds')) {
            setValue(7);
        }
    }

    const handleInstall = () => {
        hide();
        showInstall();
    }

    function showInstall() {
        deferredPrompt.prompt();  // Wait for the user to respond to the prompt
        deferredPrompt.userChoice
            // @ts-ignore
            .then(function (choiceResult) {
                setDeferredPrompt(undefined)
            });
    }

    const isUnderMaintenance = () => {
        fetch(isUnderMaintenanceUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return response.text().then(text => {
                    throw new Error(text)
                })
            }
        }).then((res) => {
            if (res.ok) {
                if (res.message === "true") {
                    setIsUnderMaintenanceMode(true)
                }
            } else if (res.status === 503) {
                return res.text().then(text => {
                    throw new Error(text)
                })
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getPeoplePaymentNotification = (token_arg) => {
        //setIsLoadingNotifications(true)
        fetch(`${getPeoplePaymentNotificationUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${!!token_arg ? token_arg : token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            data.map((value, index) => {
                count = count + 1;
            })
            setCountNotification(count)
        }).catch(() => {
            //setServiceError(true)
        });
    }

    const getDocumentToSigned = (companies) => {
        //setIsLoadingNotifications(true)
        fetch(`${getDocumentToSignedUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            let finder = 0;
            if (length > 0) {
                data.map((person) => {
                    person.policies = person.policies.map((policy) => {
                        finder = finder + (policy.documents.length);
                    }).filter(e => e)
                })
                setCountSigning(finder)
            }
        }).catch(() => {
            //setServiceError(true)
        });
    }

    const getRegulations = (token_arg) => {
        //setIsLoadingNotifications(true)
        fetch(`${getRegulationsUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${!!token_arg ? token_arg : token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            let finder = 0;
            if (length > 0) {
                data.map((person) => {
                    person.regulations.map((regulation) => {
                        if (regulation.policyStatus !== 5) {
                            finder = finder + 1;
                        }
                    });
                })
                setCountRegulations(finder)
            }
        }).catch(() => {
            //setServiceError(true)
        });
    }

    const getLastSignedDocument = () => {
        //setIsLoadingNotifications(true)
        fetch(`${getLastSignedDocumentsUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length > 0) {
                setIsLastDocumentSigned(true)
            }
        }).catch(() => {
            //setServiceError(true)
        });
    }

    const getWhitelistPolicies = (token_arg) => {
        fetch(getWhitelistPoliciesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${!!token_arg ? token_arg : token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            getDocumentToSigned(data);
            getLastSignedDocument();
        }).catch(() => {
            //setServiceError(true)
        });
    }

    const getRefunds = (token_arg) => {
        fetch(`${getAllRefundPremiumUrl}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${!!token_arg ? token_arg : token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            let finder = 0;
            if (length > 0) {
                data.map((person) => {
                    person.refund.map((refund) => {
                        finder = finder + 1;
                    });
                })
                setCountRefunds(finder)
            }
        }).catch(() => {
            //setServiceError(true)
        });
    }

    //const prefersDarkColor = useMediaQuery('(prefers-color-scheme: dark');
    const prefersDarkColor = false;

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkColor ? 'dark' : 'light',
                    primary: {
                        main: themeContext.color.main,
                    },
                    secondary: {
                        main: prefersDarkColor ? '#BABBBE' : themeContext.color.mainActive,
                    },
                    text: {
                        primary: themeContext.color.neutral600,
                        secondary: prefersDarkColor ? '#BABBBE' : themeContext.color.neutral600,
                    },
                    background: {
                        default: prefersDarkColor ? '#1C1F2A' : themeContext.color.white,
                    },
                },
                typography: {
                    fontFamily: 'Inter',
                },
            }),
        [prefersDarkColor, themeContext.color]
    );

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "15.4") || (isMac() && client.getOSVersion() < "13")) {
        //
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.addEventListener('message', event => {
            if (event.data !== 'ios-message') {
                sessionStorage.setItem("notificationId", JSON.stringify(event.data));
            }
        });
    }

    const {t} = useTranslation();

    const handleNavigation = (value) => {
        switch (value) {
            case 0:
                return navigate('/dashboard');
            case 1:
                return navigate('/contacts');
            case 2:
                return navigate('/claims');
            case 3:
                return navigate('/payments');
            case 4:
            case 5:
                return navigate('/signing');
            case 6:
                return navigate('/regulations');
            case 7:
                return navigate('/refunds');
        }
    }

    const countNavItems = () => {
        let count = 0;
        if (countRegulations) {
            count++;
        }
        if (countSigning || isLastDocumentSigned) {
            count++;
        }
        if (countNotification) {
            count++;
        }
        if (countRefunds) {
            count++;
        }
        return count;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className="App">

                {/*{ accesso con il pin se l'applicazione è offline
                    !(navigator.onLine) && !isLoggedIn && !error && pinIsActive &&
                    <Routes>
                        <Route path="/code" element={<Pin/>}></Route>
                        <Route path="/" element={<Navigate to="/code" replace/>}/>
                        <Route path="/*" element={<Navigate to="/code" replace/>}/>
                    </Routes>
                }
                {
                    !(navigator.onLine) && !isLoggedIn && error && pinIsActive &&
                    <Routes>
                        <Route path="/code" element={<Pin/>}></Route>
                        <Route path="/" element={<Navigate to="/code" replace/>}/>
                        <Route path="/*" element={<Navigate to="/code" replace/>}/>
                    </Routes>
                }*/}

                {
                    (navigator.onLine) && !isLoggedIn && !isUnderMaintenanceMode && !serviceError &&
                    <Routes>
                        <Route path="/login" index element={<Login/>}></Route>
                        <Route path="/loginProducer" index element={<LoginProducer/>}></Route>
                        <Route path="/login-spid" element={<Login/>}></Route>
                        <Route path="/login/request-credentials" element={<RequestCredentials/>}></Route>
                        <Route path="/users/invitation/accept" element={<NewPassword/>}></Route>
                        <Route path="/users/invitation/accept/otp" element={<NewOtp/>}></Route>
                        <Route path="/users/invitation/accept/otp/send" element={<SendOtp/>}></Route>
                        <Route path="/spid" element={<SPIDMiddleware/>}></Route>
                        <Route path="/spid-authorize" element={<SPIDAuthorize/>}></Route>
                        <Route path="/login-otp" element={<LoginOtp/>}></Route>
                        <Route path="/login-otp/verify" element={<LoginOtpVerify/>}></Route>
                        <Route path="/login/request-reset-password" element={<RequestResetPassword/>}></Route>
                        <Route path="/users/reset/password" element={<ResetPassword/>}></Route>
                        <Route path="/cie-authorize" element={<CIEAuthorize/>}></Route>
                        <Route path="/" element={<Navigate to="/login" replace/>}/>
                        <Route path="/*" element={<Navigate to="/login" replace/>}/>
                    </Routes>
                }
                {
                    (navigator.onLine) && !isLoggedIn && isUnderMaintenanceMode && !serviceError &&
                    <Routes>
                        <Route path="/maintenance" element={<Maintenance/>}></Route>
                        <Route path="/" element={<Navigate to="/maintenance" replace/>}/>
                        <Route path="/*" element={<Navigate to="/maintenance" replace/>}/>
                    </Routes>
                }
                {
                    (navigator.onLine) && !isLoggedIn && serviceError &&
                    <Routes>
                        <Route path="/service-error" index element={<ServiceError/>}></Route>
                        <Route path="/" element={<Navigate to="/service-error" replace/>}/>
                        <Route path="/*" element={<Navigate to="/service-error" replace/>}/>
                    </Routes>
                }
                {
                    /* setting del pin quando viene attivato il toggle nella pagina delle impostazioni
                    (navigator.onLine) && isLoggedIn && isSetFirstPin === false &&
                    <Routes>
                        <Route path="/code" element={<Pin/>}></Route>
                        <Route path="/" element={<Navigate to="/code" replace/>}/>
                        <Route path="/*" element={<Navigate to="/code" replace/>}/>
                    </Routes>
                     */

                }
                {
                    /*
                    (navigator.onLine) && isLoggedIn && isSetFirstPin && !isSetConfirmedPin &&
                    <Routes>
                        <Route path="/confirm-code" index element={<ConfirmedPin/>}></Route>
                        <Route path="/" element={<Navigate to="/confirm-code" replace/>}/>
                        <Route path="/*" element={<Navigate to="/confirm-code" replace/>}/>
                    </Routes>
                     */
                }
                {
                    !(navigator.onLine) && !isLoggedIn &&/* !pinIsActive &&*/
                    <Routes>
                        <Route path="/offline-error" index element={<OfflineError/>}></Route>
                        <Route path="/" element={<Navigate to="/offline-error" replace/>}/>
                        <Route path="/*" element={<Navigate to="/offline-error" replace/>}/>
                    </Routes>
                }
                {
                    !(navigator.onLine) && isLoggedIn &&/* !pinIsActive &&*/
                    <Routes>
                        <Route path="/offline-error" index element={<OfflineError/>}></Route>
                        <Route path="/" element={<Navigate to="/offline-error" replace/>}/>
                        <Route path="/*" element={<Navigate to="/offline-error" replace/>}/>
                    </Routes>
                }
                {
                    navigator.onLine && isLoggedIn && !isUnderMaintenanceMode &&/* isSetFirstPin && isSetConfirmedPin &&*/
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard/>}></Route>
                        <Route path="/settings" element={<Settings/>}></Route>
                        <Route path="/settings/profile" element={<Profile/>}></Route>
                        <Route path="/settings/loggedCamera/:documentGroupId"
                               element={<TestCameraRecognition/>}></Route>
                        <Route path="/settings/profile/password" element={<Password/>}></Route>
                        <Route path="/settings/people" element={<People/>}></Route>
                        <Route path="/settings/people/:personId" element={<PersonDetail/>}></Route>
                        <Route path="/settings/people/:personId/policies" element={<PeoplePolicies/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId" element={<PolicyDetails/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId/policyClaims"
                               element={<PolicyClaims/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId/:policyTitleId"
                               element={<Documents/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId/titlesHistory"
                               element={<TitlesHistory/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId/titlesHistory/:policyTitleId"
                               element={<Documents/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId/documents"
                               element={<Documents/>}></Route>
                        <Route path="/settings/people/:personId/policies/:policyId/documents-to-signed"
                               element={<Documents/>}></Route>
                        <Route path="/settings/people/:personId/claims" element={<PeopleClaims/>}></Route>
                        <Route path="/settings/people/:personId/claims/:claimId" element={<ClaimDetail/>}></Route>
                        <Route path="/settings/people/:personId/claims/:claimId/:policyId"
                               element={<PolicyDetails/>}></Route>
                        {/*<Route path="/settings/people/:personId/claims/:claimId/:policyId/policyClaims"
                               element={<PolicyClaims/>}></Route>*/}
                        <Route path="/settings/people/:personId/invitation" element={<InvitationScreen/>}></Route>
                        <Route path="/settings/people/:personId/invitation/personalDocuments"
                               element={<PersonalDocuments/>}></Route>
                        <Route path="/settings/people/:personId/invitation/personalDocuments/:documentGroupId"
                               element={<PersonalDocumentsSelection/>}></Route>
                        <Route path="/settings/people/:personId/invitation/personalDocuments/new"
                               element={<UploadNewDocument/>}></Route>
                        <Route path="/settings/people/:personId/invitation/personalDocuments/:documentGroupId/identify"
                               element={<CameraRecognition/>}></Route>
                        <Route path="/settings/people/:personId/invitation/personalDocuments/identify"
                               element={<CameraRecognition/>}></Route>
                        <Route path="/settings/people/:personId/bank-references"
                               element={<BankReferences/>}></Route>
                        <Route path="/settings/people/:personId/bank-references/upload"
                               element={<UploadBankRefs/>}></Route>
                        <Route path="/settings/people/:personId/bank-references/upload/personalDocuments"
                               element={<PersonalDocuments/>}></Route>
                        <Route
                            path="/settings/people/:personId/bank-references/upload/personalDocuments/:documentGroupId"
                            element={<PersonalDocumentsSelection/>}></Route>
                        <Route path="/settings/people/:personId/bank-references/upload/personalDocuments/new"
                               element={<UploadNewDocument/>}></Route>
                        <Route
                            path="/settings/people/:personId/bank-references/upload/personalDocuments/:documentGroupId/identify"
                            element={<CameraRecognition/>}></Route>
                        {/*dalla policyClaims ritorno al claimDetail /settings/people/:personId/claims/:claimId */}
                        <Route path="/settings/language" element={<Language/>}></Route>
                        <Route path="/settings/vehicles" element={<Vehicles/>}></Route>
                        <Route path="/settings/payments" element={<PaymentsStatus/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId" element={<VehicleDetail/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies" element={<VehiclePolicies/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId"
                               element={<PolicyDetails/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId/policyClaims"
                               element={<PolicyClaims/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId/:policyTitleId"
                               element={<Documents/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId/titlesHistory"
                               element={<TitlesHistory/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId/titlesHistory/:policyTitleId"
                               element={<Documents/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId/documents"
                               element={<Documents/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/policies/:policyId/documents-to-signed"
                               element={<Documents/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/claims" element={<VehicleClaims/>}></Route>
                        <Route path="/settings/vehicles/:vehicleId/claims/:claimId" element={<ClaimDetail/>}></Route>
                        <Route path="/settings/message-preferences" element={<MessagePreferences/>}></Route>
                        <Route path="/settings/personal-documents" element={<PersonalDocuments/>}></Route>
                        <Route path="/settings/personal-documents/:documentGroupId"
                               element={<PersonalDocumentsList/>}></Route>
                        <Route path="/settings/contacts"
                               element={<Contacts/>}></Route>
                        <Route path="/messages" element={<Messages/>}></Route>
                        <Route path="/messages/:type/:id" element={<MessageDetails/>}></Route>
                        <Route path="/claims" element={<Claims/>}></Route>
                        <Route path="/claims/claim/:claimId" element={<ClaimDetail/>}></Route>
                        <Route path="/claims/claim/:claimId/:policyId" element={<PolicyDetails/>}></Route>
                        <Route path="/claims/claim/:claimId/:policyId/:policyTitleId" element={<Documents/>}></Route>
                        <Route path="/claims/claim/:claimId/:policyId/titlesHistory" element={<TitlesHistory/>}></Route>
                        <Route path="/claims/claim/:claimId/:policyId/titlesHistory/:policyTitleId"
                               element={<Documents/>}></Route>
                        <Route path="/claims/claim/:claimId/:policyId/documents" element={<Documents/>}></Route>
                        <Route path="/claims/claim/:claimId/:policyId/policyClaims" element={<PolicyClaims/>}></Route>
                        <Route path="/dashboard/policies/:policyId" element={<PolicyDetails/>}></Route>
                        <Route path="/dashboard/policies/:policyId/:policyTitleId" element={<Documents/>}></Route>
                        <Route path="/dashboard/policies/:policyId/titlesHistory" element={<TitlesHistory/>}></Route>
                        <Route path="/dashboard/policies/:policyId/titlesHistory/:policyTitleId"
                               element={<Documents/>}></Route>
                        <Route path="/dashboard/policies/:policyId/documents" element={<Documents/>}></Route>
                        <Route path="/dashboard/policies/:policyId/documents-to-signed" element={<Documents/>}></Route>
                        <Route path="/dashboard/policies/:policyId/policyClaims" element={<PolicyClaims/>}></Route>
                        <Route path="/contacts" element={<Contacts/>}></Route>
                        <Route path="/settings/profile/code" element={<Pin/>}></Route>
                        <Route path="/settings/profile/code/confirm" element={<ConfirmedPin/>}></Route>
                        <Route path="/payments" element={<Payments/>}></Route>
                        <Route path="/payments/payment-method" element={<PaymentsMethod/>}></Route>
                        <Route path="/payments/payment-method/checkout" element={<PaymentsCheckout/>}></Route>
                        <Route path="/payments/:notificationId/summary" element={<PaymentsSummary/>}></Route>
                        <Route path="/payments/:notificationId/summary/:titleId"
                               element={<PaymentsTitleDetail/>}></Route>
                        <Route path="/payments/:notificationId/summary/payment-method"
                               element={<PaymentsMethod/>}></Route>
                        <Route path="/payments/:notificationId/summary/payment-method/checkout"
                               element={<PaymentsCheckout/>}></Route>
                        <Route path="/signing" element={<Signing/>}></Route>
                        <Route path="/signing/:personId/upload" element={<UploadDocumentToVerify/>}></Route>
                        <Route path="/signing/:personId/upload/personalDocuments"
                               element={<PersonalDocuments/>}></Route>
                        <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId"
                               element={<PersonalDocumentsSelection/>}></Route>
                        <Route path="/signing/:personId/upload/personalDocuments/new"
                               element={<UploadNewDocument/>}></Route>
                        <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify"
                               element={<CameraRecognition/>}></Route>
                        <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify/otp"
                               element={<OtpVerifyID/>}></Route>
                        <Route path="/signing/:personId/upload/personalDocuments/:documentGroupId/identify/otp/confirm"
                               element={<ConfirmOtpVerifyID/>}></Route>
                        <Route path="/signing/:personId/upload/identify" element={<CameraRecognition/>}></Route>
                        <Route path="/signing/:personId/upload/identify/otp" element={<OtpVerifyID/>}></Route>
                        <Route path="/signing/:personId/upload/identify/otp/confirm"
                               element={<ConfirmOtpVerifyID/>}></Route>
                        <Route path="/signing/:personId/uploadBack" element={<UploadDocumentToVerify/>}></Route>
                        <Route path="/signing/:personId/uploadBack/uploadFront"
                               element={<UploadDocumentToVerify/>}></Route>
                        <Route path="/signing/:personId/uploadBack/uploadFront/identify"
                               element={<CameraRecognition/>}></Route>
                        <Route path="/signing/:personId/uploadBack/uploadFront/identify/otp"
                               element={<OtpVerifyID/>}></Route>
                        <Route path="/signing/:personId/uploadBack/uploadFront/identify/otp/confirm"
                               element={<ConfirmOtpVerifyID/>}></Route>
                        <Route path="/regulations" element={<Regulations/>}></Route>
                        <Route path="/claims/open"
                               element={<OpenClaim/>}></Route>
                        <Route path="/claims/open/carClaim"
                               element={<CarClaimForm/>}></Route>
                        <Route path="/claims/open/carClaim/:claimId"
                               element={<CarClaimForm/>}></Route>
                        <Route path="/claims/open/genericClaim"
                               element={<GenericClaimForm/>}></Route>
                        <Route path="/claims/open/genericClaim/:claimId"
                               element={<GenericClaimForm/>}></Route>
                        <Route path="/claims/open/carClaim/:claimId/attachImages"
                               element={<AttachImagesToClaimForm/>}></Route>
                        <Route path="/claims/open/carClaim/:claimId/attachAccidentReportImages"
                               element={<AttachImagesToClaimForm/>}></Route>
                        <Route path="/claims/open/genericClaim/:claimId/attachImages"
                               element={<AttachImagesToClaimForm/>}></Route>
                        <Route path="/refunds" element={<Refunds/>}></Route>
                        <Route path="/refunds/:personId/upload" element={<UploadBankRefs/>}></Route>
                        <Route path="/refunds/:personId/upload/personalDocuments"
                               element={<PersonalDocuments/>}></Route>
                        <Route path="/refunds/:personId/upload/personalDocuments/:documentGroupId"
                               element={<PersonalDocumentsSelection/>}></Route>
                        <Route path="/refunds/:personId/upload/personalDocuments/new"
                               element={<UploadNewDocument/>}></Route>
                        <Route path="/refunds/:personId/upload/personalDocuments/:documentGroupId/identify"
                               element={<CameraRecognition/>}></Route>
                        <Route path="/claims/history"
                               element={<ClaimsHistory/>}></Route>
                        <Route path="/telegram-hook"
                               element={<TelegramHook/>}></Route>
                        <Route path="/settings/support"
                               element={<Support/>}></Route>
                        <Route path="/settings/support/:ticketId"
                               element={<TicketDetail/>}></Route>
                        <Route path="/" element={<Navigate to="/dashboard" replace/>}/>
                        <Route path="/*" element={<Navigate to="/dashboard" replace/>}/>
                    </Routes>
                }
                {
                    navigator.onLine && isLoggedIn /*&& isSetFirstPin && isSetConfirmedPin*/ && !isUnderMaintenanceMode && !(window.location.href.includes('profile')) && !(window.location.href.includes('settings')) && !(window.location.href.includes('messages/principal/')) && !(window.location.href.includes('messages/archive/')) && !(window.location.href.includes('policies/')) && !(window.location.href.includes('claim/')) && !(window.location.href.includes('service-error')) && !(window.location.href.includes('code')) && !(window.location.href.includes('summary')) && !(window.location.href.includes('payment-method')) && !(window.location.href.includes('upload')) && !(window.location.href.includes('camera')) && !(window.location.href.includes('open')) && !(window.location.href.includes('claims/history')) &&
                    <BottomNavigation style={{
                        'borderLeft': 'none',
                        'borderRight': 'none',
                    }} value={value} onChange={(event, newValue) => {
                        setValue(newValue);
                        handleNavigation(newValue);
                    }}
                                      showLabels className="fixed-bottom navbar-custom container">
                        <BottomNavigationAction className={'nav-action'} label={t('app.nav-home')}
                                                onClick={() => clickMenu('Home')}
                                                icon={
                                                    value === 0 || window.location.href.includes('dashboard') ?
                                                        <img src={homeHoverIcon} alt="home-hover-icon"/> :
                                                        <img src={homeIcon} alt="home-icon"/>
                                                }/>
                        {(isDesktop || (!isDesktop && countNavItems() <= 2)) &&
                            <BottomNavigationAction className={'nav-action'} label={t('app.nav-agent')}
                                                    onClick={() => clickMenu('Contacts')}
                                                    icon={value === 1 || window.location.href.includes('contacts') ?
                                                        <img src={brokerHoverIcon} alt="broker-hover-icon"/> :
                                                        <img src={brokerIcon} alt="broker-icon"/>
                                                    }/>
                        }
                        {(isDesktop || (!isDesktop && countNavItems() <= 3)) &&
                            <BottomNavigationAction className={'nav-action'} label={t('app.nav-claims')}
                                                    onClick={() => clickMenu('Claims')}
                                                    icon={value === 2 || window.location.href.includes('claims') ?
                                                        <img src={claimsHoverIcon} alt="claims-icon"/> :
                                                        <img src={claimsIcon} alt="claims-icon"/>
                                                    }/>
                        }
                        {
                            countNotification > 0 &&
                            <BottomNavigationAction
                                className={value === 3 || window.location.href.includes('payments') ? 'nav-action' : "red-action"}
                                label={t('app.nav-payment')}
                                onClick={() => clickMenu('Payments')}
                                icon={value === 3 || window.location.href.includes('payments') ?
                                    <StyledBadge badgeContent={countNotification} overlap={'rectangular'}>
                                        <img src={paymentHoverIcon} alt="payments-icon"/>
                                    </StyledBadge> :
                                    <StyledBadge badgeContent={countNotification} overlap={'rectangular'}>
                                        <img src={paymentIcon} alt="payments-icon"/>
                                    </StyledBadge>
                                }/>
                        }
                        {
                            countSigning > 0 &&
                            <BottomNavigationAction
                                className={value === 4 || window.location.href.includes('signing') ? 'nav-action' : "red-action"}
                                label={t('app.nav-signing')}
                                onClick={() => clickMenu('Signing')}
                                icon={value === 4 || window.location.href.includes('signing') ?
                                    <StyledBadge badgeContent={countSigning} overlap={'rectangular'}>
                                        <img src={signingHoverIcon} alt="signing-icon"/>
                                    </StyledBadge> :
                                    <StyledBadge badgeContent={countSigning} overlap={'rectangular'}>
                                        <img src={signingRedIcon} alt="signing-icon"/>
                                    </StyledBadge>
                                }/>
                        }
                        {
                            countSigning === 0 && isLastDocumentSigned &&
                            <BottomNavigationAction className={'nav-action'} label={t('app.nav-signing')}
                                                    onClick={() => clickMenu('Signing')}
                                                    icon={value === 5 || window.location.href.includes('signing') ?
                                                        <img src={signingHoverIcon} alt="signing-icon"/> :
                                                        <img src={signingIcon} alt="signing-icon"/>}/>
                        }
                        {
                            countRegulations > 0 &&
                            <BottomNavigationAction
                                className={value === 6 || window.location.href.includes('regulations') ? 'nav-action' : "red-action"}
                                label={t('app.nav-regulations')}
                                onClick={() => clickMenu('Regulations')}
                                icon={value === 6 || window.location.href.includes('regulations') ?
                                    <StyledBadge badgeContent={countRegulations} overlap={'rectangular'}>
                                        <img src={regulationsHoverIcon} alt="regulations-icon"/>
                                    </StyledBadge> :
                                    <StyledBadge badgeContent={countRegulations} overlap={'rectangular'}>
                                        <img src={regulationsIcon} alt="regulations-icon"/>
                                    </StyledBadge>
                                }/>
                        }
                        {
                            countRefunds > 0 &&
                            <BottomNavigationAction
                                className={value === 7 || window.location.href.includes('refunds') ? 'nav-action' : "red-action"}
                                label={t('app.nav-refunds')}
                                onClick={() => clickMenu('Refunds')}
                                icon={value === 7 || window.location.href.includes('refunds') ?
                                    <StyledBadge badgeContent={countRefunds} overlap={'rectangular'}>
                                        <img src={refundsHoverIcon} alt="refunds-icon"/>
                                    </StyledBadge> :
                                    <StyledBadge badgeContent={countRefunds} overlap={'rectangular'}>
                                        <img src={refundsIcon} alt="refunds-icon"/>
                                    </StyledBadge>
                                }/>
                        }
                    </BottomNavigation>
                }
            </div>
            <Dialog
                components={{Backdrop: StyledBackdrop}}
                id='install-app-dialog'
                open={isVisible}
                TransitionComponent={Transition}
                keepMounted
                onClose={hide}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        'position': 'absolute',
                        'bottom': '0',
                        'left': 'auto',
                        'right': 'auto',
                        'width': '95%',
                        'backgroundColor': "transparent",
                        'boxShadow': 'none',
                        'color': themeContext.color.neutral600
                    }
                }}>
                <DialogTitle onClick={hide}>
                    <img src={exitIconWhite} className={'exit-button-white cursor-pointer'} alt={'exit'}></img>
                </DialogTitle>
                <DialogContent className={'dialog-inner-content'}>
                    <DialogTitle id="alert-dialog-slide-title" style={{'textAlign': 'center'}}>
                        {t('app.modal-title')}
                    </DialogTitle>
                    <Divider/>
                    {
                        ((isIos() && isSafari()) || (isIpad() && isSafari())) &&
                        <DialogContent>
                            {t('app.modal-ios-safari-1')}
                            <img src={iosShareIcon} alt="share icon" width="30" height="30"/>
                            {t('app.modal-ios-safari-2')}
                            <span className="font-weight-bold">{t('app.modal-ios-safari-3')}</span>
                            <img src={iosAddIcon} width="30" height="30" alt="add icon"/>
                            {t('app.modal-ios-safari-4')}
                            <span className="font-weight-bold">{t('app.modal-appName')}</span>
                            {t('app.modal-ios-safari-5')}
                        </DialogContent>
                    }
                    {
                        isIos() && !isSafari() &&
                        <DialogContent>
                            {t('app.modal-ios-notsafari-1')}
                            <span className="font-weight-bold">{window.location.hostname}</span>
                            {t('app.modal-ios-notsafari-2')}
                            <span className="font-weight-bold">{t('app.modal-ios-notsafari-3')}</span>
                            <img src={iosSafariIcon} width="30" height="30" alt="safari icon"/>
                            {t('app.modal-ios-notsafari-4')}<span
                            className="font-weight-bold">{t('app.modal-appName')}</span>
                            {t('app.modal-ios-notsafari-5')}
                        </DialogContent>
                    }
                    {
                        isMac() && isSafari() && !isIpad() &&
                        <DialogContent>
                            {t('app.modal-mac-safari-1')}
                            <span className="font-weight-bold">{window.location.hostname}</span>
                            {t('app.modal-mac-safari-2')}
                            <span className="font-weight-bold">{t('app.modal-mac-safari-3')}</span>
                            <img src={chromeIcon} width="30" height="30" alt="chrome icon"/>
                            {t('app.modal-mac-safari-4')}
                            <span className="font-weight-bold">{t('app.modal-appName')}</span>
                            {t('app.modal-mac-safari-5')}
                        </DialogContent>
                    }
                    {
                        !isIos() && !isSafari() &&
                        <DialogActions>
                            <Button onClick={handleInstall} color="primary" fullWidth style={{'textTransform': 'none'}}>
                                {t('app.modal-install-button')}
                            </Button>
                        </DialogActions>
                    }
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}
