import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import goTo from "../../images/goto-icon.svg";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import pdfIcon from "../../images/file-png-icon.png";
import nothing from "../../images/nothing-icon.svg";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    listTextSelected: {
        color: `${themeContext.color.neutral600} !important`,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
}));

export default function Documents() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const policyId = params.policyId;
    const titleId = params.policyTitleId;
    const [documents, setDocuments] = useState([]);
    const [documentsPolicy, setDocumentsPolicy] = useState([]);
    const policyDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy`;
    const titleDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/title`;
    const getDetailsUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [serviceError, setServiceError] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getDocuments();
        }
    }, []);

    const turnBack = () => {
        return navigate(-1);
    }

    const getTitleDocumentToSigned = (titleId) => {
        fetch(titleDocumentUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                titleId: titleId
            })
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            if (res !== null) {
                res.map((value, index) => {
                    if (value.type === 'copia_da_restituire') {
                        setDocuments(current => [...current, value])
                    }
                })
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getDocuments = () => {
        setIsLoadingList(true)
        let url;
        let body = "";
        //TITLE DOCUMENT
        if (titleId !== undefined) {
            body = JSON.stringify({
                titleId: params.policyTitleId
            })
            url = titleDocumentUrl
            //setDocuments([])
            fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: body
            }).then((res) => {
                if (res) {
                    return res.json();
                }
            }).then((res) => {
                if (res !== null) {
                    if (titleId !== undefined) {
                        res.map((value, index) => {
                                setDocuments(current => [...current, value])
                            }
                        )
                    }
                }
            }).then(() => {
                setIsLoadingList(false)
            }).catch(() => {
                setServiceError(true)
            });
        } else {
            if (window.location.href.includes('/documents-to-signed')) {
                fetch(`${getDetailsUrl}/${policyId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },

                }).then((res) => {
                    if (res) {
                        return res.json();
                    }
                }).then((res) => {
                    if (res !== null) {
                        res.lastTitle.map((value, index) => {
                            getTitleDocumentToSigned(value.id)
                        })
                    }
                }).then(() => {
                    setIsLoadingList(false)
                }).catch(() => {
                    setServiceError(true)
                })
            } else {
                //POLICY DOCUMENT
                fetch(`${policyDocumentUrl}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        policyId: policyId
                    }),
                }).then((res) => {
                    if (res) {
                        return res.json();
                    }
                }).then((res) => {
                    if (res !== null) {
                        if (Object.keys(res).length !== 0) {
                            res.map((value, index) => {
                                setDocuments(current => [...current, value])
                            })
                        }
                    }
                    setIsLoadingList(false)
                }).catch(() => {
                    setServiceError(true)
                });
            }
        }
    }

    function reload() {
        window.location.reload();
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                {titleId !== undefined &&
                                    <h6 className="header-title ellipsis-1-v2">{t('documents.title-titles')}</h6>
                                }
                                {policyId !== undefined && titleId === undefined &&
                                    <h6 className="header-title ellipsis-1-v2">{t('documents.title-policies')}</h6>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        {titleId !== undefined &&
                            <h6 className="header-title ellipsis-1-v2">{t('documents.title-titles')}</h6>
                        }
                        {policyId !== undefined && titleId === undefined && !window.location.href.includes('/documents-to-signed') &&
                            <h6 className="header-title ellipsis-1-v2">{t('documents.title-policies')}</h6>
                        }
                        {policyId !== undefined && titleId === undefined && window.location.href.includes('/documents-to-signed') &&
                            <h6 className="header-title ellipsis-1-v2">{t('documents.title-documents-to-signed')}</h6>
                        }
                    </Col>
                    <Col className="col-2">

                    </Col>
                </Row>
                <Container className="top-of-the-language"></Container>
                <Row className="col-list" style={{
                    //'borderLeft': '1px solid #EEE9E5', 'borderRight': '1px solid #EEE9E5',
                }}>
                    <Col className="col-list">
                        {
                            !isLoadingList && (documents.length === 0 && documentsPolicy.length === 0) &&
                            <Container>
                                <Row>
                                    <img src={nothing} className="noMessages"
                                         alt={t('documents.nothing-text-1')}
                                    ></img>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('documents.nothing-text-1')}</span>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        <div className={classes.rootList}>
                            <List component="nav" aria-label="main mailbox folders">
                                {
                                    isLoadingList &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }
                                {!isLoadingList && documents.length > 0 && documents.map((document, index) => (
                                    <Fragment key={index}>
                                        <a href={document.url} target="_blank" style={{'textDecoration': 'none'}}
                                           rel="noopener noreferrer">
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={pdfIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                <span className="ellipsis-1">
                                                    {document.name}
                                            </span>
                                                </ListItemText>
                                            </ListItem>
                                        </a>
                                        <Divider/>
                                    </Fragment>
                                ))
                                }
                                {
                                    !isLoadingList && documentsPolicy.length !== 0 &&
                                    <Fragment key={0}>
                                        <a href={documentsPolicy.url} target="_blank" rel="noopener noreferrer"
                                           style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={pdfIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <span className="ellipsis-1">
                                                        <span
                                                            className={classes.listText}>{t('documents.title-policy')}</span>
                                                    </span>
                                                </ListItemText>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </a>
                                        <Divider/>
                                    </Fragment>
                                }
                            </List>
                        </div>
                    </Col>
                </Row>
            </Container>

    )
}