import React, {Fragment, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import accountExecFemale from "../../images/accountExec-female-icon.svg";
import accountExecMale from "../../images/accountExec-male-icon.svg";
import share from "../../images/external-link.svg";
import FileSaver from "file-saver";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button, Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import feedbackIcon from "../../images/feedback-beta-icon.svg";
import customerServiceIcon from "../../images/customer-service.svg";
import FsLightbox from "fslightbox-react";
import back from "../../images/back-icon.svg";
import Header from "../components/Header.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import SupportTicketDialog from "../components/SupportTicketDialog.js";
import DialogOperationComplete from "../components/DialogOperationComplete.js";
import {StyledBackdrop} from "../styles/Styles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 auto !important",
        marginBottom: "30px !important",
        background: `${themeContext.color.mainActive} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        height: "190px !important",
    },
    cardTextHigh: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '100 !important',
        fontSize: '12px !important'
    },
    cardTextMid: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: 'bold !important',
        fontSize: '21px !important'
    },
    cardTextLow: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1.6 !important',
        fontWeight: '600 !important',
        fontSize: '12px !important',
        textDecoration: "underline !important",
        float: 'left !important'
    },
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    listText: {
        fontSize: '18px !important'
    },
    large: {
        width: '64px !important',
        height: '64px !important',
    },
    dialogTitle: {
        'textAlign': 'center !important',
        'paddingTop': '10px !important',
        'paddingBottom': '2px !important',

    },
    dialogTitle2: {
        'textAlign': 'center !important',
        'lineHeight': '1.3 !important',
        'padding': '16px 24px !important',
        'fontSize': '15px !important',
        'fontWeight': '500 !important'
    },
    dialogDescription: {
        'textAlign': 'center !important',
        'paddingTop': '0px !important',
        'paddingBottom': '15px !important',
    }
}));

function MailTo({
                    email,
                    subject,
                    body,
                    ...props
                }) {
    return (
        <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
           style={{
               'textDecoration': 'none',
               'width': '100%',
               'margin': '0 auto'
           }}>
            {props.children}
        </a>
    );
}

export default function Contacts() {
    const token = sessionStorage.getItem('token');
    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const accountExecVCFUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExecVcf`;
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const [accountExec, setAccountExec] = useState([]);
    const classes = useStyles();
    const client = new ClientJS();
    const [getPermission, setGetPermission] = useState(false)
    const navigate = useNavigate();
    const [openCustomerService, setOpenCustomerService] = React.useState(false);
    const [openAppSupport, setOpenAppSupport] = React.useState(false);
    const [openOpenClaims, setOpenOpenClaims] = React.useState(false);
    const [phone, setPhone] = useState("");
    const [serviceError, setServiceError] = useState(false);
    const [isLoadingAccountExec, setIsLoadingAccountExec] = useState(false)
    const {t, i18n} = useTranslation();
    const [openNewRequestDialog, setOpenNewRequestDialog] = useState(false)
    const [openSaveCompletedDialog, setOpenSaveCompletedDialog] = useState(false);

    useEffect(() => {
        sessionStorage.setItem('root', '/contacts')
        if (navigator.onLine) {
            getAccountExec();

            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            handleRinging();
        } else {
            getAccountExecOffline();
        }
    }, []);
    const [bellRinging, setBellRinging] = useState(false);
    const [toggler, setToggler] = useState(false);

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        //console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
        //console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }


    function getAccountExec() {
        setIsLoadingAccountExec(true)
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.phone && data.phone.startsWith("+39")) {
                setPhone(data.phone.slice(3))
            }
            setAccountExec(data);
            setIsLoadingAccountExec(false)
            putElementFromIndexedDB('accountExec', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getAccountExecOffline() {
        getElementFromIndexedDB('accountExec').then((response) => {
            return response.value;
        }).then((data) => {
            setAccountExec([])
            setAccountExec(data)
        });
    }

    function shareVCard() {
        fetch(`${accountExecVCFUrl}`, {
            method: 'GET',
            headers: {
                //'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            fetch(res.message, {
                method: 'GET',
            }).then((res) => {
                return res.text();
            }).then((res) => {
                let file = new Blob([res], {type: "text/vcard;charset=utf-8"});
                FileSaver.saveAs(file, `${accountExec.firstName}_${accountExec.lastName}`);
            });
        }).catch(() => {
            setServiceError(true)
        })
    }

    const handleClickOpen = () => {
        setOpenCustomerService(true);
    };

    const handleClose = () => {
        setOpenCustomerService(false);
    };

    const handleClickOpenAppSupport = () => {
        setOpenNewRequestDialog(true)
    };

    const handleCloseAppSupport = () => {
        setOpenAppSupport(false);
    };

    const handleCloseOpenClaim = () => {
        setOpenOpenClaims(false);
    };

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const completeAndClose = () => {
        setOpenNewRequestDialog(false);
        setTimeout(() => {
            setOpenSaveCompletedDialog(true)
        }, 200)
        setTimeout(() => {
            setOpenSaveCompletedDialog(false)
        }, 2000)
    }

    return (
        serviceError ?
            <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Container style={{'paddingTop': '10%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Header leftSrc={window.location.href.includes('settings') ? back : null}
                                    leftAction={turnBack} iconSrc={wideLogoLight}
                                    rightSrc={bellRinging ? BellOn : BellOff}
                                    rightAction={() => navigate('/messages')}/>
                            {
                                /*<Row>
                                <Col>
                                    <h6 className={classes.titles}>{t('contacts.title')}</h6>
                                </Col>
                            </Row>*/
                            }
                            <Row style={{
                                background: themeContext.color.white,
                                paddingTop: '11px'
                            }}>
                                <Col>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Row>
                                                <Col className="col-3">
                                                    {
                                                        accountExec.photoUrl === null ?
                                                            <Avatar alt="avatar"
                                                                    src={accountExec.gender === 'F' ? accountExecFemale : accountExecMale}
                                                                    className={classes.large}
                                                            />
                                                            :
                                                            <Avatar alt="avatar"
                                                                    src={accountExec.photoUrl}
                                                                    className={classes.large}
                                                                    onClick={() => setToggler(!toggler)}
                                                            />
                                                    }
                                                </Col>
                                                <Col className="col-7">
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <h6 className={classes.cardTextHigh}>{t('contacts.card-title')}</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <h3 className={classes.cardTextMid}>{accountExec.firstName}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h3 className={classes.cardTextMid}>{accountExec.lastName}</h3>
                                                        </Col>
                                                    </Row>
                                                    {accountExec.phone !== "" && accountExec.phone !== null && phone !== "" &&
                                                        <Row className="mt-2 float-left w-100">
                                                            <Col>
                                                                <a id="accountExec-phone"
                                                                   href={`tel:${accountExec.phone}`}
                                                                   className={classes.cardTextLow}>+39 {phone}</a>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {accountExec.phone !== "" && accountExec.phone !== null && phone === "" &&
                                                        <Row className="mt-2 float-left w-100">
                                                            <Col>
                                                                <a id="accountExec-phone"
                                                                   href={`tel:${accountExec.phone}`}
                                                                   className={classes.cardTextLow}>{accountExec.phone}</a>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row className="mt-2 float-left w-100">
                                                        <Col>
                                                            <a id="accountExec-email"
                                                               href={`mailto:${accountExec.email}`}
                                                               className={classes.cardTextLow}>{accountExec.email}</a>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="col-2">
                                                    <img src={share} alt="external-link" height="15px"
                                                         className="float-right"
                                                         onClick={shareVCard}/>
                                                </Col>
                                            </Row>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container className="top-of-the-contacts"></Container>
                    <Container style={{
                        'paddingRight': '0px',
                        'paddingLeft': '0px',
                    }}>
                        <Row>
                            <Col style={{
                                'paddingLeft': '0',
                                'paddingRight': '0',
                                //'borderLeft': '1px solid #EEE9E5',
                                //'borderRight': '1px solid #EEE9E5'
                            }}>
                                <div className={classes.rootList}>
                                    <List component="nav">
                                        {/*<Link to="/" style={{'textDecoration': 'none'}}>*/}
                                        <ListItem className={classes.listItem} button onClick={handleClickOpen}
                                                  disabled={isLoadingAccountExec}>
                                            <ListItemIcon>
                                                <img src={customerServiceIcon} alt="image" width="28px"/>
                                            </ListItemIcon>
                                            <ListItemText primary={t('contacts.list-customer-service')}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        {/*</Link>*/}
                                        <Divider/>
                                        {/*<Link to="/" style={{'textDecoration': 'none'}}>*/}
                                        <ListItem className={classes.listItem} button
                                                  onClick={handleClickOpenAppSupport}>
                                            <ListItemIcon>
                                                <img src={feedbackIcon} alt="image" width="28px"/>
                                            </ListItemIcon>
                                            <ListItemText primary={t('contacts.list-app-support')}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        {/*</Link>*/}
                                        <Divider/>
                                    </List>
                                </div>
                            </Col>
                        </Row>
                        <FsLightbox
                            toggler={toggler}
                            sources={[accountExec.photoUrl]}
                            type="image"
                        />
                        <Container className="fixed-bottom"
                                   style={{
                                       'background': 'transparent',
                                       'opacity': '1',
                                       'paddingBottom': "100px"
                                   }}>
                            <Row>
                                <Col>
                                    <Button className="primary-button"
                                            variant="outlined"
                                            size="large"
                                            style={{width: 288}} type="submit"
                                            onClick={() => navigate('/claims/open')}
                                    >{t('claims.open-claims-button')}</Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="bottom-with-navbar"></Container>
                    </Container>
                    <Dialog
                        components={{Backdrop: StyledBackdrop}}
                        open={openCustomerService}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                            style: {
                                'position': 'absolute',
                                'bottom': '0',
                                'left': 'auto',
                                'right': 'auto',
                                'width': '95%',
                                'borderRadius': '10px',
                                'color': themeContext.color.neutral600
                            },
                        }}>
                        <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
                    <span style={{'fontSize': '15px',}}>
                        {t('contacts.modal-title')}
                    </span>
                        </DialogTitle>
                        <DialogContent className={classes.dialogDescription}>
                    <span style={{
                        'fontSize': '20px',
                        'fontWeight': "bold",
                    }}>
                        {accountExec.firstName} {accountExec.lastName}
                    </span>
                        </DialogContent>
                        <Divider/>
                        {
                            accountExec.phone !== null &&
                            <Fragment>
                                <DialogActions>
                                    <a href={`tel:${accountExec.phone}`}
                                       style={{
                                           'textDecoration': 'none',
                                           'width': '100%',
                                           'margin': '0 auto'
                                       }}>
                                        <Button color="primary" fullWidth
                                                style={{
                                                    'textTransform': 'none',
                                                    'color': themeContext.color.green
                                                }}>
                                            {t('claimDetail.modal-tel-button')}
                                        </Button>
                                    </a>
                                </DialogActions>
                                <Divider/>
                            </Fragment>
                        }
                        <DialogActions>
                            <MailTo email={`${accountExec.email}`}
                                    subject={t('contacts.customer-service-email-subject')}
                                    body={`${t('contacts.customer-service-email-text-1')}%0D%0A%0D%0A%0D%0A${t('contacts.customer-service-email-text-2')}%0D%0A${t('contacts.customer-service-email-text-3')}%0D%0A%0D%0A%0D%0A${t('contacts.customer-service-email-text-4')}%0D%0A${t('contacts.customer-service-email-text-5')}%0D%0A`}>
                                <Button color="primary" style={{'textTransform': 'none'}}
                                        fullWidth>
                                    {t('claimDetail.modal-email-button')}
                                </Button>
                            </MailTo>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleClose} style={{
                                'color': themeContext.color.neutral400,
                                'textTransform': 'none'
                            }}
                                    fullWidth>
                                {t('claimDetail.modal-refuse-button')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        components={{Backdrop: StyledBackdrop}}
                        open={openAppSupport}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseAppSupport}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                            style: {
                                'position': 'absolute',
                                'bottom': '0',
                                'left': 'auto',
                                'right': 'auto',
                                'width': '95%',
                                'borderRadius': '10px',
                                'color': themeContext.color.neutral600
                            },
                        }}>
                        <span className={classes.dialogTitle2}>
                        {t('contacts.modal-app-support-title')}
                        </span>
                        <Divider/>
                        <DialogActions>
                            <MailTo email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL}
                                    subject={t('contacts.app-support-email-subject')}
                                    body={`${t('contacts.app-support-email-text-1')}%0D%0A${t('contacts.app-support-email-text-2')}%0D%0A%0D%0A%0D%0A${t('contacts.app-support-email-text-3')}%0D%0A${t('contacts.app-support-email-text-4')}%0D%0A%0D%0A${t('contacts.app-support-email-text-5')}%0D%0A${t('contacts.app-support-email-text-6')}%0D%0A${t('contacts.app-support-email-text-7')}%0D%0A${t('contacts.app-support-email-text-8')}%0D%0A%0D%0A%0D%0A${t('contacts.app-support-email-text-9')}%0D%0A${t('contacts.app-support-email-text-10')}%0D%0A%0D%0A${t('contacts.app-support-email-text-11')}%0D%0A${t('contacts.app-support-email-text-12')}%0D%0A${t('contacts.app-support-email-text-13')}%0D%0A%0D%0A%0D%0A${t('contacts.app-support-email-text-14')}%0D%0A${t('contacts.app-support-email-text-15')}%0D%0A`}>
                                <Button color="primary" style={{'textTransform': 'none'}}
                                        fullWidth>
                                    {t('claimDetail.modal-email-button')}
                                </Button>
                            </MailTo>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleCloseAppSupport}
                                    style={{
                                        'color': themeContext.color.neutral400,
                                        'textTransform': 'none'
                                    }}
                                    fullWidth>
                                {t('claimDetail.modal-refuse-button')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        components={{Backdrop: StyledBackdrop}}
                        open={openOpenClaims}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseOpenClaim}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                            style: {
                                'position': 'absolute',
                                'bottom': '0',
                                'left': 'auto',
                                'right': 'auto',
                                'width': '95%',
                                'borderRadius': '10px',
                                'color': themeContext.color.neutral600
                            },
                        }}>
                        <span className={classes.dialogTitle2}>
                        {t('contacts.modal-open-claim-title')}
                        </span>
                        <Divider/>
                        <DialogActions>
                            <MailTo email={`${accountExec.email}`}
                                    subject={t('contacts.open-claim-email-not-auto-subject')}
                                    body={`${t('contacts.open-claim-email-not-auto-text-1')}%0D%0A${t('contacts.open-claim-email-not-auto-text-2')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-not-auto-text-3')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-not-auto-text-4')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-not-auto-text-5')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-not-auto-text-6')}%0D%0A${t('contacts.open-claim-email-not-auto-text-7')}%0D%0A`}>
                                <Button color="primary" style={{'textTransform': 'none'}}
                                        fullWidth>
                                    {t('contacts.modal-open-claim-not-auto-button')}
                                </Button>
                            </MailTo>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <MailTo email={`${accountExec.email}`} subject={t('contacts.open-claim-email-auto-subject')}
                                    body={`${t('contacts.open-claim-email-auto-text-1')}%0D%0A${t('contacts.open-claim-email-auto-text-2')}%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-3')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-4')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-5')}%0D%0A${t('contacts.open-claim-email-auto-text-6')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-7')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-8')}%0D%0A${t('contacts.open-claim-email-auto-text-9')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-10')}%0D%0A${t('contacts.open-claim-email-auto-text-11')}%0D%0A%0D%0A%0D%0A${t('contacts.open-claim-email-auto-text-12')}%0D%0A${t('contacts.open-claim-email-auto-text-13')}%0D%0A`}>
                                <Button color="primary" style={{'textTransform': 'none'}}
                                        fullWidth>
                                    {t('contacts.modal-open-claim-auto-button')}
                                </Button>
                            </MailTo>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleCloseOpenClaim} style={{
                                'color': themeContext.color.neutral400,
                                'textTransform': 'none'
                            }}
                                    fullWidth>
                                {t('claimDetail.modal-refuse-button')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <SupportTicketDialog
                        openNewRequestDialog={openNewRequestDialog}
                        handleClose={() => setOpenNewRequestDialog(false)}
                        handleSave={() => completeAndClose()}
                        handleServiceError={() => setServiceError(true)}
                        hasChips={false}
                        title={t(`contacts.describe your problem`)}/>
                    <DialogOperationComplete open={openSaveCompletedDialog}
                                             title={t('changeIBAN.confirm-operation')}/>
                </Container>
            </Fragment>
    )
}
