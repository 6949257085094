import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import {useLocation, useNavigate} from 'react-router-dom'
import wideLogoLight from '../../images/wideLogo-icon-new.svg';
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import {base64url_decode, base64url_encode, getClientDatas} from '../service/UtilityService.js';
import {Button, InputAdornment, Link, Slide, TextField} from "@mui/material";
import jwt_decode from 'jwt-decode';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import wideLogoLightBetaBig from '../../images/wide-logo-white.svg';
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import {useMediaQuery} from "react-responsive";
import exitIcon from "../../images/exit-icon.svg";
import spinner from "../../images/spinner.svg";
import DialogSmall from "../components/DialogSmall.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import Resizer from "react-image-file-resizer";
import addIcon from "../../images/add.svg";
import trashIcon from "../../images/trash-icon.svg";
import FsLightbox from "fslightbox-react";
import CropDialog from "../components/CropDialog.js";
import useCountdown from "../middleware/useCountdown.js";
import DialogOperationComplete from "../components/DialogOperationComplete.js";
import {StyledBackdrop} from "../styles/Styles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const useStyles = makeStyles(() => ({
    dialogTitle: {
        'textAlign': 'center !important',
        'lineHeight': '1.3 !important',
        'padding': '16px 24px !important',
        'fontSize': '15px !important',
        'fontWeight': '500 !important'
    },
    accessDialogTitle: {
        color: `${themeContext.color.mainDark} !important`,
        textAlign: 'center !important',
        leadingTrim: 'both !important',
        textEdge: 'cap !important',
        fontSize: '22px !important',
        fontStyle: 'normal !important',
        fontWeight: '700 !important',
        lineHeight: '220% !important',
    },
}));

export default function Login() {
    const root = 'support';
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [badRequestError, setBadRequestError] = useState(false);
    const [usersList, setUsersList] = useState({type: null, token: null, data: null});
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const param_people = query.get('people');
    useEffect(() => {
        if (sessionStorage.getItem('login_error')) {
            sessionStorage.removeItem('login_error')
        }
        if (!!JSON.parse(sessionStorage.getItem('spid-usersList')) && !!sessionStorage.getItem('spid-or-cie-token')) {
            setUsersList({
                type: 'spid',
                token: sessionStorage.getItem('spid-or-cie-token'),
                data: JSON.parse(sessionStorage.getItem('spid-usersList'))
            })
            getSPIDOrCIEAuthorization(JSON.parse(sessionStorage.getItem('spid-usersList')), 'spid', usersList.token);
        }
        if (!!JSON.parse(sessionStorage.getItem('cie-usersList')) && !!sessionStorage.getItem('spid-or-cie-token')) {
            setUsersList({
                type: 'cie',
                token: sessionStorage.getItem('spid-or-cie-token'),
                data: JSON.parse(sessionStorage.getItem('cie-usersList'))
            })
            getSPIDOrCIEAuthorization(JSON.parse(sessionStorage.getItem('cie-usersList')), 'cie', usersList.token);
        }
        if (navigator.onLine) {
            checkAppVersion(process.env.REACT_APP_VERSION);
            const username = localStorage.getItem('username-wide');
            if (username) {
                if (!sessionStorage.hasOwnProperty('spid-or-cie-token')) {
                    checkWebAuthN(username);
                }
            }
        }
        if (sessionStorage.getItem('spid-not-available') || sessionStorage.getItem('spid-not-enable') || sessionStorage.getItem('spid-bad-request')) {
            if (sessionStorage.getItem('spid-bad-request')) {
                setBadRequestError(JSON.parse(sessionStorage.getItem('spid-bad-request')));
            }
            setLoginDialog(true)
        }
        if (query.get('people')) {
            localStorage.setItem('people', true);
        }
    }, []);

    const navigate = useNavigate();
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const defaultValues = {
        email: localStorage.getItem("username-wide") ? localStorage.getItem("username-wide") : "",
        password: "",
    };
    const formValues = useRef(defaultValues);
    const authCtx = useContext(AuthContextMiddleware);
    const [loginDialog, setLoginDialog] = useState(false);
    const [openSupportDialog, setOpenSupportDialog] = useState(false);
    const webAuthNAssertionUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/assertion`;
    const loginUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login`;
    const getSPIDRedirectUrlUrl = `${process.env.REACT_APP_BASE_URL}/management/spid/getRedirectUrl`;
    const getCIERedirectUrlUrl = `${process.env.REACT_APP_BASE_URL}/management/spid/getAuthentication?entityId=${process.env.REACT_APP_CIE_ENTITY_ID}`;
    const removeTokenUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/removeToken`;
    const isUpdatedUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/isUpdated`;
    const loginWithUser = `${process.env.REACT_APP_BASE_URL}/management/auth/login/verify/user`;
    const lastStepSpidUrl = `${process.env.REACT_APP_BASE_URL}/management/spid/namirialAuthorize/lastStep`;
    const lastStepCieUrl = `${process.env.REACT_APP_BASE_URL}/management/spid/authorize/lastStep`;
    const supportMailOtpUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/mail-otp/send`;
    const supportVerifyOtpUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/mail-otp/verify`;
    const newUnAuthTicketUrl = `${process.env.REACT_APP_BASE_URL}/profile/support/unauthenticated/new`;
    const [webauthn, setWebauthn] = useState(localStorage.getItem('webauthn'));
    const [errorType, setErrorType] = useState("");
    const [serviceError, setServiceError] = useState(false);
    const classes = useStyles();
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const isRepeat = useMediaQuery({query: '(min-width: 600px)'})
    const [openAccessDialog, setOpenAccessDialog] = useState(false);
    const [openLoggedUserSelectionDialog, setOpenLoggedUserSelectionDialog] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [selectedFiscalCode, setSelectedFiscalCode] = useState(null)
    const [userNotSelectedDialog, setUserNotSelectedDialog] = useState(false)
    const [loginWithUserError, setLoginWithUserError] = useState(false);
    const [openConfirmExitDialog, setOpenConfirmExitDialog] = useState(false);
    const {t, i18n} = useTranslation();


    const [isLoadingForm, setIsLoadingForm] = useState(false)
    const [openNewRequestDialog, setOpenNewRequestDialog] = useState(false)
    const [errors, setErrors] = useState({})
    const ticketDefaultValues = {
        email: "",
        otp: "",
        title: "",
        description: "",
        attachments: null,
        deviceData: JSON.stringify(getClientDatas()),
    };
    const [ticketFormValues, setTicketFormValues] = useState(ticketDefaultValues)
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isLoadingAttach, setIsLoadingAttach] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const new_ids = useRef([]);
    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});
    const [openDoNotSaveDialog, setOpenDoNotSaveDialog] = useState(false);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [tempImages, setTempImages] = useState([]);
    const [tempImagesUrl, setTempImagesUrl] = useState([]);
    const ids = useRef([]);
    const cropperRef = useRef(null);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const [cropper, setCropper] = useState(null);
    const [activeDialog, setActiveDialog] = useState("attach");
    const [ticketId, setTicketId] = useState(undefined);
    const [documents, setDocuments] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState(false);
    const [openDoNotSaveRequestDialog, setOpenDoNotSaveRequestDialog] = useState(false);
    const [imageToDelete, setImageToDelete] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [step, setStep] = useState(1);
    const imagesMaxLength = 4;
    const {seconds, disabled, startCountdown} = useCountdown(60);
    const [openSaveCompletedDialog, setOpenSaveCompletedDialog] = useState(false);
    const titleMinLength = 4;
    const titleMaxLength = 255;
    const descriptionMinLength = 10;
    const descriptionMaxLength = 65535;
    const [uploadError, setUploadError] = useState(null);

    window.addEventListener('offline', () => {
        window.location.reload();
        return 0;
    });

    const handleCloseSupportDialog = () => {
        setOpenSupportDialog(false);
    }

    const handleLoginDialog = () => {
        setLoginDialog(current => !current);
        sessionStorage.removeItem('spid-not-enable')
        sessionStorage.removeItem('spid-not-available')
        sessionStorage.removeItem('spid-bad-request')
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const enteredEmail = formValues.current.email;
        const enteredPassword = formValues.current.password;
        setIsLoadingLogin(true)
        fetch(loginUrl, {
            method: 'POST',
            body: JSON.stringify({
                email: enteredEmail,
                password: enteredPassword,
                returnSecureToken: true,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                setIsLoadingLogin(false)
                if (res.status === 401) {
                    return res.json().then((data) => {
                        let errorMessage = '401';
                        setErrorType(errorMessage)
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    });
                } else {
                    return res.json().then((data) => {
                        let errorMessage = '500';
                        setErrorType(errorMessage)
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    });
                }
            }
        }).then((data) => {
            if (data.isLegacy === true) {
                setIsLoadingLogin(false)
                postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                    accessToken: data.accessToken,
                    expiresIn: data.expiresIn,
                    isLegacy: data.isLegacy,
                    pinCode: data.pinCode,
                    refreshExpiresIn: data.pinCode,
                    refreshToken: data.refreshToken,
                    userId: data.userId
                })
            } else {
                let decoded = jwt_decode(data.accessToken);
                if (localStorage.getItem('username-wide')) {
                    let prevUsername = localStorage.getItem('username-wide');
                    if (prevUsername !== decoded.email) {
                        sessionStorage.clear();
                        localStorage.removeItem('webauthn')
                    }
                }
                localStorage.setItem('username-wide', decoded.email);
                sessionStorage.removeItem('login_error');
                authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
                setIsLoadingLogin(false)
                if (localStorage.getItem('people')) {
                    navigate('/settings/people');
                    localStorage.removeItem('people')
                } else {
                    navigate('/dashboard');
                }
            }
        }).catch((e) => {
            setLoginDialog(true)
            if (sessionStorage.getItem('login_error')) {
                setTimeout(() => {
                    setOpenAccessDialog(true)
                }, 500)
            } else {
                sessionStorage.setItem('login_error', true);
            }
        });
    };

    const handlePasswordVisibility = e => {
        const password = document.querySelector("#password")
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setPasswordVisibility(current => !current)
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        formValues.current = {...formValues.current, [name]: value,}
    };

    const handleTicketInputChange = (e) => {
        const {name, value} = e.target;
        setTicketFormValues({...ticketFormValues, [`${name}`]: value,});
        setErrors({
            ...errors,
            [`${name}`]: null
        })
        checkValidForm();
    }

    function checkWebAuthN(username) {
        fetch(`${webAuthNAssertionUrl}/start`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (localStorage.getItem('biometrics') && localStorage.getItem('webauthn') && webauthn && !isDesktop) {
                assertCredentials(username);
            }
        }).catch((e) => {
            //console.log('errore primo', e)
            //if (e.message === 'no.credentials') {
            localStorage.removeItem('webauthn');
            setWebauthn(false);
            setErrorType('500')
            //}
        })
    }

    function assertCredentials() {
        const username = localStorage.getItem('username-wide');
        setIsLoadingLogin(true)
        fetch(`${webAuthNAssertionUrl}/start`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            //console.log('assertion:', response);
            if (!response.ok) {
                setIsLoadingLogin(false)
                return false;
            }
            response.json().then((assertResponse) => {
                let keyData = assertResponse.publicKeyCredentialRequestOptions;
                let allowCredentials = keyData.allowCredentials
                    .map(c => {
                        c.id = base64url_decode(c.id);
                        c.transports = undefined;
                        return c;
                    });
                let cred = navigator.credentials.get({
                    publicKey: {
                        challenge: base64url_decode(keyData.challenge),
                        timeout: 60000,
                        rpId: keyData.rpId,
                        allowCredentials: allowCredentials,
                        userVerification: 'discouraged'
                    },
                    extensions: keyData.extensions
                });
                //console.log(cred);
                cred.then((credData) => {
                    //console.log('cred - login:', credData);
                    let assertionRequest = {
                        credential: {
                            clientExtensionResults: {},
                            id: credData.id,
                            rawId: base64url_encode(credData.rawId),
                            response: {
                                authenticatorData: base64url_encode(credData.response.authenticatorData),
                                clientDataJSON: base64url_encode(credData.response.clientDataJSON),
                                signature: base64url_encode(credData.response.signature)
                            },
                            type: "public-key"
                        },
                        assertionId: assertResponse.assertionId
                    }
                    fetch(`${webAuthNAssertionUrl}/finish`, {
                        method: 'POST',
                        body: JSON.stringify(assertionRequest),
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then((finishResponse) => {
                        finishResponse.json().then((r) => {
                            if (r.isLegacy === true) {
                                setIsLoadingLogin(false)
                                postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                                    accessToken: r.accessToken,
                                    expiresIn: r.expiresIn,
                                    isLegacy: r.isLegacy,
                                    pinCode: r.pinCode,
                                    refreshExpiresIn: r.pinCode,
                                    refreshToken: r.refreshToken,
                                    userId: r.userId
                                })
                            } else {
                                if (r.isAccepted === false) {
                                    setIsLoadingLogin(false)
                                    postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                                        accessToken: r.accessToken,
                                        expiresIn: r.expiresIn,
                                        isLegacy: r.isLegacy,
                                        pinCode: r.pinCode,
                                        refreshExpiresIn: r.pinCode,
                                        refreshToken: r.refreshToken,
                                        userId: r.userId
                                    })
                                } else {
                                    //console.log("finish assertion:", r);
                                    authCtx.login(r.accessToken, r.refreshToken, r.pinCode);
                                    setIsLoadingLogin(false);
                                    navigate("/dashboard");
                                }
                            }
                            //console.log(authCtx);
                        });
                    }).catch((e) => {
                        setIsLoadingLogin(false)
                        console.log('error', e);
                        setErrorType('500')
                    })
                }).catch((e) => {
                    setIsLoadingLogin(false)
                    console.log('error, promise rejected', e);
                    setErrorType('500')
                });
            });
        }).catch((e) => {
            setErrorType('500')
        });
    }

    function MailTo({email, subject, body, ...props}) {
        return (<a style={{
                'color': themeContext.color.main,
                'textAlign': "center",
                'textDecoration': 'none',
                'width': '100%'
            }}
                   href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
                {props.children}
            </a>
        );
    }

    const linkToRequestCredentials = () => {
        return navigate('/login/request-credentials');
    }

    const linkToRequestResetPassword = () => {
        return navigate('/login/request-reset-password');
    }

    const getSPIDRedirectUrl = () => {
        fetch(getSPIDRedirectUrlUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                window.location.replace(data.message)
            } else {
                sessionStorage.setItem('spid-not-available', true)
                setLoginDialog(true)
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getCIERedirectUrl = () => {
        fetch(getCIERedirectUrlUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((res) => {
            let saml = res.xmlAuthRequest;
            postRedirect(res.destinationUrl, {
                SAMLRequest: btoa(saml),
                RelayState: res.relayState
            });
        }).catch(() => {
            setServiceError(true)
        })
    }

    function postRedirect(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }

    function reload() {
        window.location.reload();
    }

    function checkAppVersion(version) {
        fetch(isUpdatedUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then(async (res) => {
            if (res.ok) {
                let dbVersion = res.message.split('.').join("");
                let appVersion = version.split('.').join("");
                if (parseInt(dbVersion) > parseInt(appVersion)) {
                    console.log('update')
                    updateVersion()
                } else {
                    console.log(' up to date')
                }
            }
        }).catch(() => {
            setServiceError(true);
        })
    }

    const updateVersion = () => {
        console.log('update started')
        navigator.serviceWorker.getRegistrations().then((r) => {
            return Promise.all(r.map(reg => reg.unregister()));
        }).then(() => {
            fetch(removeTokenUrl, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: sessionStorage.getItem('firebaseToken')
                }),
            }).then((response) => {
                console.log(response)
                fetch(isUpdatedUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    return response.json();
                }).then(async (res) => {
                    window.location.reload()
                }).catch(() => {
                    authCtx.logout();
                    window.location.reload();
                })
            })
        });
    }

    const loginUserSelected = (email) => {
        setIsLoadingLogin(true)
        let otpCode = formValues.current.otpCode;
        fetch(loginWithUser, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                //phone: phoneValue,
                //code: otpCode
            }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
            let decoded = jwt_decode(data.accessToken);
            if (localStorage.getItem('username-wide')) {
                let prevUsername = localStorage.getItem('username-wide');
                if (prevUsername !== decoded.email) {
                    sessionStorage.clear();
                    localStorage.removeItem('webauthn')
                }
            }
            localStorage.setItem('username-wide', decoded.email);
            authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
            navigate('/dashboard');
            setIsLoadingLogin(false)
        }).catch(() => {
            setLoginWithUserError(true)
            setIsLoadingLogin(false)
        })
    }

    const getSPIDOrCIEAuthorization = (users, type, token) => {
        setWebauthn(false);
        if (users.length > 1) {
            setOpenLoggedUserSelectionDialog(true)
        } else if (users.length === 1) {
            if (type === 'spid') {
                loginUserSelectedAfterSPID(users[0].email, users[0].fiscalCode, token)
            } else {
                loginUserSelectedAfterCIE(users[0].email, users[0].fiscalCode, token)
            }
        } else {
            let errorMessage = 'noUserCf';
            setErrorType(errorMessage);
            setLoginDialog(true);
        }
    }

    const loginUserSelectedAfterSPID = (email, cf, token) => {
        setIsLoadingLogin(true)
        fetch(lastStepSpidUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                cf: cf,
                token: sessionStorage.getItem('spid-or-cie-token')
            }),
        }).then((res) => {
            return res.json();
        }).then((data) => {

            let decoded = jwt_decode(data.accessToken);
            if (localStorage.getItem('username-wide')) {
                let prevUsername = localStorage.getItem('username-wide');
                if (prevUsername !== decoded.email) {
                    sessionStorage.clear();
                    localStorage.removeItem('webauthn')
                }
            }
            localStorage.setItem('username-wide', decoded.email);
            authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
            sessionStorage.removeItem('spid-or-cie-token')
            sessionStorage.removeItem('spid-usersList')
            sessionStorage.removeItem('cie-usersList')
            if (localStorage.getItem('people')) {
                navigate('/settings/people');
            } else {
                navigate('/dashboard');
            }
            setWebauthn(false);
            setIsLoadingLogin(false)

        }).catch(() => {
            setLoginWithUserError(true)
            setIsLoadingLogin(false)
        })
    }

    const loginUserSelectedAfterCIE = (email, cf, token) => {
        setIsLoadingLogin(true)
        let data = new FormData();
        data.append("SAMLResponse", sessionStorage.getItem('SAMLResponse'));
        data.append("RelayState", sessionStorage.getItem('RelayState'));
        data.append("final", sessionStorage.getItem('final'));
        data.append("token", sessionStorage.getItem('RelayState'));
        data.append("email", email);
        data.append("cf", cf);
        fetch(lastStepCieUrl, {
            method: 'POST',
            body: data,
        }).then((res) => {
            return res.json();
        }).then((data) => {
            let decoded = jwt_decode(data.accessToken);
            if (localStorage.getItem('username-wide')) {
                let prevUsername = localStorage.getItem('username-wide');
                if (prevUsername !== decoded.email) {
                    sessionStorage.clear();
                    localStorage.removeItem('webauthn')
                }
            }
            localStorage.setItem('username-wide', decoded.email);
            authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
            sessionStorage.removeItem('spid-or-cie-token')
            sessionStorage.removeItem('spid-usersList')
            sessionStorage.removeItem('cie-usersList')
            sessionStorage.removeItem('SAMLResponse')
            sessionStorage.removeItem('RelayState')
            sessionStorage.removeItem('final')
            if (localStorage.getItem('people')) {
                navigate('/settings/people');
                localStorage.removeItem('people')
            } else {
                navigate('/dashboard');
            }
            setWebauthn(false);
            setIsLoadingLogin(false)
        }).catch(() => {
            setLoginWithUserError(true)
            setIsLoadingLogin(false)
        })
    }

    const submitForm = async (event) => {
        event.preventDefault();
        setIsLoadingForm(true)
        setErrors({})
        const newErrors = {};
        if (!ticketFormValues.email) {
            newErrors.email = t('Required field');
        }
        if (!ticketFormValues.otp && step === 2) {
            newErrors.otp = t('Required field');
        }
        if (!ticketFormValues.title && step === 3) {
            newErrors.title = t('Required field');
        }
        if (!ticketFormValues.description && step === 3) {
            newErrors.description = t('Required field');
        }
        if (ticketFormValues.title && ticketFormValues.title.length < titleMinLength && step === 3) {
            newErrors.title = t('Insert minimum') + titleMinLength + t('characters');
        }
        if (ticketFormValues.description && ticketFormValues.description.length < descriptionMinLength && step === 3) {
            newErrors.description = t('Insert minimum') + descriptionMinLength + t('characters');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setIsLoadingForm(false)
            return;
        }

        switch (step) {
            case 1:
                const response = await sendMailOtp();
                const data = await response.json();
                if (data.ok) {
                    setStep(current => current + 1);
                } else {
                    if (data.error === 'Too Many Requests') {
                        newErrors.email = 'Troppe richieste effettuate. Riprova più tardi';
                    } else {
                        newErrors.email = 'Qualcosa è andato storto. Riprova più tardi';
                    }
                }
                if (Object.keys(newErrors).length > 0) {
                    setErrors(newErrors);
                }
                setIsLoadingForm(false)

                startCountdown(60);
                break;
            case 2:
                fetch(supportVerifyOtpUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        mail: ticketFormValues.email,
                        code: ticketFormValues.otp
                    })
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                    if (data.ok === true) {
                        setStep(current => current + 1);
                    } else {
                        newErrors.otp = t('OTP error');
                    }
                    if (Object.keys(newErrors).length > 0) {
                        setErrors(newErrors);
                    }
                }).catch((error) => {
                    newErrors.otp = t('OTP error');
                    if (Object.keys(newErrors).length > 0) {
                        setErrors(newErrors);
                    }
                })
                setIsLoadingForm(false)
                break;
            case 3:
                const newTicketResponse = await newTicket();
                const completeTicket = await newTicketResponse.json();
                if (completeTicket.ok) {
                    setOpenNewRequestDialog(false);
                    setTicketFormValues(ticketDefaultValues)
                    setImages([])
                    setImagesUrl([])
                    setTempImages([])
                    setTempImagesUrl([])
                    setAttachments([])
                    ids.current = []
                    new_ids.current = []
                    setErrors({})
                    setIsFormTouched(false)
                    setIsFormInvalid(true)
                    setStep(1)
                    setTimeout(() => {
                        setOpenSaveCompletedDialog(true)
                    }, 200)
                    setTimeout(() => {
                        setOpenSaveCompletedDialog(false)
                    }, 2000)
                } else {
                    setServiceError(true)
                }
                setIsLoadingForm(false)
                break;
        }
    }

    const newTicket = () => {
        delete ticketFormValues.otp;
        if (attachments.length > 0) {
            ticketFormValues.attachments = attachments;
        }
        return fetch(newUnAuthTicketUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ticketFormValues),
        });
    }

    const sendMailOtp = () => {
        return fetch(supportMailOtpUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mail: ticketFormValues.email
            }),
        });
    }

    const resendOtp = async () => {
        const newErrors = {};
        const response = await sendMailOtp();
        const data = await response.json();
        if (data.ok) {
            startCountdown(60);
        } else {
            if (data.error === 'Too Many Requests') {
                newErrors.email = t('Too Many Request');
            }
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }
    }

    const checkValidForm = () => {
        //check if is touched
        if ((ticketFormValues.email !== '' || ticketFormValues.email !== 0) || ticketFormValues.otp !== '' || ticketFormValues.title !== '' || ticketFormValues.description !== '') {
            setIsFormTouched(true);
        }

        //check if is valid
        if (ticketFormValues.email !== '' && ticketFormValues.email !== 0 && ticketFormValues.otp !== '' && ticketFormValues.title !== '' && ticketFormValues.description !== '') {
            setIsFormInvalid(false);
        }
    }

    const doNotSave = (type) => {
        setOpenDoNotSaveDialog(false)
        setOpenUploadDialog(false)
        setImages(tempImages)
        setImagesUrl(tempImagesUrl)
        setUploadError(null)
        ids.current = ids.current.filter(id_to_maintain => {
            if (!new_ids.current.includes(id_to_maintain)) {
                return id_to_maintain
            }
        })
        new_ids.current = []
    }

    const doNotSaveRequest = (type) => {
        setTicketId(undefined)
        setTicketFormValues(ticketDefaultValues)
        setImages([])
        setImagesUrl([])
        setTempImages([])
        setTempImagesUrl([])
        ids.current = []
        new_ids.current = []
        setErrors({})
        setIsFormTouched(false)
        setIsFormInvalid(true)

        setOpenDoNotSaveRequestDialog(false)
        setOpenNewRequestDialog(false)
        setStep(1)
    }

    const handleOnPendingImage = async (e) => {
        setUploadError(null)
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image')) {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            if (err === 'Maximum upload size exceeded') {
                setUploadError(t('File not supported') + '<br />' + t('The maximum image upload size is 20MB'))
            } else if ('File not supported') {
                setUploadError(t('The uploaded format is not supported') + '<br />' + t('Only image formats are supported'))
            }
        }
    }

    const attachImageActions = (documentType, imagesLength) => {
        if (imagesLength >= imagesMaxLength) {
            setOpenPhotoLimitsDialog(true);
        } else {
            handleChangeImage();
        }
    }
    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleDeleteImageDialog = (image) => {
        setImageToDelete({id: image.id, url: image.url});
        setOpenDeleteImageDialog(true)
    }

    const handleAttachImages = async () => {
        setTempImagesUrl(imagesUrl)
        setTempImages(images)
        setOpenUploadDialog(true)

    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            ids.current.push(randomName)
            new_ids.current.push(randomName)
            setImages(current => [...current, {id: randomName, url: cropImage}])
            setImagesUrl(current => [...current, cropImage])
            setAttachments(current => [...current, {name: randomName, base64: base64_string, mimeType: mimetype}])
            setIsLoadingData(false);
            setOpenCropDialog(false);
        }
    }

    const handleDeleteImage = (attachment) => {
        ids.current = ids.current.filter(id => {
            return id !== attachment.id;
        })
        setImages(images.filter(image => {
            return image.id !== attachment.id;
        }))
        setImagesUrl(imagesUrl.filter(imageUrl => {
            return imageUrl !== attachment.url;
        }))
        setAttachments(attachments.filter(att => {
            return att.name !== attachment.id;
        }))
        setOpenDeleteImageDialog(false)
    }

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    return (
        serviceError ?
            <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Container style={{'paddingTop': '10%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
                <div className="row no-gutter">
                    <div className="col-md-6 d-none d-md-flex bg-image">
                        <Row style={{'margin': 'auto'}}>
                            <Col>
                                <img style={{
                                    'height': '74.67px',
                                    'marginBottom': '27px'
                                }} src={wideLogoLightBetaBig} alt="wide-logo-light"/>
                                <h6 style={{
                                    'color': themeContext.color.white,
                                    'fontSize': '16px',
                                    'fontWeight': '300'
                                }}>{t('login.payoff-left-1')}</h6>
                                <h6 style={{
                                    'color': themeContext.color.white,
                                    'fontSize': '16px',
                                    'fontWeight': '300'
                                }}>{t('login.payoff-left-2')}</h6>
                                <h6 style={{
                                    'color': themeContext.color.white,
                                    'fontSize': '16px',
                                    'fontWeight': '300'
                                }}>{t('login.payoff-left-3')}</h6>
                            </Col>
                        </Row>
                    </div>
                    <div className="col-md-6">
                        <div className="login d-flex align-items-center">
                            <div className="container">
                                <Row className="d-flex justify-content-center align-items-center">
                                    <Container className="d-flex justify-content-center align-items-center">
                                        <Row className="w-100">
                                            <img className="wide-logo" src={wideLogoLight} height="50px"
                                                 alt="wide-logo"/>
                                        </Row>
                                    </Container>
                                    <div>
                                        <Row className="mt-3 mb-4 mx-auto">
                                            <Col>
                                                <h6 className="login-title align-center">{t('login.title-1')}</h6>
                                                <h6 className="login-title align-center">{t('login.title-2')}</h6>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <div className="mb-3 mt-4">
                                                <Form onSubmit={submitHandler}>
                                                    <div style={{textAlign: "center"}}>
                                                        <TextField
                                                            id="email"
                                                            className="custom-textfield mb-4"
                                                            label={t('login.email')}
                                                            variant="outlined"
                                                            name="email"
                                                            type="email"
                                                            value={formValues.email}
                                                            defaultValue={defaultValues.email}
                                                            onChange={handleInputChange}
                                                            color="primary"
                                                            InputProps={{
                                                                style: {
                                                                    'borderRadius': '4px',
                                                                    'color': themeContext.color.neutral600,
                                                                    'minWidth': '324px'
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                    <span className="material-icons darkgrey"
                                                                          style={{'transform': 'rotate(90deg)'}}>key</span>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>

                                                    <div style={{textAlign: "center"}}>
                                                        <TextField
                                                            id="password"
                                                            className="custom-textfield"
                                                            label={t('login.password')}
                                                            variant="outlined"
                                                            name="password"
                                                            type="password"
                                                            value={formValues.password}
                                                            onChange={handleInputChange}
                                                            color='primary'
                                                            InputProps={{
                                                                style: {
                                                                    'borderRadius': '4px',
                                                                    'color': themeContext.color.neutral600,
                                                                    'minWidth': '324px'
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {passwordVisibility &&
                                                                            <span
                                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                                onClick={handlePasswordVisibility}>visibility_off</span>}
                                                                        {!passwordVisibility &&
                                                                            <span
                                                                                className="material-icons toggle-password float-right unselectable darkgrey"
                                                                                onClick={handlePasswordVisibility}>visibility</span>}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <Row className="mt-4" style={{'marginBottom': '67px'}}>
                                                        <Col>
                                                            <Link
                                                                className="mt-4 login-subtitle cursor-pointer"
                                                                color="primary" target="_new"
                                                                onClick={() => setOpenSupportDialog(true)} style={{
                                                                'color': themeContext.color.main,
                                                                'marginLeft': '-175px'
                                                            }}>
                                                                {t('login.support-title')}
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                    <div
                                                        className={'d-flex flex-column justify-content-center align-items-center'}
                                                        style={{gap: 16}}>
                                                        <Button className="primary-button"
                                                                variant="outlined"
                                                                size="large"
                                                                type="submit"
                                                                style={{
                                                                    'color': themeContext.color.white,
                                                                    'width': '197px'
                                                                }}>
                                                            {
                                                                isLoadingLogin &&
                                                                <img src={spinner} className="spinner-img-inner-button"
                                                                     alt="spinner"/>
                                                            }
                                                            {!isLoadingLogin && t('login.submit')}
                                                        </Button>
                                                        <Button className="transparent-button"
                                                                size="large"
                                                                type="button"
                                                                onClick={linkToRequestCredentials}
                                                                style={{
                                                                    'width': '197px'
                                                                }}>
                                                            {t('login.register-button')}
                                                        </Button>
                                                    </div>
                                                    <Container>
                                                        <Row className="mt-3">
                                                            {
                                                                localStorage.getItem('biometrics') && localStorage.getItem('webauthn') && webauthn &&
                                                                <Link className="login-subtitle align-center"
                                                                      href="#" color="primary"
                                                                      onClick={assertCredentials}>
                                                                    {t('login.webauthn')}
                                                                </Link>
                                                            }
                                                        </Row>
                                                    </Container>
                                                </Form>
                                                {isDesktop ?
                                                    <div style={{margin: '33px 10%'}}>
                                                        <h6 className="footer-text-login-desk">
                                                            <a>{t('login.footer-row-1a')}</a>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login-desk">
                                                            <a href={`https://${t('login.footer-row-1b')}`}
                                                               target='_blank'
                                                               className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-1b')} </a>
                                                            • <a href={`mailto:${t('login.footer-row-2a')}`}
                                                                 className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-2a')}</a> • <a
                                                            href={`mailto:${t('login.footer-row-2b')}`}
                                                            target="_blank"
                                                            className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-2b')}</a> •
                                                            <span> {t('login.footer-row-3a')}<a
                                                                href={`tel:${t('login.footer-row-3b')}`}
                                                                className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-3b')}</a>
                                                            </span>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login-desk">{t('login.footer-row-5')} •<span> {t('login.footer-row-4')}</span>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login-desk font-weight-bold"
                                                            style={{fontStyle: 'italic'}}>
                                                            <a href="https://www.widegroup.eu/contatti/"
                                                               target="_blank"
                                                               className="footer-link-no-decoration">
                                                                {t('login.footer-row-6')}
                                                            </a>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login">{t('login.footer-row-20a')}
                                                            <a href="https://www.widegroup.eu/tutela-del-cliente"
                                                               className="footer-link-no-decoration"
                                                               target="_blank"><span
                                                                className="font-weight-bold">{t('login.footer-row-20b')}</span></a>
                                                        </h6>
                                                        <h6 className="footer-text-login-desk">{t('login.footer-row-21a')}
                                                            <a href="https://ruipubblico.ivass.it/rui-pubblica/ng/#/workspace/registro-unico-intermediari"
                                                               className="footer-link-no-decoration"
                                                               target="_blank"><span
                                                                className="font-weight-bold">{t('login.footer-row-21b')}</span></a>
                                                        </h6>
                                                    </div>
                                                    :
                                                    <div style={{margin: '33px auto'}}>
                                                        <h6 className="footer-text-login-desk">
                                                            <a>{t('login.footer-row-1a')}</a>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login">
                                                            <a href="https://www.widegroup.eu" target="_blank"
                                                               className="footer-link-no-decoration"
                                                            ><span
                                                                className="font-weight-bold">{t('login.footer-row-1b')}</span></a>
                                                        </h6>
                                                        <h6 className="footer-text-login font-weight-bold"><a
                                                            href={`mailto:${t('login.footer-row-2a')}`}
                                                            className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-2a')}</a> • <a
                                                            href={`mailto:${t('login.footer-row-2b')}`}
                                                            target="_blank"
                                                            className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-2b')}</a>
                                                        </h6>
                                                        <h6 className="footer-text-login">{t('login.footer-row-3a')}
                                                            <a
                                                                href={`tel:${t('login.footer-row-3b')}`}
                                                                className="font-weight-bold footer-link-no-decoration">{t('login.footer-row-3b')}</a>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login">{t('login.footer-row-5')}</h6>
                                                        <h6 className="footer-text-login">{t('login.footer-row-4')}</h6>
                                                        <br/>
                                                        <h6 className="footer-text-login-desk font-weight-bold"
                                                            style={{fontStyle: 'italic'}}>
                                                            <a href="https://www.widegroup.eu/contatti/"
                                                               target="_blank"
                                                               className="footer-link-no-decoration">
                                                                {t('login.footer-row-6')}
                                                            </a>
                                                        </h6>
                                                        <br/>
                                                        <h6 className="footer-text-login">{t('login.footer-row-20a')}
                                                            <a href="https://www.widegroup.eu/tutela-del-cliente"
                                                               target="_blank"
                                                               className="footer-link-no-decoration"><span
                                                                className="font-weight-bold">{t('login.footer-row-20b')}</span></a>
                                                        </h6>
                                                        <h6 className="footer-text-login">{t('login.footer-row-21a')}
                                                            <a href="https://ruipubblico.ivass.it/rui-pubblica/ng/#/workspace/registro-unico-intermediari"
                                                               target="_blank"
                                                               className="footer-link-no-decoration"><span
                                                                className="font-weight-bold">{t('login.footer-row-21b')}</span></a>
                                                        </h6>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <DialogSmall open={loginDialog}
                             title={t('login.login-failed-modal-title')}
                             description={
                                 <Fragment>
                                     {
                                         errorType === '401' && t('login.login-failed-modal-description')
                                     }
                                     {
                                         errorType === '500' && t('login.login-failed-modal-description-server-error')
                                     }
                                     {
                                         errorType === 'noUserCf' && t('login.login-failed-modal-description-no-user-cf-error')
                                     }
                                     {
                                         sessionStorage.getItem('spid-not-available') &&
                                         <span>
                                            <br/>{t('login.spid-not-available-error-1')}<br/>{t('login.spid-not-available-error-2')}<br/>{t('login.spid-not-available-error-3')}<MailTo
                                             subject={t('login.email-contact-us-1-subject')}
                                             email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL}
                                             body={`${t('login.email-contact-us-1')}%0D%0A%0D%0A%0D%0A${t('login.email-contact-us-2')}%0D%0A%0D%0A`}>{t('login.spid-not-available-error-4')}</MailTo>
                                         </span>
                                     }
                                     {
                                         sessionStorage.getItem('spid-not-enable') &&
                                         <span>
                                             <br/>{t('login.spid-not-enable-error-1')}<br/>{t('login.spid-not-enable-error-2')}<br/><MailTo
                                             subject={t('login.email-contact-us-2-subject')}
                                             email={process.env.REACT_APP_MY_WIDE_SUPPORT_EMAIL}
                                             body={`${t('login.email-contact-us-2-1')}%0D%0A%0D%0A%0D%0A${t('login.email-contact-us-2-2')}%0D%0A%0D%0A`}>{t('login.spid-not-enable-error-3')}</MailTo>{t('login.spid-not-enable-error-4')}
                                         </span>
                                     }
                                     {
                                         sessionStorage.getItem('spid-bad-request') &&
                                         <span>{badRequestError.message}</span>
                                     }
                                 </Fragment>

                             }
                             handleClose={handleLoginDialog}
                             buttonText={t('login.login-failed-modal-button')}/>
                <Dialog
                    components={{Backdrop: StyledBackdrop}}
                    open={openSupportDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseSupportDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                        style: {
                            'position': 'absolute',
                            'bottom': '0',
                            'left': 'auto',
                            'right': 'auto',
                            'width': '95%',
                            'borderRadius': '10px',
                            'color': themeContext.color.neutral600,
                            'zIndex': '99'
                        },
                    }}>
                        <span className={classes.dialogTitle}>
                        {t('login.support-title')}
                        </span>
                    <Divider/>
                    <DialogActions style={{'textTransform': 'none'}}>
                        <Button onClick={linkToRequestResetPassword}
                                style={{
                                    'color': themeContext.color.main,
                                    'textTransform': 'none'
                                }}
                                fullWidth>
                            {t('login.forgot-password')}
                        </Button>
                    </DialogActions>
                    <Divider/>
                    <DialogActions>
                        <Button onClick={linkToRequestCredentials}
                                style={{
                                    'color': themeContext.color.main,
                                    'textTransform': 'none'
                                }}
                                fullWidth>
                            {t('login.no-credentials')}
                        </Button>
                    </DialogActions>
                    <Divider/>
                    <DialogActions>
                        <Button onClick={() => navigate('/login-otp')}
                                style={{
                                    'color': themeContext.color.main,
                                    'textTransform': 'none'
                                }}
                                fullWidth>
                            {t('login.support-otp')}
                        </Button>
                    </DialogActions>
                    <Divider/>
                    <DialogActions>
                        <Button onClick={() => setOpenNewRequestDialog(true)} style={{
                            'color': themeContext.color.main,
                            'textTransform': 'none'
                        }} fullWidth>
                            {t('login.support-contact-us')}
                        </Button>
                    </DialogActions>
                    <Divider/>
                    <DialogActions>
                        <Button onClick={handleCloseSupportDialog} style={{
                            'color': themeContext.color.neutral400,
                            'textTransform': 'none'
                        }}
                                fullWidth>
                            {t('login.refuse-button')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={() => {
                        setOpenAccessDialog(false)
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={!isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? 'auto' : '100%',
                            marginTop: '10%',
                            background: themeContext.color.linearGradientBgLight,
                        }
                    }}
                    open={openAccessDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogContent className={'no-scrollbar'}>
                        <Container className="dialog-main-container" style={{paddingBottom: "30px"}}>
                            <Row style={{justifyContent: 'end'}}>
                                <img src={exitIcon} className="exit-button position-absolute cursor-pointer" alt="close"
                                     onClick={() => {
                                         setOpenAccessDialog(false)
                                     }} style={{float: 'inline-end'}}/>
                            </Row>
                            <Row className={isRepeat ? 'wide-background-desktop' : 'wide-background-mobile'}
                                 style={isDesktop ? {
                                     maxHeight: '215px',
                                     marginTop: '-20px'
                                 } : {marginTop: '-20px'}}>
                            </Row>
                            <div style={isDesktop ? {marginTop: '-20px'} : {}}>
                                <Row>
                                    <Col>
                                        <h6 className={classes.accessDialogTitle}>
                                            {t('login.dialog-title')}
                                        </h6>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '40px'}}>
                                    <Col>
                                        <h6 style={{fontSize: '14px', textAlign: 'center'}}>
                                            {t('login.dialog-description-1')}
                                        </h6>
                                    </Col>
                                </Row>
                                <Row className={'mt-3'}>
                                    <Col>
                                        <h6 style={isDesktop ? {
                                            padding: '0 20% 0 20%',
                                            fontSize: '14px',
                                            textAlign: 'center'
                                        } : {fontSize: '14px', textAlign: 'center'}}>
                                            {t('login.dialog-description-2')}
                                        </h6>
                                    </Col>
                                </Row>
                                <Row className={'mt-3'} style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button-no-padding"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none',
                                                    fontSize: '16px',
                                                }}
                                                onClick={() => {
                                                    navigate('/login-otp')
                                                }}>
                                            {t('login.dialog-phone-button')}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className={'mt-3'}>
                                    <Col>
                                        <h6 style={{fontSize: '14px', textAlign: 'center'}}>
                                            {t('login.dialog-description-3')}
                                        </h6>
                                    </Col>
                                </Row>
                                <Row className={'mt-2'} style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="outline-button"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                onClick={linkToRequestResetPassword}>
                                            {t('login.dialog-reset-password-button')}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className={'mt-3'} style={{textAlign: 'center'}}>
                                    <Col>
                                        <Link
                                            className="link-custom cursor-pointer"
                                            color="primary" target="_new"
                                            onClick={linkToRequestCredentials} style={{
                                            'color': themeContext.color.main,
                                        }}>
                                            {t('login.dialog-link')}
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className={'mt-3'} style={{textAlign: 'center'}}>
                                    <Col>
                                        <h6 style={{fontSize: '14px', textAlign: 'center'}}>
                                            {t('login.dialog-description-4')}
                                        </h6>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openLoggedUserSelectionDialog}
                    TransitionComponent={Transition}
                    onClose={() => {
                        setOpenConfirmExitDialog(true)
                    }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                                setOpenLoggedUserSelectionDialog(false)
                            }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className={'pr-5'}>
                                <h6 className="dialog-form-title-1 text-left">{t('login.dialog-logged-user-selection.title')}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 text-left">{t('login.dialog-logged-user-selection.description')}</h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar" style={{marginTop: '50px'}}>
                        <Container className="dialog-main-container"
                                   style={{paddingBottom: "96px"}}>
                            <div>
                                {usersList.data !== null && usersList.data.length > 1 && usersList.data.map((user, index) => (
                                    <Row key={index}
                                         className={selectedPerson === user.email ? 'selected-user' : 'not-selected-user'}
                                         onClick={() => {
                                             setSelectedPerson(user.email);
                                             setSelectedFiscalCode(user.fiscalCode);
                                             setUserNotSelectedDialog(false);
                                             setLoginWithUserError(false);
                                         }}
                                    >
                                        <Col>
                                        <span className="d-flex justify-content-center black"
                                              style={{fontSize: '17px'}}>{user.email}</span>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button boxShadow"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                                disabled={false}
                                                onClick={() => selectedPerson !== null ? (usersList.type === 'spid' ? loginUserSelectedAfterSPID(selectedPerson, selectedFiscalCode) : loginUserSelectedAfterCIE(selectedPerson, selectedFiscalCode)) : setUserNotSelectedDialog(true)}>
                                            {isLoadingLogin &&
                                                <img src={spinner} className="spinner-img-inner-button" alt="spinner"/>
                                            }
                                            {!isLoadingLogin && t('loginOtpVerify.dialog-button')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction open={openConfirmExitDialog}
                                    title={t('login.dialog-confirm-exit-title')}
                                    description={t('login.dialog-confirm-exit-description')}
                                    handleClose={() => setOpenConfirmExitDialog(false)}
                                    handleNo={() => setOpenConfirmExitDialog(false)}
                                    handleYes={() => {
                                        setOpenConfirmExitDialog(false)
                                        setOpenLoggedUserSelectionDialog(false)
                                    }}
                                    deleteAction={true}
                                    buttonYesText={t('login.dialog-confirm-exit-confirm')}
                                    buttonNoText={t('login.dialog-confirm-exit-refuse')}/>
                <Dialog
                    onClose={() => {
                        if (isFormTouched) {
                            setOpenDoNotSaveRequestDialog(true)
                        } else {
                            setOpenNewRequestDialog(false)
                        }
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openNewRequestDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title"
                                 className="dialog-title dialog-title-div"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close"
                                 onClick={() => {
                                     if (isFormTouched) {
                                         setOpenDoNotSaveRequestDialog(true)
                                     } else {
                                         setOpenNewRequestDialog(false)
                                     }
                                 }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left">{t(`settings.list-support`)}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col">
                                <h6 className="dialog-form-title-2 float-left">{t(`support.support-description`)}</h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <Container className="dialog-main-container px-0 pt-2"
                                   style={{paddingBottom: "96px"}}>
                            {
                                isLoadingForm &&
                                <Row style={{'height': '200px'}}
                                     className="background-loading-spinner">
                                </Row>
                            }
                            {
                                !isLoadingForm &&
                                <Form onSubmit={submitForm} style={{marginBottom: '60px'}}>
                                    <div className={"p-0 position-relative d-flex flex-column"}
                                         style={{gap: 16, marginBottom: '60px'}}>
                                        {(step === 1 || step === 2) &&
                                            <div className={"p-0 position-relative d-flex flex-column"}
                                                 style={{gap: 12}}>
                                                <span className="dialog-parameter text-capitalize">
                                                    {t(`login.email`)}
                                                </span>
                                                <TextField
                                                    error={!!errors.email}
                                                    helperText={errors.email}
                                                    onChange={handleTicketInputChange}
                                                    id={`email`}
                                                    name={`email`}
                                                    type={'email'}
                                                    disabled={step === 2 || step === 3}
                                                    fullWidth
                                                    placeholder=""
                                                    variant="outlined"
                                                    value={ticketFormValues.email}
                                                    color="primary"
                                                    InputProps={{
                                                        inputProps: {min: 0},
                                                        style: {
                                                            'borderRadius': '8px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                        {step === 2 &&
                                            <Fragment>
                                                <div className={"p-0 position-relative d-flex flex-column"}
                                                     style={{gap: 12}}>
                                                    <span className="dialog-parameter text-capitalize">
                                                         {t(`sendOtp.otp-code`)}
                                                    </span>
                                                    <TextField
                                                        error={!!errors.otp}
                                                        helperText={errors.otp}
                                                        onChange={handleTicketInputChange}
                                                        id={`otp`}
                                                        name={`otp`}
                                                        type={'number'}
                                                        fullWidth
                                                        placeholder=""
                                                        variant="outlined"
                                                        value={ticketFormValues.otp}
                                                        color="primary"
                                                        InputProps={{
                                                            inputProps: {min: 0},
                                                            style: {
                                                                'borderRadius': '8px',
                                                                'color': themeContext.color.neutral600
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <span className="dialog-parameter text-center">
                                                    {t(`${root}.otp-not-received`)}
                                                    <br/><br/>
                                                    <span onClick={() => {
                                                        if (!disabled) {
                                                            resendOtp();
                                                        }
                                                    }} className={disabled ? 'gray' : 'blue'}
                                                          style={{
                                                              textDecoration: 'underline',
                                                              fontWeight: 'bold',
                                                          }}>{disabled ? t(`sendOtp.resend-disabled-1`) + seconds + t(`sendOtp.resend-disabled-2`)
                                                        : t(`sendOtp.resend`)}</span>
                                                    <br/>
                                                    <span>{t(`${root}.or`)}</span>
                                                    <br/>
                                                    <span onClick={() => {
                                                        setStep(current => current - 1)
                                                        setErrors({})
                                                        setTicketFormValues(ticketFormValues)
                                                    }} style={{
                                                        color: themeContext.color.main,
                                                        textDecoration: 'underline',
                                                        fontWeight: 'bold',
                                                    }}>
                                                        {t(`${root}.change-email`)}
                                                    </span>
                                                </span>
                                            </Fragment>
                                        }
                                        {step === 3 &&
                                            <Fragment>
                                                <div className={"p-0 position-relative d-flex flex-column"}
                                                     style={{gap: 12}}>
                                                    <span className="dialog-parameter text-capitalize">
                                                        {t(`${root}.title-input`)}
                                                    </span>
                                                    <div className={"p-0 position-relative d-flex flex-column"}
                                                         style={{gap: 4}}>
                                                        <div>
                                                            <TextField
                                                                error={!!errors.title}
                                                                helperText={errors.title}
                                                                onChange={handleTicketInputChange}
                                                                id={`title`}
                                                                name={`title`}
                                                                value={ticketFormValues.title}
                                                                fullWidth
                                                                placeholder=""
                                                                variant="outlined"
                                                                color="primary"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: titleMinLength,
                                                                        maxLength: titleMaxLength
                                                                    },
                                                                    style: {
                                                                        'borderRadius': '8px',
                                                                        'color': themeContext.color.neutral600
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={'d-flex justify-content-end'}>
                                                    <span>
                                                        {ticketFormValues.title.length} / {titleMaxLength}
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"p-0 position-relative d-flex flex-column"}
                                                     style={{gap: 12}}>
                                            <span className="dialog-parameter text-capitalize">
                                                    {t(`${root}.description-input`)}
                                            </span>
                                                    <TextField
                                                        error={!!errors.description}
                                                        helperText={errors.description}
                                                        multiline
                                                        minRows={5}
                                                        onChange={handleTicketInputChange}
                                                        id={`description`}
                                                        name={`description`}
                                                        autoComplete={'off'}
                                                        fullWidth
                                                        placeholder=""
                                                        variant="outlined"
                                                        value={ticketFormValues.description}
                                                        color="primary"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: descriptionMinLength,
                                                                max: descriptionMaxLength
                                                            },
                                                            style: {
                                                                'borderRadius': '8px',
                                                                'color': themeContext.color.neutral600
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Button name="attach"
                                                        disabled={isFormInvalid}
                                                        type={"button"}
                                                        onClick={handleAttachImages}
                                                        style={{height: 40}}
                                                        className={isFormInvalid ? "btn-custom-disable-2 cursor-pointer text-transform-none" : "btn-primary-custom cursor-pointer text-transform-none"}>
                                                    {
                                                        isLoadingAttach &&
                                                        <img src={spinner} className="spinner-img"
                                                             alt="spinner"/>
                                                    }
                                                    {
                                                        !isLoadingAttach && t(`${root}.attach-images`)
                                                    }
                                                </Button>
                                                <div className={'d-flex flex-row'} style={{gap: 24}}>
                                                    {
                                                        isDesktop && imagesUrl.map((item, index) => (
                                                            <div key={index}>
                                                                {
                                                                    index <= 2 &&
                                                                    <Fragment>
                                                                        <div className="div-grid-thumb">
                                                                            <img className={'image-grid'} src={item}
                                                                                 onClick={handleAttachImages}/>
                                                                        </div>
                                                                    </Fragment>
                                                                }
                                                                {
                                                                    index === 3 &&
                                                                    <Fragment>
                                                                        <div className="div-grid-thumb">
                                                                            <img className={'image-grid'}
                                                                                 style={{opacity: '0.5'}} src={item}
                                                                                 onClick={handleAttachImages}/>
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                fontWeight: '600',
                                                                                fontSize: '32px',
                                                                                color: themeContext.color.neutral600
                                                                            }}
                                                                                 onClick={handleAttachImages}>
                                                                                +{imagesUrl.length - 3}</div>
                                                                        </div>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        !isDesktop && imagesUrl.map((item, index) => (
                                                            <div key={index}>
                                                                {
                                                                    index <= 1 &&
                                                                    <Fragment>
                                                                        <div className="div-grid-thumb">
                                                                            <img className={'image-grid'} src={item}
                                                                                 onClick={handleAttachImages}/>
                                                                        </div>
                                                                    </Fragment>
                                                                }
                                                                {
                                                                    index === 2 &&
                                                                    <Fragment>
                                                                        <div className="div-grid-thumb">
                                                                            <img className={'image-grid'}
                                                                                 style={{opacity: '0.5'}} src={item}
                                                                                 onClick={handleAttachImages}/>
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                fontWeight: '600',
                                                                                fontSize: '32px',
                                                                                color: themeContext.color.neutral600
                                                                            }}
                                                                                 onClick={handleAttachImages}>
                                                                                +{imagesUrl.length - 2}</div>
                                                                        </div>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <span className="dialog-parameter text-center">
                                                    {t(`${root}.attach-images-info`)}
                                                </span>
                                            </Fragment>
                                        }
                                    </div>
                                    <Container className="position-absolute linear-gradient-white"
                                               style={
                                                   isDesktop ? {
                                                       opacity: '1',
                                                       left: 0,
                                                       bottom: 0,
                                                       right: 0,
                                                       paddingBottom: 32,
                                                       display: "flex",
                                                       flexDirection: "column",
                                                       gap: 24
                                                   } : {
                                                       opacity: '1',
                                                       left: 0,
                                                       bottom: 0,
                                                       paddingBottom: 68,
                                                       right: 0,
                                                       display: "flex",
                                                       flexDirection: "column",
                                                       gap: 24
                                                   }
                                               }>
                                        <Row style={{textAlign: 'center'}}>
                                            <Col>
                                                <Button
                                                    className={"close-button"}
                                                    variant="outlined"
                                                    size="large"
                                                    style={{
                                                        color: themeContext.color.white,
                                                        width: '288px',
                                                        textTransform: 'none'
                                                    }}
                                                    type="submit"
                                                >
                                                    {isLoadingForm &&
                                                        <img src={spinner} className="spinner-img" alt="spinner"/>}
                                                    {!isLoadingForm && step === 1 && t(`${root}.step1-button`)}
                                                    {!isLoadingForm && step === 2 && t(`${root}.step2-button`)}
                                                    {!isLoadingForm && step === 3 && t(`${root}.send-request`)}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            }
                        </Container>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={() => {
                        if (new_ids.current.length > 0) {
                            setOpenDoNotSaveDialog(true)
                        } else {
                            setOpenUploadDialog(false)
                            setUploadError(null)
                        }
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '89%' : '100%',
                            marginTop: isDesktop ? '11%' : '20%'
                        }
                    }}
                    open={openUploadDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close"
                                 onClick={() => {
                                     if (new_ids.current.length > 0) {
                                         setOpenDoNotSaveDialog(true)
                                     } else {
                                         setOpenUploadDialog(false)
                                         setUploadError(null)
                                     }
                                 }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left text-left">{t('attachImagesToClaimForm.title')}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {t('carClaimForm.upload-description')}
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {t('support.attachments-max-size')}
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {imagesUrl.length} / {imagesMaxLength} {t('ticketDetail.attachments')}
                                </h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container" style={{paddingBottom: '120px'}}>
                            <div style={{display: "flex", gap: "24px"}}
                                 className={isDesktop ? 'flex-wrap justify-content-center mt-4' : 'flex-wrap justify-content-between mt-4'}>
                                <div className={imagesUrl.length < 4 ? 'add-active-icon add-icon' : 'add-icon'}>
                                    <img key={0} src={addIcon}  width={'100%'} height={'100%'}
                                         alt={`image-0`}
                                         onClick={() => {
                                             attachImageActions(documents.type, imagesUrl.length)
                                         }}
                                    />
                                    <img hidden className="trash-button" src={trashIcon} alt="trash-icon"/>
                                    <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                           accept="image/*"/>
                                </div>
                                {
                                    images.map((item, index) => (
                                        <div key={index} className={'div-grid'}>
                                            <img src={item.url} alt={`image-${index}`} className={'image-grid'}
                                                 onClick={() => {
                                                     openLightboxOnSlide(index);
                                                 }}
                                            />
                                            <img className="trash-button" src={trashIcon} alt="trash-icon"
                                                 onClick={() => handleDeleteImageDialog(item)}/>
                                        </div>
                                    ))
                                }
                                <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={imagesUrl}
                                    type="image"
                                    sourceIndex={lightboxController.slide}
                                    key={imagesUrl.length}
                                />
                                {
                                    uploadError &&
                                    <Row className="ml-4 mr-4 mt-4">
                                        <Col className="mt-4 text-center dialog-form-title-3 red">
                                            <span dangerouslySetInnerHTML={{__html: uploadError}}/>
                                        </Col>
                                    </Row>
                                }
                            </div>
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button boxShadow"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                                disabled={false}
                                                onClick={() => {
                                                    new_ids.current = []
                                                    setOpenUploadDialog(false)
                                                }}
                                        >{t('regulations.save-pdf-button')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => {
                                if(data) {
                                    setCropper(data)
                                }
                            }}/>
                <DialogDoubleAction open={openDoNotSaveRequestDialog}
                                    title={t('genericClaimForm.do-not-save-title')}
                                    description={t('genericClaimForm.do-not-save-description')}
                                    handleClose={() => setOpenDoNotSaveRequestDialog(false)}
                                    handleNo={() => setOpenDoNotSaveRequestDialog(false)}
                                    deleteAction={true}
                                    handleYes={() => doNotSaveRequest(activeDialog)}
                                    buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                    buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
                <DialogDoubleAction open={openDoNotSaveDialog}
                                    title={t('genericClaimForm.do-not-save-title')}
                                    description={t('genericClaimForm.do-not-save-description')}
                                    handleClose={() => setOpenDoNotSaveDialog(false)}
                                    handleNo={() => setOpenDoNotSaveDialog(false)}
                                    deleteAction={true}
                                    handleYes={() => doNotSave(activeDialog)}
                                    buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                    buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
                <DialogDoubleAction open={openDeleteImageDialog}
                                    title={t('genericClaimForm.irreversible-action-title')}
                                    description={t('genericClaimForm.irreversible-action-description')}
                                    handleClose={() => setOpenDeleteImageDialog(false)}
                                    handleNo={() => setOpenDeleteImageDialog(false)}
                                    deleteAction={true}
                                    handleYes={() => handleDeleteImage(imageToDelete)}
                                    buttonYesText={t('genericClaimForm.irreversible-action-done')}
                                    buttonNoText={t('genericClaimForm.irreversible-action-undone')}/>
                <DialogOperationComplete open={openSaveCompletedDialog}
                                         title={t('changeIBAN.confirm-operation')}/>
            </div>
    )
}
