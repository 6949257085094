import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import documentPlaceholder from '../../images/document-photo-placeholder.svg';
import exit from "../../images/exit-icon.svg";
import PlaceholderScreen from "../components/PlaceholderScreen.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import themeContext from "../components/ThemeContext.js";


export default function InvitationScreen() {
    const root = 'changeIBAN';
    //const root = 'invitationScreen';
    const navigate = useNavigate();
    const params = useParams();
    const personId = params.personId;
    const {t} = useTranslation();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [openExitDialog, setOpenExitDialog] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {

        }
    }, []);

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const navigateToPersonalDocuments = () => {
        return navigate(`/settings/people/${personId}/invitation/personalDocuments`);
    }

    return (
        <Fragment>
            {
                serviceError &&
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className="col-2 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{t('personalDocuments.title')}</h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container style={{'paddingTop': '25%'}}></Container>
                    <Row>
                        {
                            <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                                 alt="service-error-image"></img>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                        </Col>
                    </Row>
                    <Row style={{'marginTop': '5%'}}>
                        <Col>
                            <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                        </Col>
                    </Row>
                </Container>
            }
            {
                !serviceError &&
                <Container className="mt-0 full-height linear-gradient">
                    <div style={{'opacity': '1'}}>
                        <Row className="nav-top container border-bottom"
                             style={{
                                 'backgroundColor': themeContext.color.white,
                                 'paddingTop': '19.5px',
                                 'paddingBottom': '20px',
                                 'paddingLeft': '0',
                                 'paddingRight': '0',
                             }}>
                            <Col className="col-1 float-left">
                                <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-10">
                                <h6 className="header-title ellipsis-1-v2">{t('uploadDocumentToVerify.title')}</h6>
                            </Col>
                            <Col className="col-1">
                                <img src={exit} onClick={() => setOpenExitDialog(true)}
                                     className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                            </Col>
                        </Row>
                        <Container className="top-of-the-preferences"></Container>
                        <PlaceholderScreen isLoadingData={isLoadingData} title={t('uploadDocumentToVerify.subtitle')}
                                           description={t(`${root}.description`)}
                                           placeholder={documentPlaceholder}
                                           buttonText={t('uploadDocumentToVerify.button')}
                                           buttonAction={navigateToPersonalDocuments}/>
                    </div>
                </Container>
            }
            <DialogDoubleAction
                open={openExitDialog}
                handleClose={() => setOpenExitDialog(false)}
                title={t('dialog.interruption-title')}
                description={t('dialog.interruption-description')}
                buttonYesText={t('dialog.button-yes')}
                handleYes={() => {
                    navigate(`/settings/people/${personId}`);
                }}
                buttonNoText={t('dialog.button-no')}
                handleNo={() => setOpenExitDialog(false)}
            ></DialogDoubleAction>
        </Fragment>
    )
}