import React, {Fragment, useEffect, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Button, Checkbox, Menu, MenuItem, MenuList, Radio} from "@mui/material";
import {fetchToken} from "../middleware/Firebase.js";
import FileSaver from "file-saver";
import {
    base64url_decode,
    base64url_encode,
    deleteElementFromIndexedDB,
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import UserImg from '../../images/profile-icon.svg'
import baseFemaleAvatar from '../../images/female-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Moment from "react-moment";
import baseMaleAvatar from "../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import searchIcon from "../../images/search-icon.svg";
import exitIcon from "../../images/exit-icon.svg";
import exportIcon from "../../images/export.svg";
import nothing from "../../images/nothing-icon.svg";
import car from "../../images/car-blue.svg";
import vectorIcon from "../../images/policy_branches/vector.svg";
import artCollectionIcon from "../../images/policy_branches/art-collections-icon.svg";
import autoCvtIcon from "../../images/policy_branches/auto-cvt-icon.svg";
import aviationIcon from "../../images/policy_branches/aviation-icon.svg";
import buildingsIcon from "../../images/policy_branches/buildings-icon.svg";
import cablewayIcon from "../../images/policy_branches/cableway-icon.svg";
import certifiedTransportationIcon from "../../images/policy_branches/certified-transportation-icon.svg";
import civiliansIcon from "../../images/policy_branches/civilians-icon.svg";
import collectiveLivesIcon from "../../images/policy_branches/collective-lives-icon.svg";
import companiesIcon from "../../images/policy_branches/companies-icon.svg";
import depositsIcon from "../../images/policy_branches/deposits-icon.svg";
import electronicIcon from "../../images/policy_branches/electronic-icon.svg";
import energyIcon from "../../images/policy_branches/energy-icon.svg";
import farmingIcon from "../../images/policy_branches/farming-icon.svg";
import horecaIcon from "../../images/policy_branches/horeca-icon.svg";
import illnessIcon from "../../images/policy_branches/illness-icon.svg";
import individualLivesIcon from "../../images/policy_branches/individual-lives-icon.svg";
import injuresIcon from "../../images/policy_branches/injures-icon.svg";
import jewellersIcon from "../../images/policy_branches/jewellers-icon.svg";
import legalProtectionIcon from "../../images/policy_branches/legal-protection-icon.svg";
import officeLaboratoriesIcon from "../../images/policy_branches/office-laboratories-icon.svg";
import petInsuranceIcon from "../../images/policy_branches/pet-insurance-icon.svg";
import rcAdminIcon from "../../images/policy_branches/rc-admin-icon.svg";
import rcClinicalTrialsIcon from "../../images/policy_branches/rc-clinical-trials-icon.svg";
import rcIndividuals from "../../images/policy_branches/rc-individuals-icon.svg";
import rcMedicalMalpracticeIcon from "../../images/policy_branches/rc-medical-malpractice-icon.svg";
import rcPollutionIcon from "../../images/policy_branches/rc-pollution-icon.svg";
import rcProfessionalIcon from "../../images/policy_branches/rc-professional-icon.svg";
import shipIcon from "../../images/policy_branches/ship-icon.svg";
import technologiesIcon from "../../images/policy_branches/technologies-icon.svg";
import theftIcon from "../../images/policy_branches/theft-icon.svg";
import thirdSectorIcon from "../../images/policy_branches/third-sector-icon.svg";
import tourOperatorIcon from "../../images/policy_branches/tour-operator-icon.svg";
import tradeCreditsIcon from "../../images/policy_branches/trade-credits-icon.svg";
import travelBusinnessLineIcon from "../../images/policy_branches/travel-businnes-line-icon.svg";
import wheaterIcon from "../../images/policy_branches/wheater-icon.svg";
import activeFromIcon from "../../images/active-from-icon.svg";
import cardPlaceholder_min from "../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../images/card-placeholder_full.svg";
import scrollbarUsersPlaceholder from "../../images/user-placeholder.svg";
import useAnalyticsEventTracker from "../middleware/useAnalyticsEventTracker.js";
import {useMediaQuery} from 'react-responsive'
import Checked from "../../Checked.js";
import Unchecked from "../../Unchecked.js";
import spinner from "../../images/spinner.svg";
import {publish} from "../service/EventService.js";
import Header from "../components/Header.js";
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";
import {Title, CardTextMid, CardTextCenter} from "../styles/Styles.js";

const useStyles = {
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
    cardTextCenter: {
        color: `${themeContext.color.neutral400} !important`,
        textAlign: 'center !important',
        lineHeight: '0.6 !important',
        fontSize: '14px !important',
        paddingBottom: '0 !important',
        marginBottom: '0 !important'
    },
    cardAvatar: {
        float: "right !important",
        width: "35px !important",
        height: "35px !important",
    },
    avatar: {
        width: '56px !important',
        height: '56px !important',
        margin: '4px 7px 4px 7px !important',
    },
    bigAvatar: {
        width: '66px !important',
        height: '66px !important',
        margin: '0 0 0 5px !important',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow} !important`,
    },
    renewButton: {
        fontWeight: '600 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        letterSpacing: '2.07px !important',
        color: `${themeContext.color.mainActive} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important'
    },
    revisionButton: {
        fontWeight: '600 !important',
        fontSize: '12px !important',
        lineHeight: '16px !important',
        letterSpacing: '2.07px !important',
        color: `${themeContext.color.yellow} !important`,
        textTransform: 'uppercase !important',
        float: 'left !important'
    }
}

export default function Dashboard() {
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const exportFileUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/export/policies`;
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const personalPoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/personal`;
    const peoplePoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/people`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const webAuthNRegistrationUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/registration`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;
    const getPeopleVehiclesUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleVehicles`;
    const getWhitelistPoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/whitelistPolicies`;
    const getDocumentsForPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/documentForPrivacy`;
    const setPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/setPrivacy`;
    const getPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/getPrivacy`;
    const updateUserVersionUrl = `${process.env.REACT_APP_BASE_URL}/management/operation/updateUserVersion`;
    const token = sessionStorage.getItem('token');
    const [isLoadingPersonalData, setIsLoadingPersonalData] = useState(false);
    const [isLoadingPeopleData, setIsLoadingPeopleData] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [people, setPeople] = useState([]);
    const gaEventTrackerPolicyDetail = useAnalyticsEventTracker('PolicyDetail')
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [noShow, setNoShow] = useState(false);
    const {t, i18n} = useTranslation();

    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    });
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    });
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    });
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    });
    const [checked, setChecked] = useState([]);
    const [exportType, setExportType] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(true);
    const CTA_CATEGORIES = {
        REGULATIONS: 'regulations',
        SIGNING: 'signing',
        PAYMENTS: 'payments',
        FUTURE: 'future',
        PENDING: 'pending',
    }

    useEffect(() => {
        sessionStorage.setItem('root', '/dashboard');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            if (!sessionStorage.getItem('privacy_policy_dialog')) {
                getDocumentsForPrivacy();
            }
            getInfo();
            handleRinging();
            if (!searchMode) {
                getPeople();
                getWhitelistPolicies();
            }
            updateUserVersion();
        } else {
            getInfoOffline();
            getPeopleOffline();
            getPersonalPoliciesOffline();
            getPeoplePoliciesOffline();
        }
        if (!localStorage.getItem('firstLogin') && localStorage.getItem('biometrics')) {
            getVehicles();
            setOpenDialog(true);
        }
        localStorage.setItem('firstLogin', true);
        if (searchMode) {
            const delayDebounceFn = setTimeout(() => {
                searchPeople(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchValue]);

    const [user, setUser] = useState("");
    const client = new ClientJS();
    const [openDialog, setOpenDialog] = useState(false);
    const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [bellRinging, setBellRinging] = useState(false);
    const [personalPolicies, setPersonalPolicies] = useState([]);
    const [peoplePolicies, setPeoplePolicies] = useState([]);
    const selectedAvatar = useRef("null");
    const [searchMode, setSearchMode] = useState(false);
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [getPermission, setGetPermission] = useState(false);
    const [isNothingPersonal, setIsNothingPersonal] = useState(false);
    const [isNothingPeople, setIsNothingPeople] = useState(false);
    const filter = useRef('STATUS_DEFAULT');
    const [openMenu, setOpenMenu] = useState(null);
    const [openMenuExport, setOpenMenuExport] = useState(null);
    const [selectedRadioCsv, setSelectedRadioCsv] = useState('');
    const [selectedRadioXlsx, setSelectedRadioXlsx] = useState('');
    const [selectedRadioPdf, setSelectedRadioPdf] = useState('');
    const [serviceError, setServiceError] = useState(false);
    const [acceptedSigningCompanies, setAcceptedSigningCompanies] = useState([])
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [privacyPolicyDocument, setPrivacyPolicyDocument] = useState([])


    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((isMac() && client.getOSVersion() < "13")) || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || (isMac() && client.getOSVersion() < "13") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone())) {
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    const handleCheck = (event) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
            setIsCheckAll(false)
        }
        setChecked(updatedList);
    };

    const checkAll = () => {
        setIsCheckAll(!isCheckAll);
        setChecked(people.map(li => li.id));
        if (isCheckAll) {
            setChecked([]);
        }
    };

    const handleBackCheck = () => {
        setExportType(false);
        setSelectedRadioCsv('')
        setSelectedRadioXlsx('')
        setSelectedRadioPdf('')
        setChecked([]);
        setIsCheckAll(false);
    };

    function handleRadioCsv(event) {
        setSelectedRadioCsv(event.target.value)
        setSelectedRadioXlsx('')
        setSelectedRadioPdf('')
    }

    function handleRadioXlsx(event) {
        setSelectedRadioXlsx(event.target.value)
        setSelectedRadioCsv('')
        setSelectedRadioPdf('')
    }

    function handleRadioPdf(event) {
        setSelectedRadioPdf(event.target.value)
        setSelectedRadioCsv('')
        setSelectedRadioXlsx('')

    }

    function exportFile() {
        setIsLoading(true);
        let format = '';
        if (selectedRadioCsv !== '') {
            format = 'csv';
        }
        if (selectedRadioXlsx !== '') {
            format = 'xlsx';
        }
        if (selectedRadioPdf !== '') {
            format = 'pdf';
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                personIds: checked,
                policyFilter: filter.current,
                format: format
            })
        };
        fetch(exportFileUrl, requestOptions)
            .then((response) => {
                return response.json();
            }).then((data) => {
            if (data.filename.includes('.pdf')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/pdf"), data.filename);
                setIsLoading(false);
            }
            if (data.filename.includes('.csv')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "text/csv;charset=utf-8"), data.filename);
                setIsLoading(false);
            }
            if (data.filename.includes('.xlsx')) {
                FileSaver.saveAs(b64toFile(data.base64, data.filename, "application/octet-stream"), data.filename);
                setIsLoading(false);
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    function b64toFile(b64Data, filename, contentType) {
        var sliceSize = 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);

            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var file = new File(byteArrays, filename, {type: contentType});
        return file;
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            if (data.status !== -1) {
                setUser(data)
                localStorage.setItem('wideAPP_userId', data.id)
                putElementFromIndexedDB('profileInfo', data)
                if (!!data.pinCode) {
                    putElementFromIndexedDB('pin', data.pinCode)
                    localStorage.setItem('pinIsActive', true)
                } else {
                    localStorage.removeItem('pinIsActive')
                    deleteElementFromIndexedDB('pin')
                }
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getInfoOffline() {
        getElementFromIndexedDB('profileInfo').then((response) => {
            return response.value;
        }).then((data) => {
            setUser([])
            setUser(data)
        });
    }

    function getPeople() {
        setIsLoadingPeople(true)
        fetch(getPeopleUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeople([])
            data.map((item) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalPolicies.includes(item)) {
                        setPeople(current => [...current, item])
                        setChecked(current => [...current, item.id])
                    }
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            putElementFromIndexedDB('people', data)
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeopleOffline() {
        getElementFromIndexedDB('people').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
            } else {
                sessionStorage.removeItem('noPeople');
            }
            setPeople([])
            for (let i = 0; i < length; i++) {
                if (!personalPolicies.includes(data[i])) {
                    setPeople(current => [...current, data[i]])
                }
            }
        });
    }

    function getPersonalPolicies() {
        let url;
        if (filter.current !== null) {
            url = `${personalPoliciesUrl}?filter=${filter.current}`;
        } else {
            url = `${personalPoliciesUrl}`;
        }
        setIsLoadingPersonalData(true)
        fetch(url, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPersonalPolicies([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!personalPolicies.includes(item)) {
                        setPersonalPolicies(current => [...current, item])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPersonal(true)
            } else {
                setIsNothingPersonal(false)
            }
            setIsLoadingPersonalData(false)
            putElementFromIndexedDB('personalPolicies', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPersonalPoliciesOffline() {
        setIsLoadingPersonalData(true)
        getElementFromIndexedDB('personalPolicies').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setIsNothingPersonal(true)
            } else {
                setIsNothingPersonal(false)
            }
            setPersonalPolicies([])
            for (let i = 0; i < length; i++) {
                if (!personalPolicies.includes(data[i])) {
                    setPersonalPolicies(current => [...current, data[i]])
                }
            }
            setIsLoadingPeopleData(false)
        });
    }

    function getPeoplePolicies() {
        let url;
        if (filter.current !== null) {
            url = `${peoplePoliciesUrl}?filter=${filter.current}`;
        } else {
            url = `${peoplePoliciesUrl}`;
        }
        setIsLoadingPeopleData(true)
        fetch(url, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeoplePolicies([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peoplePolicies.includes(item)) {
                        setPeoplePolicies(current => [...current, formattedPolicy(item)])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeopleData(false)
            putElementFromIndexedDB('peoplePolicies', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeoplePoliciesOffline() {
        setIsLoadingPeopleData(true)
        getElementFromIndexedDB('peoplePolicies').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            setPeoplePolicies([])
            for (let i = 0; i < length; i++) {
                if (!peoplePolicies.includes(data[i])) {
                    setPeoplePolicies(current => [...current, formattedPolicy(data[i])])
                }
            }
            setIsLoadingPeopleData(false);
        });
    }

    function getPersonPolicies(id) {
        let url;
        if (filter.current !== null) {
            url = `${peoplePoliciesUrl}?personId=${id}&filter=${filter.current}`;
        } else {
            url = `${peoplePoliciesUrl}?personId=${id}`;
        }
        setIsLoadingPeopleData(true)
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            setPeoplePolicies([])
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                    if (!peoplePolicies.includes(item)) {
                        setPeoplePolicies(current => [...current, formattedPolicy(item)])
                    }
                }
            })
            if (count === 0) {
                setIsNothingPeople(true)
            } else {
                setIsNothingPeople(false)
            }
            setIsLoadingPeopleData(false)
            putElementFromIndexedDB('peoplePolicies', data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function registerCredentials() {
        let username = localStorage.getItem('username-wide');
        fetch(`${webAuthNRegistrationUrl}/start`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (!response.ok) {
                return;
            }
            response.json().then(
                (registrationResponse) => {
                    let keyData = registrationResponse.publicKeyCredentialCreationOptions;
                    let excludeCredentials = keyData.excludeCredentials
                        .map(c => {
                            c.id = base64url_decode(c.id);
                            c.transports = undefined;
                            return c;
                        });
                    let cred = navigator.credentials.create({
                        publicKey: {
                            rp: keyData.rp,
                            user: {
                                id: base64url_decode(keyData.user.id),
                                name: keyData.user.name,
                                displayName: keyData.user.displayName
                            },
                            challenge: base64url_decode(keyData.challenge),
                            pubKeyCredParams: keyData.pubKeyCredParams,
                            excludeCredentials: excludeCredentials,
                            timeout: 60000,
                            authenticatorSelection: {
                                userVerification: 'required',
                                authenticatorAttachment: 'platform',
                            },
                        },
                    }).catch((e) => {
                        if (e.name === 'InvalidStateError') {
                            localStorage.setItem('webauthn', true);
                        }
                    });
                    cred.then((credData) => {
                        if (!credData) {
                            return;
                        }
                        let finishRequest = {
                            credential: {
                                clientExtensionResults: {},
                                id: credData.id,
                                rawId: base64url_encode(credData.rawId),
                                response: {
                                    attestationObject: base64url_encode(credData.response.attestationObject),
                                    clientDataJSON: base64url_encode(credData.response.clientDataJSON)
                                },
                                type: "public-key"
                            },
                            registrationId: registrationResponse.registrationId,
                            userAgent: navigator.userAgent
                        };
                        fetch(`${webAuthNRegistrationUrl}/finish`, {
                            method: 'POST',
                            body: JSON.stringify(finishRequest),
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        }).then((finishResponse) => {
                            finishResponse.json().then((r) => console.log("finish registration:", r));
                            localStorage.setItem('webauthn', credData.id);
                        })
                    }).catch(() => {
                        setServiceError(true)
                    });
                }
            );
        });
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleBiometrics = () => {
        setOpenDialog(false);
        registerCredentials();
    };

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getDocumentsForPrivacy = () => {
        fetch(getDocumentsForPrivacyUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPrivacyPolicyDocument(data[0]);
            getPrivacy();
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getPrivacy = () => {
        fetch(getPrivacyUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data) {
                setCheckbox1(data.datiParticolari)
                setCheckbox2(data.marketingDiretto)
                setCheckbox3(data.profilazione)
                if (!data.noShow) {
                    setOpenPolicyDialog(true)
                    sessionStorage.setItem('privacy_policy_dialog', true)
                }
                setIsLoadingData(false)
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    function setPrivacy(checkbox1, checkbox2, checkbox3) {
        fetch(setPrivacyUrl, {
            method: 'POST',
            body: JSON.stringify({
                datiParticolari: checkbox1,
                marketingDiretto: checkbox2,
                profilazione: checkbox3,
                //noShow: noShow,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (response.ok) {
                setOpenPolicyDialog(false)
            }
        }).catch(e => {
        });
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    const handleClickPersonal = () => {
        setTimeoutAvatar(true);
        setPeoplePolicies([]);
        if (selectedAvatar.current === 'personal') {
            selectedAvatar.current = "null";
            getWhitelistPolicies()
        } else {
            selectedAvatar.current = 'personal';
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleClickPerson = (id) => {
        setTimeoutAvatar(true);
        setPersonalPolicies([]);
        setPeoplePolicies([]);
        if (selectedAvatar.current === id) {
            selectedAvatar.current = "null";
            getWhitelistPolicies();
            setChecked(people.map(li => li.id));
            setIsCheckAll(true)
        } else {
            selectedAvatar.current = id;
            getWhitelistPolicies(id);
            setChecked([])
            setChecked(current => [...current, id])
            setIsCheckAll(false)
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeople();
            getWhitelistPolicies();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingPeople(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const nothingFunction = () => {
    }

    const onClickPolicy = (id) => {
        gaEventTrackerPolicyDetail('Click', 'Clicked Policy Detail Button')
        return navigate(`/dashboard/policies/${id}`);
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleOpenMenuExport = (event) => {
        setOpenMenuExport(event.currentTarget);
    }

    const handleCloseMenuExport = () => {
        setOpenMenuExport(null);
    }

    const handleFilter = (selectedFilter) => {
        if (filter.current === selectedFilter) {
            filter.current = 'STATUS_DEFAULT';
        } else {
            filter.current = selectedFilter;
        }
        handleCloseMenu();
        if (selectedAvatar.current === "null") {
            getWhitelistPolicies();
        } else if (selectedAvatar.current === "personal") {
            //
        } else {
            getWhitelistPolicies(selectedAvatar.current);
        }
    }

    const stringFilter = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-ascending-date')}`;
            case 'INSURANCE_END_DESC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-descending-date')}`;
            case 'STATUS_ACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-active')}`;
            case 'STATUS_EXPIRING':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expirity')}`;
            case 'STATUS_EXPIRED':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expired')}`;
            case 'STATUS_INACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-killed')}`;
        }
    }

    const calculateMarginRight = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `160px`;
            case 'INSURANCE_END_DESC':
                return `160px`;
            case 'STATUS_ACTIVE':
                return `130px`;
            case 'STATUS_EXPIRING':
                return `170px`;
            case 'STATUS_EXPIRED':
                return `150px`;
            case 'STATUS_INACTIVE':
                return `160px`;
        }
    }

    const calculateMinWidth = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `151px`;
            case 'INSURANCE_END_DESC':
                return `151px`;
            case 'STATUS_ACTIVE':
                return `110px`;
            case 'STATUS_EXPIRING':
                return `151px`;
            case 'STATUS_EXPIRED':
                return `130px`;
            case 'STATUS_INACTIVE':
                return `140px`;
        }
    }

    const getVehicles = () => {
        fetch(getPeopleVehiclesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let count = 0;
            data.map((item, index) => {
                if (item.status !== -1) {
                    count++;
                }
            })
            if (count === 0) {
                sessionStorage.setItem('noVehicles', true)
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getBranchIcon = (policyType) => {
        switch (policyType) {
            case "TUTELA LEGALE":
                return legalProtectionIcon;
            case "AGRICOLTURA":
                return farmingIcon;
            case "AUTO (RCA - CVT)":
            case "AUTO (CVT)":
            case "CVT AUTO D'EPOCA E SPORTIVE":
            case "CVT SOCCORSO":
            case "KASKO CHILOMETRICA":
                return autoCvtIcon;
            case "PET INSURANCE":
                return petInsuranceIcon;
            case "CAUZIONI":
                return depositsIcon;
            case "CREDITI COMMERCIALI":
                return tradeCreditsIcon;
            case "ELETTRONICA":
                return electronicIcon;
            case "ENERGY":
                return energyIcon;
            case "RC AMMINISTRATORI (D&O)":
            case "RC COLPA GRAVE":
                return rcAdminIcon;
            case "FINE ART E COLLEZIONI":
                return artCollectionIcon;
            case "FURTO":
            case "FURTO PRIVATI":
                return theftIcon;
            case "GLOBALE GIOIELLERIE":
                return jewellersIcon;
            case "GLOBALE FABBRICATI":
                return buildingsIcon;
            case "INFORTUNI":
            case "INFORTUNI COLLETTIVA":
            case "INFORTUNI CONDUCENTI":
            case "INFORTUNI E MALATTIA":
            case "INFORTUNI TERZO SETTORE":
            case "PRESTAZIONI DI ASSISTENZA SANITARIA":
                return injuresIcon;
            case "MALATTIA":
                return illnessIcon;
            case "METEO":
                return wheaterIcon;
            case "VIAGGI LINEA BUSINESS":
            case "VIAGGI LINEA PERSONA":
                return travelBusinnessLineIcon;
            case "MULTIRISCHI AZIENDE":
            case "PROPERTY AZIENDE":
            case "RC AZIENDE":
                return companiesIcon;
            case "MULTIRISCHI HO.RE.CA.":
                return horecaIcon;
            case "MULTIRISCHI TERZO SETTORE":
            case "RC TERZO SETTORE":
                return thirdSectorIcon;
            case "MULTIRISCHI CIVILI":
            case "PROPERTY RISCHI CIVILI":
                return civiliansIcon;
            case "MULTIRISCHI UFFICI/LABORATORI":
                return officeLaboratoriesIcon;
            case "RC INQUINAMENTO":
                return rcPollutionIcon;
            case "RC MEDICAL MALPRACTICE":
                return rcMedicalMalpracticeIcon;
            case "RC PRIVATI":
                return rcIndividuals;
            case "RC PROFESSIONALE":
                return rcProfessionalIcon;
            case "RC SPERIMENTAZIONE CLINICA":
                return rcClinicalTrialsIcon;
            case "TECNOLOGICI":
                return technologiesIcon;
            case "AGENZIE VIAGGI":
            case "TOUR OPERATOR / AGENZIA VIAGGI":
                return tourOperatorIcon;
            case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
            case "TRASPORTO MERCI":
            case "STOCK & TRANSIT":
            case "SPEDIZIONIERI E VETTORI":
                return certifiedTransportationIcon;
            case "CORPI":
            case "RC E CORPI NATANTI":
            case "P&I PROTECTION & INDEMNITY":
                return shipIcon;
            case "VITA COLLETTIVE":
            case "RC PATRIMONIALE COLPA LIEVE":
                return collectiveLivesIcon;
            case "VITA INDIVIDUALI":
                return individualLivesIcon;
            case "AVIAZIONE":
                return aviationIcon;
            case "PERDITE PECUNIARIE":
                return depositsIcon;
            case "CYBER RISK":
                //icona mancante
                return theftIcon;
            case "MULTIRISCHI IMPIANTI A FUNE":
                return cablewayIcon;
            default:
                return vectorIcon;
        }
    }

    function reload() {
        window.location.reload();
    }

    const handleRenewPolicy = (personId) => {
        publish('navigateToPayments');
        sessionStorage.setItem('root', '/payments');
        return navigate(`/payments`);
    }

    const handleSignDocumentPolicy = (personId) => {
        publish('navigateToSigning');
        sessionStorage.setItem('root', '/signing');
        return navigate(`/signing`);
    }

    const handleRegulatePolicy = () => {
        publish('navigateToRegulations');
        sessionStorage.setItem('root', '/regulations');
        return navigate(`/regulations`);
    }

    const getWhitelistPolicies = (id) => {
        setIsLoadingPeopleData(true)
        fetch(getWhitelistPoliciesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setAcceptedSigningCompanies(data)
        }).catch(() => {
            setServiceError(true)
        }).then(() => {
            if (id !== undefined) {
                getPersonPolicies(id)
            } else {
                getPeoplePolicies();
            }
        });
    }

    const updateUserVersion = () => {
        fetch(updateUserVersionUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                newVersion: true,
                oldVersion: false
            }),
        }).then((response) => {
            return response.json();
        });
    }

    const formattedPolicy = (policy) => {
        switch (policy.status) {
            case 2:
            case 3:
            case 4:
                if (policy.hasRegulations) {
                    //regola
                    policy.cta = CTA_CATEGORIES.REGULATIONS
                } else if (policy.status !== 2 && !policy.isCompleted) {
                    //paga ora
                    policy.cta = CTA_CATEGORIES.PAYMENTS
                } else {
                    if (policy.status === 2 && policy.hasDocumentToBeSigned) {
                        if (policy.hasPending === true) {
                            policy.cta = CTA_CATEGORIES.PENDING
                        } else {
                            policy.cta = CTA_CATEGORIES.SIGNING
                        }
                    }
                }
                break;
            case 1:
                if (policy.type.includes('VIAGGI')) {
                    if (policy.hasRegulations) {
                        //regola
                        policy.cta = CTA_CATEGORIES.REGULATIONS
                    } else if (!policy.isCompleted) {
                        //paga ora
                        policy.cta = CTA_CATEGORIES.PAYMENTS
                    } else {
                        if (policy.hasDocumentToBeSigned) {
                            if (policy.hasPending === true) {
                                policy.cta = CTA_CATEGORIES.PENDING
                            } else {
                                policy.cta = CTA_CATEGORIES.SIGNING
                            }
                        }
                    }
                }
                break;
            case 5:
                policy.cta = CTA_CATEGORIES.FUTURE
                break;
        }
        return policy;
    }

    return (
        serviceError ?
            <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Container style={{'paddingTop': '10%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Header leftSrc={UserImg} leftAction={() => navigate('/settings')} iconSrc={wideLogoLight}
                                    rightSrc={bellRinging ? BellOn : BellOff}
                                    rightAction={() => navigate('/messages')} email={user.email}/>
                            {!noPeople &&
                                <Fragment>
                                    <Row style={{'marginTop': '11px'}}>
                                        <Col className="col-auto float-left">
                                            <Title>{t('dashboard.beneficiaries-title')}</Title>
                                        </Col>
                                        <Col className="col float-right" style={{'marginTop': '-7px'}}>
                                            {
                                                searchMode &&
                                                <Row className="div-search" style={{'marginRight': '0'}}>
                                                    <Col className="col-10">
                                                        <input id="search-input" className="input-search"
                                                               onChange={e => setSearchValue(e.target.value)}
                                                               autoFocus></input>
                                                    </Col>
                                                    <Col className="col-2"
                                                         style={{
                                                             'paddingRight': '2px',
                                                             'textAlign': 'right'
                                                         }}>
                                                        <img src={exitIcon} alt="close" onClick={handleSearchMode}
                                                             height="25px" width="25px"/>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                !searchMode &&
                                                <img src={searchIcon}
                                                     className="nav-top-logo float-right cursor-pointer"
                                                     height="27px"
                                                     alt="search-icon"
                                                     onClick={handleSearchMode}/>
                                            }

                                        </Col>
                                    </Row>
                                    {isLoadingPeople &&
                                        <Row className="mt-3 w-100">
                                            <Col style={{textAlign: 'left'}}>
                                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                                     className="mr-1"/>
                                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                                     className="mr-1"/>
                                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                                     className="mr-1"/>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        !isLoadingPeople &&
                                        <Row style={{'marginTop': '10px'}}>
                                            <Col className="col-list">
                                                <Container className="people-scrollable-row">
                                                    {
                                                        /*
                                                        <div className="card card-avatar fixed-avatar">
                                                        <CardContent className="card-avatar-content"
                                                                     style={{'marginRight': '10px'}}>
                                                            <Row style={{'width': '50px'}}>
                                                                {
                                                                    user.image === null && user.gender === 'M' &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? useStyles.bigAvatar : useStyles.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === 'F' &&
                                                                    <Avatar alt="my-avatar" src={baseFemaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? useStyles.bigAvatar : useStyles.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === 'C' &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? useStyles.bigAvatar : useStyles.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === null &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? useStyles.bigAvatar : useStyles.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image &&
                                                                    <Avatar alt="my-avatar" src={user.image}
                                                                            className={selectedAvatar.current === 'personal' ? useStyles.bigAvatar : useStyles.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                            </Row>
                                                            <Row
                                                                style={selectedAvatar.current === 'personal' ? {'width': '73px'} : {'width': '67px'}}>
                                                                <h6 className={selectedAvatar.current === 'personal' ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                    style={selectedAvatar.current === 'personal' ? {color: `${setStatusColor(user.status)}`} : {}}>
                                                                    {user.firstName === null && user.lastName === null ?
                                                                        <span
                                                                            className="text-uppercase ellipsis-1">{user.name} </span> :
                                                                        <Fragment>
                                                                        <span
                                                                            className="text-uppercase ellipsis-1">{user.firstName} </span>
                                                                            <span
                                                                                className="text-uppercase ellipsis-1">{user.lastName}</span>
                                                                        </Fragment>
                                                                    }
                                                                </h6>
                                                            </Row>
                                                        </CardContent>
                                                    </div>
                                                         */
                                                    }
                                                    <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                                               id="scroll-container">
                                                        {
                                                            !isLoadingPeople && people.map((person, index) => (
                                                                <div className="card card-avatar" key={index}
                                                                     style={{'backgroundColor': themeContext.color.neutral10}}>
                                                                    <CardContent className="card-avatar-content"
                                                                                 style={{'backgroundColor': themeContext.color.neutral10}}>
                                                                        <Row style={{'width': '50px'}}>

                                                                            {
                                                                                person.image === null && person.gender === 'M' &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseMaleAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                            }
                                                                            {
                                                                                person.image === null && person.gender === 'F' &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseFemaleAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                            }
                                                                            {
                                                                                person.image === null && person.gender === 'C' &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseCompanyAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                            }
                                                                            {
                                                                                person.image === null && person.gender === null &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={baseMaleAvatar}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                            }
                                                                            {
                                                                                person.image &&
                                                                                <Avatar alt="my-avatar"
                                                                                        src={person.image}
                                                                                        sx={selectedAvatar.current === person.id ? useStyles.bigAvatar : useStyles.avatar}
                                                                                        style={{
                                                                                            filter: `${setGrayScale(person.status)}`,
                                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                                        }}
                                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                                            }
                                                                        </Row>
                                                                        <Row
                                                                            style={selectedAvatar.current === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                                            <h6 className={selectedAvatar.current === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                                style={selectedAvatar.current === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                                                {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ? upperCaseFirst(`${person.name}`)
                                                                                    : <span><span
                                                                                        className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                                        className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>
                                                                                }
                                                                            </h6>
                                                                        </Row>
                                                                    </CardContent>
                                                                </div>
                                                            ))
                                                        }
                                                    </Container>
                                                </Container>
                                            </Col>
                                        </Row>
                                    }
                                </Fragment>
                            }
                            <Row className="mb-2" style={{'marginTop': '20px'}}>
                                <Col className="col-2">
                                    <Title>{t('dashboard.policies-title')}</Title>
                                </Col>
                                <Col className="col-10">
                                    {
                                        filter.current === 'STATUS_DEFAULT' &&
                                        <Fragment>
                                            <Row className="float-right">
                                                <Button
                                                    className="filter-btn filter-btn-2 float-right justify-content-end"
                                                    onClick={handleOpenMenuExport}
                                                    style={{
                                                        'color': themeContext.color.neutral400,
                                                        'backgroundColor': 'unset',
                                                        'width': '20px',
                                                        'marginBottom': '6px',
                                                        '& .MuiTouchRipple .MuiTouchRippleVisible': {
                                                            'left': '3px',
                                                        },
                                                    }}>
                                                    <img onClick={handleOpenMenuExport} src={exportIcon}
                                                         style={{'width': '17px'}} alt="export-icon"/>
                                                </Button>
                                                <hr style={{
                                                    'transform': 'rotate(90deg)',
                                                    'width': '40px',
                                                    'marginTop': '2px',
                                                    'marginRight': '0px'
                                                }}/>
                                                <Button
                                                    className="filter-btn filter-btn-2 float-right justify-content-end"
                                                    style={{
                                                        'color': themeContext.color.neutral400,
                                                        'backgroundColor': 'unset',
                                                        'width': '20px',
                                                        'marginRight': 18,
                                                        '& .MuiTouchRipple .MuiTouchRippleVisible': {
                                                            'paddingRight': '35px',
                                                        },
                                                    }}
                                                    onClick={handleOpenMenu}>
                                                        <span className="material-symbols-outlined" style={{
                                                            'fontSize': '22px',
                                                            'width': 'auto',
                                                        }}>
                                                        filter_list
                                                        </span>
                                                </Button>
                                            </Row>
                                        </Fragment>
                                    }
                                    {
                                        filter.current !== 'STATUS_DEFAULT' &&
                                        <Row className="float-right">
                                            <Button
                                                className="filter-btn filter-btn-2  justify-content-end"
                                                style={{
                                                    'color': themeContext.color.neutral400,
                                                    'backgroundColor': 'unset'
                                                }}
                                                onClick={handleOpenMenuExport}>
                                                <img onClick={handleOpenMenuExport} src={exportIcon}
                                                     style={{'width': '17px'}} alt="export-icon"/>
                                            </Button>
                                            <hr style={{
                                                'transform': 'rotate(90deg)',
                                                'width': '40px',
                                                'marginTop': '2px',
                                            }}/>
                                            <Button className="filter-btn filter-btn-2  justify-content-end"
                                                    style={{
                                                        'color': themeContext.color.main,
                                                        'backgroundColor': 'unset',
                                                        'marginRight': 18,
                                                        '& .MuiTouchRipple .MuiTouchRippleVisible': {
                                                            'left': '-5px',
                                                            'right': '15px',
                                                            'width': 'auto'
                                                        },
                                                    }}
                                                    onClick={handleOpenMenu}>
                                                <span className="material-symbols-outlined" style={{
                                                    'fontSize': '22px',
                                                }}>
                                                    filter_list
                                                </span>
                                                <span style={{
                                                    'minWidth': '30px',
                                                    'padding': '0px'
                                                }}>
                                                    {stringFilter(filter.current)}
                                                </span>
                                            </Button>
                                        </Row>
                                    }
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={openMenu}
                                        keepMounted
                                        open={Boolean(openMenu)}
                                        onClose={handleCloseMenu}
                                        className="menu filter-menu-dashboard"
                                        PaperProps={{style: {'maxWidth': '100%', 'width': '200px'}}}>
                                        <MenuList className="title-filter-item">{t('dashboard.filter-title')}</MenuList>
                                        <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_ACTIVE')}
                                                  style={filter.current === 'STATUS_ACTIVE' ? {
                                                      'color': themeContext.color.green,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.green}}>
                                            {
                                                filter.current === 'STATUS_ACTIVE' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-active')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-active')
                                            }
                                        </MenuItem>
                                        <MenuItem className="filter-item"
                                                  onClick={() => handleFilter('STATUS_EXPIRING')}
                                                  style={filter.current === 'STATUS_EXPIRING' ? {
                                                      'color': themeContext.color.yellow,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.yellow}}>
                                            {
                                                filter.current === 'STATUS_EXPIRING' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-expirity')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-expirity')
                                            }
                                        </MenuItem>
                                        <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_EXPIRED')}
                                                  style={filter.current === 'STATUS_EXPIRED' ? {
                                                      'color': themeContext.color.red,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.red}}>
                                            {
                                                filter.current === 'STATUS_EXPIRED' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-expired')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-expired')
                                            }
                                        </MenuItem>
                                        <MenuItem className="filter-item"
                                                  onClick={() => handleFilter('STATUS_INACTIVE')}
                                                  style={filter.current === 'STATUS_INACTIVE' ? {
                                                      'color': themeContext.color.neutral200,
                                                      'fontWeight': '800',
                                                      'backgroundColor': themeContext.color.neutral10
                                                  } : {'color': themeContext.color.neutral200}}>
                                            {
                                                filter.current === 'STATUS_INACTIVE' ?
                                                    <Fragment>
                                                        {t('dashboard.filter-killed')}
                                                        <img src={exitIcon} className="ml-auto" alt="exit-icon"/>
                                                    </Fragment> : t('dashboard.filter-killed')
                                            }
                                        </MenuItem>
                                    </Menu>
                                    <Menu
                                        id="simple-menu-export"
                                        anchorEl={openMenuExport}
                                        keepMounted
                                        open={Boolean(openMenuExport)}
                                        onClose={handleCloseMenuExport}
                                        className="menu filter-menu-dashboard"
                                        PaperProps={{style: {'maxWidth': '250px', 'width': '250px', 'left': '70px'}}}>
                                        {!exportType &&
                                            <MenuList className="title-filter-item">
                                                {t('dashboard.policy-export-title')}
                                            </MenuList>
                                        }
                                        {!exportType &&
                                            people.map((person, index) => (
                                                <Row key={index}>

                                                    <MenuItem className="filter-item"
                                                              style={{'width': '100%', 'marginTop': '-10px'}}>
                                                        <div style={{'display': 'grid', 'overflow': 'hidden'}}>
                                                            <Row>
                                                                <Col className={"col-1"}>
                                                                    <span style={{
                                                                        'paddingTop': '0px',
                                                                        'width': '220px',
                                                                        'whiteSpace': 'nowrap',
                                                                        'overflow': 'hidden',
                                                                        'textOverflow': 'ellipsis',
                                                                        'display': 'block',
                                                                        'marginLeft': '15px'
                                                                    }}>
                                                                        <Checkbox
                                                                            key={index}
                                                                            value={person.id}
                                                                            name={String(person.id)}
                                                                            id={String(person.id)}
                                                                            type="checkbox"
                                                                            onClick={handleCheck}
                                                                            checked={checked.includes(person.id)}
                                                                            checkedIcon={<Checked/>}
                                                                            icon={<Unchecked/>}
                                                                        />
                                                                        {(((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === ""))) ? person.name : person.firstName + ' ' + person.lastName}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </MenuItem>
                                                </Row>
                                            ))
                                        }
                                        {!exportType &&
                                            <Row style={{
                                                'justifyContent': 'right',
                                                'marginRight': '10px',
                                                'marginTop': '10px'
                                            }}>
                                                <Col className="col-8 left"
                                                     style={{'marginTop': '-5px', 'paddingLeft': ' 31px'}}>
                                                    <Checkbox
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        onClick={checkAll}
                                                        checked={isCheckAll}
                                                        checkedIcon={<Checked/>}
                                                        icon={<Unchecked/>}
                                                    />
                                                    <span style={{
                                                        'textDecoration': 'underline',
                                                        'color': themeContext.color.main
                                                    }}>
                                                        {t('dashboard.select-all')}
                                                    </span>
                                                </Col>
                                                <Col className="col-4 right">
                                                    <Button style={{'marginTop': '0px'}}
                                                            sx={useStyles.renewButton}
                                                            onClick={() => {
                                                                setExportType(true)
                                                            }}
                                                            disabled={checked.length === 0}>
                                                        {t('dashboard.proceed-button')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        {exportType &&
                                            <MenuList className="title-filter-item" style={{'marginBottom': '10px'}}>
                                                {t('dashboard.extensions-title')}
                                            </MenuList>
                                        }
                                        {exportType &&
                                            <Row style={{'marginLeft': '15px'}}>
                                                <Radio
                                                    checked={selectedRadioCsv === 'csv'}
                                                    onChange={handleRadioCsv}
                                                    value="csv"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'csv'}}
                                                    className="justify-content-end"
                                                    style={{'marginTop': '-8px'}}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span>{t('dashboard.extensions-1')}</span>
                                            </Row>
                                        }
                                        {exportType &&
                                            <Row style={{'marginLeft': '15px'}}>
                                                <Radio
                                                    checked={selectedRadioXlsx === 'xlsx'}
                                                    onChange={handleRadioXlsx}
                                                    value="xlsx"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'xlsx'}}
                                                    className="justify-content-end"
                                                    style={{'marginTop': '-8px'}}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span>{t('dashboard.extensions-2')}</span>
                                            </Row>

                                        }
                                        {exportType &&
                                            <Row style={{'marginLeft': '15px'}}>
                                                <Radio
                                                    checked={selectedRadioPdf === 'pdf'}
                                                    onChange={handleRadioPdf}
                                                    value="pdf"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'pdf'}}
                                                    className="justify-content-end"
                                                    style={{'marginTop': '-8px'}}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span>{t('dashboard.extensions-3')}</span>
                                            </Row>

                                        }
                                        {exportType &&
                                            <Row style={{
                                                'justifyContent': 'right',
                                                'marginRight': '10px',
                                                'marginTop': '10px'
                                            }}>
                                                <Col className="col-6 left">
                                                    <Button style={{'marginTop': '0px', 'marginLeft': '10px'}}
                                                            sx={useStyles.renewButton}
                                                            onClick={handleBackCheck}>
                                                        {t('dashboard.back-button')}
                                                    </Button>
                                                </Col>
                                                <Col className="col-6 float-right">
                                                    <Button style={{'marginTop': '0px', 'marginLeft': '30px'}}
                                                            sx={useStyles.renewButton}
                                                            disabled={selectedRadioCsv === '' && selectedRadioXlsx === '' && selectedRadioPdf === ''}
                                                            onClick={exportFile}>
                                                        {isLoading ?
                                                            <img src={spinner} className="spinner-img-inner-button"
                                                                 alt="spinner"/>
                                                            :
                                                            <span>
                                                                {t('dashboard.export-button')}
                                                            </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Menu>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {!noPeople ?
                        <Container className="top-of-the-dashboard"></Container>
                        :
                        <Container className="top-of-the-dashboard" style={{'paddingTop': '112px'}}></Container>
                    }
                    {
                        isLoadingPeopleData &&
                        <Fragment>
                            <Row className="mt-2">
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-list">
                                    {width_min && !width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                    {width_min && width_767 && !width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && !width_1199 &&
                                        <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                    {width_min && width_767 && width_991 && width_1199 &&
                                        <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                </Col>
                            </Row>
                        </Fragment>
                    }
                    <Row className="mt-2">
                        <Col>
                            {
                                !isLoadingPeopleData && isNothingPeople && selectedAvatar.current === 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('dashboard.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('dashboard.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingPeopleData && isNothingPeople && selectedAvatar.current !== 'null' &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages" alt={t('dashboard.nothing-text-1')}>
                                        </img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('dashboard.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingPeopleData && !isNothingPeople &&
                                peoplePolicies.map((value, index) => (
                                    <Card key={index} onClick={() => onClickPolicy(value.id)} sx={useStyles.card}
                                          style={{'borderRight': `12px solid ${setStatusColor(value.status)}`}}>
                                        <CardContent style={{'paddingBottom': '12px'}}>
                                            <Row>
                                                <Col className="col-auto mr-0" style={{'paddingRight': '0px'}}>
                                                    <img src={getBranchIcon(value.type)} alt="home-policy-icon"
                                                         className="branch-icon"/>
                                                </Col>
                                                <Col className="col-7">
                                                    <CardTextMid>{value.type}</CardTextMid>
                                                </Col>
                                                <Col>
                                                    {
                                                        value.image === null && value.gender === 'M' &&
                                                        <Avatar src={baseMaleAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'F' &&
                                                        <Avatar src={baseFemaleAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === 'C' &&
                                                        <Avatar src={baseCompanyAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image === null && value.gender === null &&
                                                        <Avatar src={baseMaleAvatar} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                    {
                                                        value.image &&
                                                        <Avatar src={value.image} sx={useStyles.cardAvatar}
                                                                alt="male-avatar"/>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content">{t('dashboard.expiration-label')}
                                                        <span className="font-weight-bold">
                                                        <Moment locale="it"
                                                                format="DD/MM/YYYY" className="black">
                                                            {value.insuranceEnd}
                                                        </Moment>
                                                    </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('dashboard.object-label')}
                                                        <span className="font-weight-bold black">
                                                            {value.object}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('dashboard.company-label')}
                                                        <span className="font-weight-bold black">
                                                                {value.company}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col" style={{'paddingRight': '0'}}>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="card-text-content ellipsis-1-v2">
                                                                {t('dashboard.policy-number-label')}
                                                                <span className="font-weight-bold black">
                                                                        {value.policyNr}
                                                                </span>
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        value.plate !== null &&
                                                        <Row>
                                                            <div className="col-plate">
                                                                <img src={car} alt="car icon"/>
                                                                <span className="card-text-content"
                                                                      style={{
                                                                          'marginLeft': '7px',
                                                                          'color': themeContext.color.neutral600
                                                                      }}>
                                                                    {value.plate}
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    }
                                                </Col>
                                                {(value.cta && value.cta !== CTA_CATEGORIES.FUTURE) &&
                                                    <Col className={'text-right pl-0 pb-0 mb-0 mt-2'}>
                                                        <Row className="float-right">
                                                            <Col>
                                                                <Button
                                                                    style={value.plate === null ? {marginTop: 0} : {marginTop: 24}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        switch (value.cta) {
                                                                            case CTA_CATEGORIES.PAYMENTS:
                                                                                handleRenewPolicy(value.id);
                                                                                break;
                                                                            case CTA_CATEGORIES.SIGNING:
                                                                            case CTA_CATEGORIES.PENDING:
                                                                                handleSignDocumentPolicy(value.id);
                                                                                break;
                                                                            case CTA_CATEGORIES.REGULATIONS:
                                                                                handleRegulatePolicy();
                                                                                break;
                                                                        }
                                                                    }}
                                                                    sx={value.cta === CTA_CATEGORIES.PENDING ? useStyles.revisionButton : useStyles.renewButton}>
                                                                    {value.cta === CTA_CATEGORIES.PAYMENTS && t('dashboard.renew-button')}
                                                                    {value.cta === CTA_CATEGORIES.SIGNING && t('dashboard.sign-button')}
                                                                    {value.cta === CTA_CATEGORIES.PENDING && t('dashboard.revision-button')}
                                                                    {value.cta === CTA_CATEGORIES.REGULATIONS && t('dashboard.regulations-button')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                            </Row>
                                            {(value.cta && value.cta === CTA_CATEGORIES.FUTURE) &&
                                                <Row style={{'justifyContent': 'center'}}>
                                                    <Col>
                                                        <CardTextCenter>
                                                            <img src={activeFromIcon} alt="active-from-icon"
                                                                 style={{
                                                                     'paddingTop': '5px',
                                                                     'paddingBottom': '9px',
                                                                     'paddingRight': '4px'
                                                                 }}/>
                                                            {t('dashboard.active-from-label')}
                                                            <CardTextCenter>
                                                                <Moment locale="it"
                                                                        format="DD/MM/YYYY"
                                                                        sx={useStyles.cardTextCenter}
                                                                        style={{'fontWeight': '700'}}>{value.insuranceStart}
                                                                </Moment>
                                                            </CardTextCenter>
                                                        </CardTextCenter>
                                                    </Col>
                                                </Row>
                                            }
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                    <Container className="bottom-with-navbar"
                               style={{'backgroundColor': themeContext.color.neutral10}}></Container>
                </Container>
                {openPolicyDialog &&
                    <PrivacyPolicyDialog open={openPolicyDialog} checkbox1={checkbox1} checkbox2={checkbox2}
                                         checkbox3={checkbox3} document={privacyPolicyDocument}
                                         handleClose={() => setOpenPolicyDialog(false)}
                                         proceedLater={true}
                                         handleSave={(checkbox1, checkbox2, checkbox3) => {
                                             setPrivacy(checkbox1, checkbox2, checkbox3)
                                         }}
                    />}
                <DialogDoubleAction open={openDialog}
                                    title={t('dashboard.biometrics-modal-title')}
                                    description={
                                        <span>{t('dashboard.biometrics-modal-description-1')}<br/><br/>{t('dashboard.biometrics-modal-description-2')}</span>}
                                    handleClose={handleClose} handleNo={handleClose} handleYes={handleBiometrics}
                                    buttonYesText={t('dashboard.biometrics-modal-confirm-button')}
                                    buttonNoText={t('dashboard.biometrics-modal-refuse-button')}/>
            </Fragment>
    )
}