import React, {Fragment} from 'react'
import {Col, Row} from "react-bootstrap";
import {useMediaQuery} from "react-responsive";
import cardPlaceholder_min from "../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../images/card-placeholder_full.svg";

/*
* cardsNr: number of cards on loading
* */
const CardsOnLoading = ({cardsNr = 4}) => {
    const width_1199 = useMediaQuery({query: '(min-width: 1199px)'})
    const width_991 = useMediaQuery({query: '(min-width: 991px)'})
    const width_767 = useMediaQuery({query: '(min-width: 766px)'})
    const width_min = useMediaQuery({query: '(min-width: 0px)'})

    const getImageSrc = () => {
        if (width_min && !width_767 && !width_991 && !width_1199) return cardPlaceholder_min;
        if (width_min && width_767 && !width_991 && !width_1199) return cardPlaceholder_678;
        if (width_min && width_767 && width_991 && !width_1199) return cardPlaceholder_918;
        if (width_min && width_767 && width_991 && width_1199) return cardPlaceholder_full;
    };

    return (
        <Fragment>
            {Array(cardsNr).fill().map((_, index) => (
                <Row key={index}>
                    <Col>
                        <img src={getImageSrc()} width="100%" alt="card"/>
                    </Col>
                </Row>
            ))}
        </Fragment>
    )
}
export default CardsOnLoading;