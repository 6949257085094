import React, {Fragment, useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import nothing from '../../images/nothing-icon.svg';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/it';
import back from '../../images/back-icon.svg';
import 'react-swipeable-list/dist/styles.css';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import car from "../../images/car-blue.svg";
import {useMediaQuery} from "react-responsive";
import cardPlaceholder_min from "../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../images/card-placeholder_full.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important",
        width: "100% !important",
    },
    card: {
        margin: "0 auto !important",
        marginTop: "10px !important",
        marginBottom: "18px !important",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        minHeight: "140px !important"
    },
}));

export default function VehicleClaims(styles) {
    const [isLoadingSX, setIsLoadingSX] = useState(true);
    const [isLoadingDX, setIsLoadingDX] = useState(true);
    const params = useParams();
    const vehicleId = params.vehicleId;
    const personId = params.personId;

    useEffect(() => {
        getVehicle();
    }, []);

    const [activeClaims, setActiveClaims] = useState([]);
    const [notActiveClaims, setNotActiveClaims] = useState([]);
    const [vehicle, setVehicle] = useState([]);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [tab, setTab] = useState(sessionStorage.getItem('tab_vehicleClaims') ?  parseInt(sessionStorage.getItem('tab_vehicleClaims')) : 0);
    const classes = useStyles();
    const vehicleInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/vehicle`;
    const vehicleClaimsUrl = `${process.env.REACT_APP_BASE_URL}/claims/people`;
    const [serviceError, setServiceError] = useState(false);


    let tabStyle = {
        borderBottom: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
    };

    let tabClass = {
        //
    };

    let labelStyle = {
        textTransform: "none",
        fontWeight: "700"
    };

    const token = sessionStorage.getItem('token');

    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })

    function handleTabs(event, value) {
        setTab(value);
        sessionStorage.setItem('tab_vehicleClaims', value);
    }

    function reload() {
        window.location.reload();
    }

    function getVehicle() {
        fetch(`${vehicleInfoUrl}/${vehicleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setVehicle(data);
            }
            return data.personId
        }).then((personId) => {
            setIsLoadingSX(true);
            setIsLoadingDX(true);
            fetch(`${vehicleClaimsUrl}?personId=${personId}&vehicleId=${vehicleId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json();
            }).then((data) => {
                data.map((item, index) => {
                    if (item.status !== -1) {
                        if (item.closedate !== null) {
                            setNotActiveClaims(current => [...current, item]);
                        } else {
                            setActiveClaims(current => [...current, item]);
                        }
                    }
                })
                setIsLoadingDX(false);
                setIsLoadingSX(false);
            })
        }).catch(() => {
            setServiceError(true)
        })

    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    function turnBack() {
        sessionStorage.removeItem('tab_vehicleClaims');
        return navigate(-1);
    }

    const onClickClaim = (id) => {
        return navigate(`/settings/people/${personId}/claims/${id}`);
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2"></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top container"
                         style={{
                             'paddingLeft': '0',
                             'paddingRight': '0',
                             'backgroundColor': themeContext.color.white,
                         }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className='col-6 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px" alt="back"/>
                                </Col>
                            </Row>
                            <Row style={{
                                paddingTop: '11px',
                                background: themeContext.color.white
                            }}>
                                <Col className="col-10">
                                    <h2 className="float-left title-page ellipsis-1">{t('claim.title')}
                                        {vehicle.plate}
                                    </h2>
                                </Col>
                                <Col className="col-2 mt-1">
                                </Col>
                            </Row>
                            <Row>
                                <div className={classes.root}>
                                    <Tabs
                                        value={tab}
                                        onChange={handleTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth">
                                        <Tab
                                            key="Attive"
                                            label={<span style={labelStyle}>{t('claim.opened-claims-tab')}</span>}
                                            classes={tabClass}
                                            style={tabStyle}/>
                                        <Tab
                                            key="Non attive"
                                            label={<span style={labelStyle}>{t('claim.closed-claims-tab')}</span>}
                                            classes={tabClass}
                                            style={tabStyle}/>
                                    </Tabs>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Container className="top-of-the-messages"></Container>
                    <Row style={{
                        'backgroundColor': themeContext.color.neutral10,
                    }}>
                        <Col>
                            {
                                isLoadingSX && tab === 0 &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                isLoadingDX && tab === 1 &&
                                <Fragment>
                                    <Row className="mt-2">
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-list">
                                            {width_min && !width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                            {width_min && width_767 && !width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && !width_1199 &&
                                                <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                            {width_min && width_767 && width_991 && width_1199 &&
                                                <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {
                                !isLoadingSX && activeClaims.length === 0 && tab === 0 &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('claim.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claim.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claim.nothing-text-2')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {
                                !isLoadingDX && notActiveClaims.length === 0 && tab === 1 &&
                                <Container>
                                    <Row>
                                        <img src={nothing} className="noMessages"
                                             alt={t('claim.nothing-text-1')}
                                        ></img>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claim.nothing-text-1')}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="no-messages-text">{t('claim.nothing-text-2')}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {!isLoadingSX && tab === 0 && activeClaims.length > 0 &&
                                activeClaims.map((value, index) => (
                                    <Card className={classes.card}
                                          key={index}
                                          onClick={() => onClickClaim(value.id)}>
                                        <CardContent style={{'paddingBottom': '7px'}}>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-mid ellipsis-1-v2">
                                                        {value.claimtype}
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claim.practise-number')}
                                                        <span className="font-weight-bold black">
                                                           {value.claimnr}
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">{t('claim.open-date')}
                                                        <span className="font-weight-bold black">
                                                             <Moment locale="it" format="DD/MM/YYYY">
                                                                  {value.eventdate}
                                                             </Moment>
                                                        </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.controparte && <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claim.counterpart')}
                                                        <span className="font-weight-bold black">
                                                        {value.controparte}
                                                    </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claims.status')}
                                                        <span className="font-weight-bold">
                                                                    <span
                                                                        style={(value.settledValueCents > 0 && value.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                            : value.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                                : (value.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                                    : (value.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                                            {value.closeType}
                                                            </span>
                                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.settledValueCents > 0 && <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claims.settled-value')}
                                                        <span className="font-weight-bold black">
                                                                {value.settledValue}
                                                         </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <div className="col-plate">
                                                    <img src={car} alt="car icon"/>
                                                    <span className="card-text-content" style={{'marginLeft': '7px'}}>
                                                         {vehicle.plate}
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardContent>
                                    </Card>
                                ))
                            }
                            {!isLoadingDX && tab === 1 && notActiveClaims.length > 0 &&
                                notActiveClaims.map((value, index) => (
                                    <Card className={classes.card}
                                          key={index}
                                          onClick={() => onClickClaim(value.id)}>
                                        <CardContent style={{'paddingBottom': '7px'}}>
                                            <Row>
                                                <Col className="col-8">
                                                    <h6 className="card-text-mid ellipsis-1-v2">
                                                        {value.claimtype}
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claim.practise-number')}
                                                        <span className="font-weight-bold black">
                                                                {value.claimnr}
                                                            </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content">
                                                        {t('claim.open-date')}
                                                        <span className="font-weight-bold black">
                                                    <Moment locale="it"
                                                            format="DD/MM/YYYY">{value.eventdate}</Moment>
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.controparte && <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claim.counterpart')}
                                                        <span className="font-weight-bold black">
                                                        {value.controparte}
                                                    </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <Col>
                                                    <h6 className="card-text-content">
                                                <span className="ellipsis-1">
                                                    {t('claims.status')}
                                                    <span className="font-weight-bold black">
                                                        <span
                                                            style={(value.settledValueCents > 0 && value.closeType === 'SINISTRO CHIUSO') ? {color: themeContext.color.green}
                                                                : value.closeType === 'SINISTRO APERTO' ? {color: themeContext.color.mainActive}
                                                                    : (value.closeType === 'SINISTRO CHIUSO' ? {color: themeContext.color.red}
                                                                        : (value.closeType === 'SENZA SEGUITO' ? {color: themeContext.color.neutral200} : {}))}>
                                                                {value.closeType}
                                                        </span>
                                                    </span>
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>
                                            {value.settledValueCents > 0 && <Row>
                                                <Col>
                                                    <h6 className="card-text-content ellipsis-1-v2">
                                                        {t('claims.settled-value')}<span
                                                        className="font-weight-bold black">
                                                            {value.settledValue}
                                                </span>
                                                    </h6>
                                                </Col>
                                            </Row>}
                                            <Row>
                                                <div className="col-plate">
                                                    <img src={car} alt="car icon"/>
                                                    <span className="card-text-content"
                                                          style={{'marginLeft': '7px'}}>
                                                        {vehicle.plate}
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                </Container>
                {
                    /*
                    <Container className="end-of-the-page" style={{
                    'background': themeContext.color.white,
                    'borderLeft': '1px solid #EEE9E5',
                    'borderRight': '1px solid #EEE9E5',
                    'height': '100%',
                    'position': 'fixed',
                    'right': '0',
                    'left': '0'
                }}></Container>
                     */
                }

                <Container className="mb-2 fixed-bottom">
                </Container>
            </Fragment>
    )
}