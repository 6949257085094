import Container from "react-bootstrap/Container";
import {Col, Form, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import {
    Button,
    Dialog,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    TextField
} from "@mui/material";
import {MobileDatePicker, MobileTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import {useForm} from 'react-hook-form';
import emailPlaceholder from "../../images/email-placeholder.svg";
import {useMediaQuery} from "react-responsive";
import spinner from "../../images/spinner.svg";
import addIcon from "../../images/add.svg";
import DialogTitle from "@mui/material/DialogTitle";
import exitIcon from "../../images/exit-icon.svg";
import DialogContent from "@mui/material/DialogContent";
import trashIcon from "../../images/trash-icon.svg";
import FsLightbox from "fslightbox-react";
import Resizer from "react-image-file-resizer";
import CropDialog from "../components/CropDialog.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import themeContext from "../components/ThemeContext.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenericClaimForm() {
    const [locale, setLocale] = useState("it");
    moment.locale(locale);
    const getClaimAttachmentUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleLite`;
    const newClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide/new`;
    const updateClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const sendClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const addAttachmentToClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const removeAttachmentFromClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const peoplePoliciesUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/people`;
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [serviceError, setServiceError] = useState(false);
    const defaultValues = {
        claimType: "generic",
        policyId: "",
        personId: "",
        dateTime: "",
        description: ""
    };
    const {sendControl, handleSubmit: handleSendSubmit} = useForm();
    const {attachControl, handleSubmit: handleAttachSubmit} = useForm();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingPolicy, setIsLoadingPolicy] = useState(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [isLoadingSend, setIsLoadingSend] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const formValues = useRef(defaultValues);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [people, setPeople] = useState([]);
    const params = useParams();
    //const claimId = params.claimId;
    const [imagesRequired, setImagesRequired] = useState(false);
    const ids = useRef([]);
    const new_ids = useRef([]);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [imageIdToDelete, setImageIdToDelete] = useState(-1);
    const [documents, setDocuments] = useState([]);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [claimId, setClaimId] = useState(undefined);
    const [openDoNotSaveDialog, setOpenDoNotSaveDialog] = useState(false);
    const [tempImages, setTempImages] = useState([]);
    const [tempImagesUrl, setTempImagesUrl] = useState([]);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const [policies, setPolicies] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState("");
    const [uploadError, setUploadError] = useState(null);

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    useEffect(() => {
        if (navigator.onLine) {
            if (claimId !== undefined) {
                formValues.current = JSON.parse(sessionStorage.getItem(`${claimId}`))
                if (formValues.current.dateTime) {
                    const utcDate = moment.utc(formValues.current.dateTime).format();
                    setSelectedDate(utcDate);
                }
            }
            getPeople();
            checkValidForm();
            if (sessionStorage.getItem(`${claimId}_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_images`)).length > 0) {
                let claimImages = JSON.parse(sessionStorage.getItem(`${claimId}_images`));
                if (claimImages && claimImages.length > 0) {
                    ids.current = claimImages
                    for (let i = 0; i < claimImages.length; i++) {
                        getClaimAttachment(claimImages[i])
                    }
                }
            }
        }
    }, []);

    function turnBack() {
        if (isSent) {
            return navigate(`/claims`);
        } else {
            return navigate(`/claims/open`);
        }
    }

    function reload() {
        window.location.reload();
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'personId':
                getPoliciesByPersonId(value)
                break;
            case 'policyId':
                setSelectedPolicy(value)
                break;
        }
        formValues.current = {...formValues.current, [name]: value,}
        checkValidForm();
    };

    const checkValidForm = () => {
        if (formValues.current.personId !== '' && formValues.current.personId !== 0)
            setIsFormInvalid(false);
    };

    const handleDateChange = (date) => {
        const utcDate = moment.utc(date).format();
        setSelectedDate(utcDate);
    };

    const submitForm = () => {
        const checkImages = JSON.parse(sessionStorage.getItem(`${claimId}_images`))
        /*
        if (claimId === undefined) {
            setImagesRequired(true)
            return;
        } else if (checkImages.length === 0) {
            setImagesRequired(true)
            return;
        }
         */
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['policyId']: selectedPolicy,}
        //update
        setIsLoadingSend(true)
        if (claimId === undefined) {
            fetch(newClaimUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                fetch(`${updateClaimUrl}/${res.id}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formValues.current)
                }).then((response) => {
                    return response.json();
                }).then((res) => {
                    if (res.id) {
                        fetch(`${sendClaimUrl}/${res.id}/send`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((res) => {
                            if (res.ok) {
                                sessionStorage.setItem('root', '/claims')
                                setIsSent(true)
                            }
                        }).catch(() => {
                            setIsLoadingSend(false)
                        });
                    }
                }).catch(() => {
                    setIsLoadingSend(false)
                });
            }).catch(() => {
                setIsLoadingSend(false)
            });
        } else {
            fetch(`${updateClaimUrl}/${claimId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    fetch(`${sendClaimUrl}/${res.id}/send`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                    }).then((response) => {
                        return response.json();
                    }).then((res) => {
                        if (res.ok) {
                            sessionStorage.setItem('root', '/claims')
                            setIsSent(true)
                        }
                    }).catch(() => {
                        setIsLoadingSend(false)
                    });
                }
            }).catch(() => {
                setIsLoadingSend(false)
            });
        }
    }

    const createClaim = () => {
        setTempImagesUrl(imagesUrl)
        setTempImages(images)
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        if (claimId === undefined) {
            fetch(newClaimUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    sessionStorage.setItem(`${res.id}`, JSON.stringify(formValues.current))
                    setOpenUploadDialog(true)
                    setClaimId(res.id)
                }
            }).catch(() => {
            });
        } else {
            //reopen attach without create
            sessionStorage.setItem(`${claimId}`, JSON.stringify(formValues.current))
            setOpenUploadDialog(true)
        }
    }

    const updateClaim = () => {
        setTempImagesUrl(imagesUrl)
        setTempImages(images)
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        //reopen attach without create
        sessionStorage.setItem(`${claimId}`, JSON.stringify(formValues.current))
        setOpenUploadDialog(true)
    }

    const getPeople = () => {
        setIsLoadingData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((item, index) => {
                if (item.status !== -1 && item.status !== 1) {
                    setPeople(current => [...current, item])
                }
            })
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getPoliciesByPersonId = (personId) => {
        setIsLoadingPolicy(true)
        fetch(`${peoplePoliciesUrl}?filter=STATUS_DEFAULT&personId=${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.length > 0) {
                let activePolicies = data.filter(p => p.status !== -1 && p.status !== 1);
                setPolicies(activePolicies)
                activePolicies.map((policy, index) => {
                    if (index === 0) {
                        setSelectedPolicy(policy.id)
                    }
                })
            } else {
                setSelectedPolicy("")
                setPolicies([])
            }
            setIsLoadingPolicy(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getClaimAttachment = (attachmentId) => {
        setIsLoadingData(true)
        fetch(`${getClaimAttachmentUrl}/${claimId}/attachment/${attachmentId}/download`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.blob();
        }).then((data) => {
            let urlCreator = window.URL || window.webkitURL;
            let imageUrl = urlCreator.createObjectURL(data);
            setImages(current => [...current, {id: attachmentId, url: imageUrl}])
            setImagesUrl(current => [...current, imageUrl])
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const openDeleteImageDialog = (id) => {
        setImageIdToDelete(id);
        setOpenDialog2(true)
    }

    const handleDeleteImage = (attachmentId) => {
        fetch(`${removeAttachmentFromClaimUrl}/${claimId}/attachments/${attachmentId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                ids.current = ids.current.filter(id => {
                    return id !== attachmentId;
                })
                setImages([])
                setImagesUrl([])
                if (window.location.href.includes('attachAccidentReportImages')) {
                    sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                } else {
                    sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                }
                if (ids.current.length > 0) {
                    for (let i = 0; i < ids.current.length; i++) {
                        if (ids.current[i] !== attachmentId) {
                            getClaimAttachment(ids.current[i]);
                        }
                    }
                }
            }
            setOpenDialog2(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const attachImageActions = (documentType, imagesLength) => {
        if (imagesLength >= 15) {
            setOpenPhotoLimitsDialog(true);
        } else {
            handleChangeImage();
        }
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleOnPendingImage = async (e) => {
        setUploadError(null)
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image')) {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            if (err === 'Maximum upload size exceeded') {
                setUploadError(t('File not supported') + '<br />' + t('The maximum image upload size is 20MB'))
            } else if ('File not supported') {
                setUploadError(t('The uploaded format is not supported') + '<br />' + t('Only image formats are supported'))
            }
        }
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(`${addAttachmentToClaimUrl}/${claimId}/attachments/new`, {
                method: 'POST',
                body: JSON.stringify({
                    name: randomName,
                    mimeType: mimetype,
                    base64: base64_string,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false);
                ids.current.push(res.id)
                new_ids.current.push(res.id)
                return res.id;
            }).then((attachment) => {
                getClaimAttachment(attachment);
            }).catch(() => {
                setServiceError(true)
            });
        }
    }

    const doNotSave = () => {
        setOpenDoNotSaveDialog(false)
        setOpenUploadDialog(false)
        setImages(tempImages)
        setImagesUrl(tempImagesUrl)

        ids.current = ids.current.filter(id_to_maintain => {
            if (!new_ids.current.includes(id_to_maintain)) {
                return id_to_maintain
            }
        })
        new_ids.current = []
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0'
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('carClaimForm.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}
                                  localeText={{
                                      cancelButtonLabel: t('dialog.button-cancel'),
                                      okButtonLabel: t('dialog.button-confirm'),
                                  }}>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="container nav-top border-bottom overZIndex" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0'
                    }}>
                        <Col className="col-2 float-left">
                            <img src={back} onClick={turnBack}
                                 className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-8">
                            <h6 className="header-title ellipsis-1-v2">{t('carClaimForm.title')}</h6>
                        </Col>
                        <Col className="col-2">
                        </Col>
                    </Row>

                    <Container className="top-of-the-payment-title-detail"></Container>
                    {
                        isLoadingData ? <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                            :
                            <Fragment>
                                {
                                    !isSent &&
                                    <Fragment>
                                        <Row className="mt-1" style={{
                                            paddingLeft: '0',
                                            paddingRight: '0',
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            color: themeContext.color.neutral400
                                        }}>
                                            <Col>
                                                <span>{t('genericClaimForm.payoff-1')}</span>
                                                <span>{t('genericClaimForm.payoff-2')}</span>
                                                <span
                                                    className="font-weight-bold">{t('genericClaimForm.payoff-3')}</span>
                                            </Col>
                                        </Row>

                                        <Form
                                            onSubmit={handleSendSubmit(submitForm)}>
                                            <FormControl required variant="outlined" className={'w-100'}>
                                                <InputLabel id="person-label"
                                                            className="mt-4">{t('carClaimForm.person-label')}</InputLabel>
                                                <Select
                                                    disabled={isLoadingData}
                                                    className="mt-4"
                                                    labelId="person-label"
                                                    id="personId"
                                                    name="personId"
                                                    control={sendControl}
                                                    defaultValue={formValues.current.personId}
                                                    onChange={handleInputChange}
                                                    label={t('carClaimForm.person-label')}
                                                    fullWidth
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            'textAlign': 'left',
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600,
                                                            'width': '100%',
                                                        }
                                                    }}>
                                                    {
                                                        people.map((value, index) => (
                                                            <MenuItem key={index + 1} value={value.id}>
                                                                {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                    <span>{value.name}</span> :
                                                                    <span>{value.firstName} {value.lastName}</span>}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl required variant="outlined" className={'w-100'}>
                                                <InputLabel id="policy-label"
                                                            className="mt-4">{t('carClaimForm.claimed-policy')}</InputLabel>
                                                <Select
                                                    displayEmpty
                                                    disabled={isLoadingPolicy || formValues.current.personId === "" || selectedPolicy === ""}
                                                    className="mt-4"
                                                    labelId="policy-label"
                                                    id="policyId"
                                                    name="policyId"
                                                    value={selectedPolicy}
                                                    control={sendControl}
                                                    onChange={handleInputChange}
                                                    label={t('carClaimForm.claimed-policy')}
                                                    fullWidth
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    endAdornment={isLoadingPolicy &&
                                                        <InputAdornment position="end" style={{marginRight: 10}}>
                                                            <img src={spinner} className="spinner-img"
                                                                 alt="spinner"/>
                                                        </InputAdornment>
                                                    }
                                                    SelectDisplayProps={
                                                        isLoadingPolicy ? {
                                                            style: {
                                                                'opacity': '0.4',
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600,
                                                                'width': '100%'
                                                            }
                                                        } : {
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600,
                                                                'width': '100%'
                                                            }
                                                        }}>
                                                    {
                                                        policies.map((policy, index) => (
                                                            <MenuItem key={index + 1} value={policy.id}>
                                                                {policy.type} - {policy.company}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                <FormHelperText>{t('carClaimForm.claimed-policy-helper-text')}</FormHelperText>
                                            </FormControl>
                                            <Row className="mt-4 mb-2">
                                                <Col>
                                                    <MobileDatePicker
                                                        className="w-100" id="date" name="date"
                                                        required
                                                        label={t('carClaimForm.date-label')}
                                                        value={selectedDate}
                                                        control={claimId === undefined ? attachControl : sendControl}
                                                        onChange={handleDateChange}
                                                        renderInput={(params) => <TextField
                                                            style={{backgroundColor: themeContext.color.white}} {...params} />}
                                                        format="DD/MM/YYYY"
                                                        disableFuture/>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <MobileTimePicker
                                                        className="w-100" id="time" name="time"
                                                        required
                                                        label={t('carClaimForm.time-label')}
                                                        value={selectedDate}
                                                        control={claimId === undefined ? attachControl : sendControl}
                                                        onChange={handleDateChange}
                                                        renderInput={(params) => <TextField
                                                            style={{backgroundColor: themeContext.color.white}} {...params} />}
                                                        ampm={false}/>
                                                </Col>
                                            </Row>
                                            <TextField
                                                multiline
                                                id="description"
                                                name="description"
                                                className="mt-4 mb-4"
                                                minRows={7}
                                                fullWidth
                                                control={sendControl}
                                                label={t('genericClaimForm.description-label')}
                                                placeholder={t('genericClaimForm.description-label')}
                                                variant="outlined"
                                                type="text"
                                                defaultValue={formValues.current.description}
                                                onChange={handleInputChange}
                                                color="primary"
                                                style={{'backgroundColor': themeContext.color.white}}
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        'color': themeContext.color.neutral600
                                                    }
                                                }}
                                            />
                                            <Row className="mt-4 mb-4 justify-content-center">
                                                {
                                                    isDesktop && sessionStorage.getItem(`${claimId}_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_images`)).length > 0 && imagesUrl.map((item, index) => (
                                                        <div key={index}>
                                                            {
                                                                index <= 2 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px'
                                                                        }} src={item}
                                                                             onClick={claimId === undefined ? () => createClaim() : () => updateClaim()}/>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                            {
                                                                index === 3 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px',
                                                                            opacity: '0.5'
                                                                        }} src={item}
                                                                             onClick={claimId === undefined ? () => createClaim() : () => updateClaim()}/>
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',
                                                                            fontWeight: '600',
                                                                            fontSize: '32px',
                                                                            color: themeContext.color.neutral600
                                                                        }}
                                                                             onClick={claimId === undefined ? () => createClaim() : () => updateClaim()}>
                                                                            +{imagesUrl.length - 3}</div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !isDesktop && sessionStorage.getItem(`${claimId}_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_images`)).length > 0 && imagesUrl.map((item, index) => (
                                                        <div key={index}>
                                                            {
                                                                index <= 1 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px'
                                                                        }} src={item}
                                                                             onClick={claimId === undefined ? () => createClaim() : () => updateClaim()}/>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                            {
                                                                index === 2 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px',
                                                                            opacity: '0.5'
                                                                        }} src={item}
                                                                             onClick={claimId === undefined ? () => createClaim() : () => updateClaim()}/>
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',
                                                                            fontWeight: '600',
                                                                            fontSize: '32px',
                                                                            color: themeContext.color.neutral600
                                                                        }}
                                                                             onClick={claimId === undefined ? () => createClaim() : () => updateClaim()}>
                                                                            +{imagesUrl.length - 2}</div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Row>
                                            <Row className="justify-content-center mb-3">
                                                <Col>
                                                    <Button type={"button"}
                                                            disabled={isFormInvalid}
                                                            onClick={claimId === undefined ? createClaim : updateClaim}
                                                            className={isFormInvalid ? "btn-custom-disable-2 cursor-pointer" : "btn-primary-custom cursor-pointer"}
                                                            style={isFormInvalid ? {
                                                                background: themeContext.color.neutral400,
                                                                height: '40px',
                                                                textTransform: 'none',
                                                                width: isDesktop ? 'max-content' : '-webkit-fill-available'
                                                            } : {
                                                                background: themeContext.color.main,
                                                                height: '40px',
                                                                textTransform: 'none',
                                                                width: isDesktop ? 'max-content' : '-webkit-fill-available'
                                                            }}>
                                                        {
                                                            isLoadingCreate &&
                                                            <img src={spinner} className="spinner-img" alt="spinner"/>
                                                        }
                                                        {
                                                            !isLoadingCreate && t('genericClaimForm.append-image-label')
                                                        }
                                                    </Button>
                                                </Col>
                                                {
                                                    imagesRequired &&
                                                    <Col className="col-auto mt-1">

                                                        <span className="footer-text"
                                                              style={{
                                                                  'color': themeContext.color.red,
                                                                  'paddingTop': '5px'
                                                              }}>
                                                            {t('carClaimForm.attach-image-error')}
                                                        </span>

                                                    </Col>
                                                }
                                            </Row>
                                            <Row className="mt-2 mb-4">
                                                <Col style={{textAlign: 'left'}}>
                                                    <span className="font-weight-bold">
                                                        {t('genericClaimForm.note-1')}
                                                    </span><br/>
                                                    <span>{t('genericClaimForm.note-2')}</span><br/>
                                                    <span>{t('genericClaimForm.note-3')}</span><br/>
                                                    <span>{t('genericClaimForm.note-4')}</span><br/>
                                                    <span>{t('genericClaimForm.note-5')}</span><br/>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2 mb-4">
                                                <Col style={{textAlign: 'left'}}>
                                                    <span
                                                        style={{color: themeContext.color.neutral400}}>{t('carClaimForm.required')}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <Button className="close-button"
                                                            variant="outlined"
                                                            size="large"
                                                            name="send"
                                                            style={{
                                                                color: themeContext.color.white,
                                                                width: '288px',
                                                                textTransform: 'none'
                                                            }}
                                                            type={'submit'}>
                                                        {
                                                            isLoadingSend &&
                                                            <img src={spinner} className="spinner-img" alt="spinner"/>
                                                        }
                                                        {!isLoadingSend && t('carClaimForm.send-button')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Fragment>
                                }
                                {
                                    isSent &&
                                    <Row className="pr-0 pl-0">
                                        <Col className="col-list">
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <img src={emailPlaceholder} alt="email-placeholder"
                                                             width="100%"
                                                             style={{'maxHeight': '340px'}}/>
                                                    </Col>
                                                </Row>
                                                <Row style={{'marginTop': '43.5px'}}>
                                                    <Col className="pl-5 pr-5">
                                            <span style={{
                                                'fontSize': '16px',
                                                'color': themeContext.color.neutral600,
                                                'fontWeight': 'bold'
                                            }}>{t('carClaimForm.send-title')}</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    'marginTop': '12px',
                                                    'marginBottom': '44.5px'
                                                }}>
                                                    <Col>
                                            <span style={{
                                                'fontSize': '14px',
                                                'color': themeContext.color.neutral600,
                                                'fontWeight': 'normal'
                                            }}>{t('carClaimForm.send-payoff-1')}</span><br/>
                                                        <span style={{
                                                            'fontSize': '14px',
                                                            'color': themeContext.color.neutral600,
                                                            'fontWeight': 'normal'
                                                        }}>{t('carClaimForm.send-payoff-2')}</span>
                                                    </Col>
                                                </Row>
                                                <Container
                                                    style={{
                                                        'background': 'transparent',
                                                        'paddingBottom': "40px"
                                                    }}>
                                                    <Row>
                                                        <Col>
                                                            <Button className="btn-custom"
                                                                    variant="outlined"
                                                                    size="large"
                                                                    style={{'color': themeContext.color.white}}
                                                                    type="submit"
                                                                    onClick={() => navigate('/claims')}
                                                            >{t('carClaimForm.close-button')}</Button>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Container>
                                        </Col>
                                    </Row>
                                }
                            </Fragment>
                    }
                </Container>
                <Dialog
                    onClose={() => {
                        if (new_ids.current.length > 0) {
                            setOpenDoNotSaveDialog(true)
                        } else {
                            setOpenUploadDialog(false)
                        }
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openUploadDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">


                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close"
                                 onClick={() => {
                                     if (new_ids.current.length > 0) {
                                         setOpenDoNotSaveDialog(true)
                                     } else {
                                         setOpenUploadDialog(false)
                                     }
                                 }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left text-left">{t('attachImagesToClaimForm.title')}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {t('carClaimForm.upload-description')}
                                </h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container" style={{paddingBottom: '120px'}}>
                            <div style={{display: "flex", gap: "24px"}}
                                 className={isDesktop ? 'flex-wrap justify-content-center mt-4' : 'flex-wrap justify-content-between mt-4'}>
                                <div className={imagesUrl.length < 15 ? 'add-active-icon add-icon' : 'add-icon'}>
                                    <img key={0} src={addIcon} width={'100%'} height={'100%'}
                                         alt={`image-0`}
                                         onClick={() => attachImageActions(documents.type, imagesUrl.length)}
                                    />
                                    <img hidden className="trash-button" src={trashIcon} alt="trash-icon"/>
                                    <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                           accept="image/*"/>
                                </div>
                                {
                                    images.map((item, index) => (
                                        <div key={index} className={'div-grid'}>
                                            <img src={item.url} alt={`image-${index}`} className={'image-grid'}
                                                 onClick={() => {
                                                     openLightboxOnSlide(index);
                                                 }}
                                            />
                                            <img className="trash-button" src={trashIcon} alt="trash-icon"
                                                 onClick={() => openDeleteImageDialog(item.id)}/>
                                        </div>
                                    ))
                                }
                                <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={imagesUrl}
                                    type="image"
                                    sourceIndex={lightboxController.slide}
                                    key={imagesUrl.length}
                                />
                            </div>
                            {
                                uploadError &&
                                <Row className="ml-4 mr-4 mt-4">
                                    <Col className="mt-4 text-center dialog-form-title-3 red">
                                        <span dangerouslySetInnerHTML={{__html: uploadError}}/>
                                    </Col>
                                </Row>
                            }
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button boxShadow"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                                disabled={false}
                                                onClick={() => {
                                                    sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                                                    new_ids.current = []
                                                    setOpenUploadDialog(false)
                                                }}
                                        >{t('regulations.save-pdf-button')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction open={openDialog2}
                                    title={t('genericClaimForm.irreversible-action-title')}
                                    description={t('genericClaimForm.irreversible-action-description')}
                                    handleClose={() => setOpenDialog2(false)}
                                    handleNo={() => setOpenDialog2(false)}
                                    deleteAction={true}
                                    handleYes={() => handleDeleteImage(imageIdToDelete)}
                                    buttonYesText={t('genericClaimForm.irreversible-action-done')}
                                    buttonNoText={t('genericClaimForm.irreversible-action-undone')}/>
                <DialogDoubleAction open={openDoNotSaveDialog}
                                    title={t('genericClaimForm.do-not-save-title')}
                                    description={t('genericClaimForm.do-not-save-description')}
                                    handleClose={() => setOpenDoNotSaveDialog(false)}
                                    handleNo={() => setOpenDoNotSaveDialog(false)}
                                    deleteAction={true}
                                    handleYes={doNotSave}
                                    buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                    buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => {
                                if (data) {
                                    setCropper(data)
                                }
                            }}/>
            </LocalizationProvider>
    )
}