import React from 'react'
import Dialog from "@mui/material/Dialog";
import themeContext from "./ThemeContext.js";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ticIcon from "../../images/tic-white-icon.svg";
import {StyledBackdrop} from "../styles/Styles.js";

const DialogOperationComplete = ({open, title}) => {
    return (
        <Dialog
            components={{Backdrop: StyledBackdrop}}
            PaperProps={{
                style: {
                    'color': themeContext.color.neutral600,
                    background: 'transparent',
                    boxShadow: 'none'
                }
            }}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"
                         className="dialog-title">
            </DialogTitle>
            <DialogContent>
                <div style={{display: 'flex'}} className={'justify-content-center flex-wrap flex-column'}>
                    <div style={{textAlign: 'center'}}>
                        <img src={ticIcon} alt={'tic-icon'} height={'68px'}/>
                    </div>
                    <div style={{textAlign: 'center', padding: '10%'}}>
                        <h6 className={'font-weight-bold text-white'}>{title}</h6>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default DialogOperationComplete;