import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next'
import {withSize} from "react-sizeme";
import {Button, ListItemButton, Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Resizer from "react-image-file-resizer";
import spinner from "../../images/spinner.svg";
import {useMediaQuery} from "react-responsive";
import Avatar from "@mui/material/Avatar";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import exitIcon from "../../images/exit-icon.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import documentPersonIcon from "../../images/documentPerson.svg";
import documentTypeIcon from "../../images/documentType.svg";
import documentTypeActiveIcon from "../../images/documentTypeActive.svg";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import personalDocument from "../../images/personal-documents-alt-icon.svg";
import checkboxSelectedIcon from "../../images/checkbox-selected.svg";
import checkboxUnselectedIcon from "../../images/checkbox-unselected.svg";
import exit from "../../images/exit-icon.svg";
import ticIcon from "../../images/tic-icon.svg";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import CropDialog from "../components/CropDialog.js";
import themeContext from "../components/ThemeContext.js";
import DialogOperationComplete from "../components/DialogOperationComplete.js";
import {StyledBackdrop} from "../styles/Styles.js";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const useStyles = makeStyles((theme) => ({
    avatarList: {
        width: "32px !important",
        height: "32px !important",
        marginRight: "16px !important"
    },
    avatar: {
        margin: '0 auto !important',
        width: '74px !important',
        height: '74px !important',
    },
    dialog: {
        color: `${themeContext.color.neutral600} !important`,
        padding: '24px !important'
    },
    dialogTitle: {
        textAlign: 'left !important',
        font: 'normal normal 700 20px/28px Inter !important',
        paddingBottom: '24px !important'
    },
    dialogDescription: {
        textAlign: 'left !important',
        font: 'normal normal normal 14px/140% Inter !important',
        paddingBottom: '24px !important'
    },
    dialogAction: {
        display: 'flex !important',
        justifyContent: 'flex-end !important',
    },
    dialogButton: {
        textTransform: 'none !important',
        fontSize: '16px !important'
    }
}));

function UploadNewDocument({size}) {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const personId = params.personId;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const documentId = query.get('documentId');
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const getGroupDocumentByIdUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group`;
    const attachDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/attachDocument`;
    const deleteDocumentFromGroupUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/removeDocument`;
    const setFavouriteDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/saveFavorite`;
    const removeFavouriteDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/removeFavorite`;
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleLite`;
    const getAllGroupDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    //const [toggler, setToggler] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(-1);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [serviceError, setServiceError] = useState(false);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'});
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [person, setPerson] = useState([]);
    const [fav, setFav] = useState(-1);
    const authCtx = useContext(AuthContextMiddleware);
    const pendingDocument = authCtx.pendingDocument
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [peopleDivVisibility, setPeopleDivVisibility] = useState(false);
    const [typeDivVisibility, setTypeDivVisibility] = useState(false);
    const [isFavourite, setIsFavourite] = useState(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false)
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState({id: null});
    const [documentType, setDocumentType] = useState(0);
    const [documentTypeError, setDocumentTypeError] = useState(false);
    const [selectedPeopleError, setSelectedPeopleError] = useState(false);
    const [people, setPeople] = useState([]);
    const [user, setUser] = useState("");
    const [openSaveIntoPersonalDocumentsDialog, setOpenSaveIntoPersonalDocumentsDialog] = useState(false);
    const [dontShowAnymore, setDontShowAnymore] = useState(false);
    const [isLoadingPeopleData, setIsLoadingPeopleData] = useState(false);
    const [openSaveCompletedDialog, setOpenSaveCompletedDialog] = useState(false);
    const [saveDocumentHidden, setSaveDocumentHidden] = useState(false);
    const [doneButtonDisable, setDoneButtonDisable] = useState(false);
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);


    function reload() {
        window.location.reload();
    }

    useEffect(() => {
        if (navigator.onLine) {
            if (sessionStorage.getItem('notShowPersonalDocuments') !== 'true') {
                setOpenSaveIntoPersonalDocumentsDialog(true)
            }
            if (pendingDocument.base64 && pendingDocument.url) {
                //console.log('immagini presenti')
            } else {
                //console.log('immagini non presenti')
                setOpenFallbackDialog(true)
            }
            getInfo();
            getPeople();
        }
    }, []);

    const turnBack = () => {
        if (window.location.href.includes('signing')) {
            return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        } else if (window.location.href.includes('refunds')) {
            return navigate(`/refunds/${personId}/upload/personalDocuments`);
        } else if (window.location.href.includes('bank-references')) {
            return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments`);
        } else if (window.location.href.includes('invitation')) {
            return navigate(`/settings/people/${personId}/invitation/personalDocuments`);
        } else if (window.location.href.includes('settings')) {
            return navigate(`/settings/personal-documents`);
        }
    }

    const getDocumentGroupById = () => {
        setIsLoadingData(true)
        setImages([])
        setImagesUrl([])
        fetch(`${getGroupDocumentByIdUrl}/${documentGroupId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let lengthDocs = Object.keys(data.documents).length;
            setDocuments(data)
            data.documents.map((doc, index) => {
                if (doc.isFavorite === true) {
                    setFav(index)
                }
            })
            getPerson(data.personId)
            if (lengthDocs !== 0) {
                for (let i = 0; i < lengthDocs; i++) {
                    setImages(current => [...current, data.documents[i]])
                    setImagesUrl(current => [...current, data.documents[i].document])
                }
            }
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(attachDocumentUrl, {
                method: 'POST',
                body: JSON.stringify({
                    groupId: documentGroupId,
                    documents: [
                        {
                            name: randomName,
                            mimeType: mimetype,
                            base64: base64_string,
                        }
                    ]
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                    //'Access-Control-Allow-Origin': 'no-cors'
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false);
                getDocumentGroupById();
            }).catch(() => {
                setServiceError(true)
            });
        }
    }

    const rotate = (method) => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        switch (method) {
            case 'left':
                cropper.rotate(-90);
                break;
            case 'right':
                cropper.rotate(90);
                break;
            case 'flip-x':
                cropper.scaleX(scaleX === 1 ? -1 : 1);
                setScaleX(scaleX === 1 ? -1 : 1);
                break;
            case 'flip-y':
                cropper.scaleY(scaleY === 1 ? -1 : 1);
                setScaleY(scaleY === 1 ? -1 : 1);
                break;
        }
    }

    const handleDeleteGroup = () => {
        fetch(`${getGroupDocumentByIdUrl}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: documentGroupId,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            navigate(-1)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleDeleteImage = (index) => {
        fetch(`${deleteDocumentFromGroupUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                groupId: documentGroupId,
                documentIndex: index
            }),
        }).then((response) => {

            return response.json();
        }).then((data) => {
            if (fav === index) {
                setFav(-1)
            }
            getDocumentGroupById();
            setOpenDialog2(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPerson(personId) {
        setIsLoadingPerson(true)
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPerson(data);
            }
            setIsLoadingPerson(false)
        }).catch(() => {
            setServiceError(true)
        })
    }

    const navigateToCameraRecognition = () => {
        authCtx.storeReqImage(pendingDocument.url)
        authCtx.storeReqImageBase64(pendingDocument.base64)
        if (window.location.href.includes('signing')) {
            return navigate(`/signing/${personId}/upload/personalDocuments/new/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
        } else if (window.location.href.includes('refunds')) {
            return navigate(`/refunds/${personId}/upload/personalDocuments/new/identify`)
        } else if (window.location.href.includes('bank-references')) {
            return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments/new/identify`)
        } else if (window.location.href.includes('invitation')) {
            return navigate(`/settings/people/${personId}/invitation/personalDocuments/new/identify`)
        }
    }

    const createGroupWithAttachedDocument = () => {
        if (selectedPeople.id !== null && documentType !== 0) {
            //setIsLoadingCreate(true)
            setOpenUploadDialog(false)
            fetch(getAllGroupDocumentUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    personId: `${selectedPeople.id}`,
                    type: documentType,
                    documents: [
                        {
                            name: pendingDocument.name,
                            mimeType: pendingDocument.mimeType,
                            base64: pendingDocument.base64,
                            isFavorite: isFavourite
                        }
                    ]
                }),
            }).then((res) => {
                setSaveDocumentHidden(true)
                setDoneButtonDisable(true)
                authCtx.storeReqImage(pendingDocument.url)
                authCtx.storeReqImageBase64(pendingDocument.base64)
                return res.json();
            }).then((data) => {
                setTimeout(() => {
                    setOpenSaveCompletedDialog(true)
                }, 200)
                setTimeout(() => {
                    setOpenSaveCompletedDialog(false)
                    setDoneButtonDisable(false)
                }, 1200)
            }).catch(() => {
                setServiceError(true)
            });
        } else {
            if (selectedPeople.id === null) {
                setSelectedPeopleError(true)
            } else {
                setSelectedPeopleError(false)
            }
            if (documentType === 0) {
                setDocumentTypeError(true)
            } else {
                setDocumentTypeError(false)
            }
            setIsLoadingCreate(false)
        }
    }

    function getInfo() {
        setUser([])
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setUser(data)
                //setSelectedPeople(data.policyPersonId)
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getPeople = () => {
        setIsLoadingPeopleData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople(data)
            setIsLoadingPeopleData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2"></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top-shadow container col-list" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-2 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-8">
                            <h6 className="header-title ellipsis-1-v2">
                                <span>{t('uploadNewDocument.title')}</span>
                            </h6>
                        </Col>
                        <Col className="col-2">
                            <img src={exit} onClick={() => setOpenExitDialog(true)}
                                 className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                        </Col>
                    </Row>
                    <Container className="top-of-the-preferences"></Container>
                    <div className="mt-4 flex-wrap justify-content-between" style={{
                        display: "flex",
                        gap: "24px",
                        paddingBottom: "120px"
                    }}>
                        <div style={isDesktop ? {width: '50%', margin: '0 auto'} : {width: '100%'}}>
                            <img src={pendingDocument.url} className={'image-grid'} alt={`new-image`}/>
                        </div>
                    </div>
                </Container>
                <Container className="fixed-bottom"
                           style={{'paddingBottom': "40px"}}>
                    <Row>
                        <Col>
                            <Button
                                disabled={doneButtonDisable}
                                className="primary-button"
                                variant="outlined"
                                onClick={navigateToCameraRecognition}
                                style={!isDesktop ? {width: '80%'} : {width: '300px'}} type="submit">
                                {t('uploadNewDocument.done-button')}
                            </Button>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col>
                            <Button
                                hidden={saveDocumentHidden}
                                className="outline-button"
                                variant="outlined"
                                onClick={() => setOpenUploadDialog(true)}
                                style={!isDesktop ? {width: '80%'} : {width: '300px'}} type="submit">
                                {t('uploadNewDocument.save-button')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <DialogDoubleAction open={openDialog}
                                    title={t('documentsList.modal-title')}
                                    description={t('documentsList.modal-description')}
                                    handleClose={() => setOpenDialog(false)}
                                    handleNo={() => setOpenDialog(false)}
                                    handleYes={handleDeleteGroup}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>
                <DialogSmall open={openPhotoLimitsDialog}
                             title={t('documentsList.limit-photo-modal-title')}
                             description={t('documentsList.limit-photo-modal-description')}
                             handleClose={() => setOpenPhotoLimitsDialog(false)}
                             buttonText={t('documentsList.limit-photo-modal-button')}/>
                <DialogDoubleAction open={openDialog2}
                                    title={t('documentsList.modal-title-2')}
                                    description={t('documentsList.modal-description-2')}
                                    handleClose={() => setOpenDialog2(false)} handleNo={() => setOpenDialog2(false)}
                                    handleYes={() => handleDeleteImage(indexToDelete)}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => {
                                if(data) {
                                    setCropper(data)
                                }
                            }}/>
                <Dialog
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openUploadDialog}
                    TransitionComponent={Transition}
                    onClose={() => {
                        setOpenUploadDialog(false)
                    }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                                setOpenUploadDialog(false)
                            }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left">Salva documento</h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container"
                             style={{paddingBottom: "96px"}}>
                            {isLoadingData &&
                                <div style={{'height': '30vh'}} className="background-loading-spinner"></div>
                            }
                            {
                                !isLoadingData &&
                                <div>
                                    <div className="mt-4 flex-wrap justify-content-between" style={{
                                        display: "flex",
                                        gap: "16px",
                                        paddingBottom: "16px"
                                    }}>
                                        <div style={{width: '100%'}}>
                                            <img src={pendingDocument.url} className={'image-grid'}
                                                 alt={`new-image`}/>
                                        </div>
                                        <ClickAwayListener
                                            onClickAway={() => {
                                                setPeopleDivVisibility(false)
                                                setTypeDivVisibility(false)
                                            }}>

                                            <div style={{display: "flex", gap: "16px", width: '100%'}}
                                                 className={'flex-row justify-content-between position-relative'}>
                                                <div className={'personal-documents-select cursor-pointer'}
                                                     onClick={() => setPeopleDivVisibility(true)}>
                                                    {
                                                        selectedPeople.id === null &&
                                                        <img height={'80px'}
                                                             src={documentPersonIcon}
                                                             alt={'select-user'}/>
                                                    }
                                                    {
                                                        selectedPeople.id !== null &&
                                                        <Fragment>
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === 'M' &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseMaleAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === 'F' &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseFemaleAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === 'C' &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseCompanyAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === null &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseMaleAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image &&
                                                                <Avatar className={classes.avatar}
                                                                        src={selectedPeople.image}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                        </Fragment>
                                                    }
                                                    <span
                                                        className={'align-center personal-documents-select-title'}>
                                                        {selectedPeople.id !== null ?
                                                            <Fragment>
                                                                {selectedPeople.firstName === null && selectedPeople.lastName === null ?
                                                                    <span>{selectedPeople.name}</span> :
                                                                    <span>{selectedPeople.firstName} {selectedPeople.lastName}</span>}
                                                            </Fragment> :
                                                            selectedPeopleError === true ?
                                                                <span className={'red'}>
                                                        {t('personalDocuments.modal-select-people')}
                                                            </span>
                                                                :
                                                                <span>
                                                        {t('personalDocuments.modal-select-people')}
                                                            </span>
                                                        }
                                                    </span>
                                                </div>
                                                <div className={'personal-documents-select cursor-pointer'}
                                                     onClick={() => setTypeDivVisibility(true)}>
                                                    <img height={'80px'}
                                                         src={documentType === 0 ? documentTypeIcon : documentTypeActiveIcon}
                                                         alt={'select-user'}/>
                                                    <span
                                                        className={'align-center personal-documents-select-title'}>
                                                        {
                                                            documentTypeError ?
                                                                <span className={'red'}>
                                                                    {t('personalDocuments.modal-select-document-type')}
                                                                </span> :
                                                                <Fragment>
                                                                    {documentType === 0 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.modal-select-document-type')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 1 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-1')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 2 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-2')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 3 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-3')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 4 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-4')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 5 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-5')}
                                                                        </span>
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </span>
                                                </div>
                                                <div hidden={!peopleDivVisibility} className={'select-div-list'}>
                                                    <List component="nav">
                                                        {
                                                            people.map((value, index) => (
                                                                <Fragment key={index}>
                                                                    <ListItemButton style={{padding: '16px'}}
                                                                              onClick={() => {
                                                                                  if (selectedPeople.id === value.id) {
                                                                                      setSelectedPeople({id: null})
                                                                                  } else {
                                                                                      setSelectedPeople(value)
                                                                                  }
                                                                                  setSelectedPeopleError(false)
                                                                                  setPeopleDivVisibility(false)
                                                                              }}>
                                                                        {
                                                                            value.image === null && value.gender === 'M' &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseMaleAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === 'F' &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseFemaleAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === 'C' &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseCompanyAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === null &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseMaleAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={value.image}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        <Fragment>
                                                                            {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                                <span
                                                                                    className={'darkgrey'}>{value.name}</span> :
                                                                                <span
                                                                                    className={'darkgrey'}>{value.firstName} {value.lastName}</span>}
                                                                        </Fragment>
                                                                    </ListItemButton>
                                                                    {index + 1 !== people.length && <Divider style={{
                                                                        marginLeft: '16px',
                                                                        marginRight: '16px'
                                                                    }}/>}
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                </div>
                                                <div hidden={!typeDivVisibility} className={'select-div-list'}>
                                                    <List component="nav">
                                                        <ListItemButton style={{padding: '16px'}} value={1}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 1) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(1)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-1')}
                                                        </ListItemButton>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItemButton style={{padding: '16px'}} value={2}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 2) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(2)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-2')}
                                                        </ListItemButton>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItemButton style={{padding: '16px'}} value={3}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 3) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(3)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-3')}
                                                        </ListItemButton>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItemButton style={{padding: '16px'}} value={4}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 4) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(4)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-4')}
                                                        </ListItemButton>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItemButton style={{padding: '16px'}} value={5}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 5) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(5)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-5')}
                                                        </ListItemButton>
                                                    </List>
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                    <div className={'flex-wrap flex-row'}
                                         style={{display: 'flex', marginTop: '60px', gap: '16px'}}>
                                        <div onClick={() => setIsFavourite(current => !current)}>
                                            <img
                                                src={isFavourite ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                alt="checkbox" height="20px"/>
                                        </div>
                                        <div onClick={() => setIsFavourite(current => !current)}>
                                            <h6 className={'darkgrey'}
                                                style={{textDecoration: 'underline'}}>{t('personalDocuments.save-favourite')}</h6>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button
                                            className={"primary-button"}
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="submit"
                                            onClick={() => {
                                                createGroupWithAttachedDocument()
                                            }}>
                                            {
                                                isLoadingCreate ?
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                    :
                                                    t('personalDocuments.save-button')
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction
                    open={openExitDialog}
                    handleClose={() => setOpenExitDialog(false)}
                    title={t('dialog.interruption-title')}
                    description={t('dialog.interruption-description')}
                    buttonYesText={t('dialog.button-yes')}
                    handleYes={() => {
                        if (window.location.href.includes('signing')) {
                            navigate('/signing');
                        } else if (window.location.href.includes('refunds')) {
                            navigate(`/refunds`);
                        } else if (window.location.href.includes('invitation')) {
                            navigate(`/settings/people/${personId}`);
                        } else if (window.location.href.includes('bank-references')) {
                            navigate(`/settings/people/${personId}/bank-references`);
                        }
                    }}
                    buttonNoText={t('dialog.button-no')}
                    handleNo={() => setOpenExitDialog(false)}
                ></DialogDoubleAction>
                <Dialog
                    components={{Backdrop: StyledBackdrop}}
                    PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
                    open={openSaveIntoPersonalDocumentsDialog}
                    onClose={() => setOpenSaveIntoPersonalDocumentsDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <h6 className={classes.dialogTitle}>{t('uploadNewDocument.dialog-title')}</h6>
                    <div>
                        <h6 className={classes.dialogDescription}>
                            {t('uploadNewDocument.dialog-description')}
                        </h6>
                        <div className={'flex-wrap flex-row'}
                             style={{display: 'flex', marginTop: '16px', gap: '16px'}}>
                            <div onClick={() => setDontShowAnymore(current => !current)}>
                                <img
                                    src={dontShowAnymore ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                    alt="checkbox" height="20px"/>
                            </div>
                            <div onClick={() => setDontShowAnymore(current => !current)}>
                                <span className={'darkgrey'}
                                      style={{
                                          textDecoration: 'underline',
                                          fontSize: 16
                                      }}>{t('uploadNewDocument.dialog-dont-show-anymore')}</span>
                            </div>
                        </div>
                    </div>
                    <div className={classes.dialogAction}>
                        <Button className={`${classes.dialogButton} grey`} onClick={() => {
                            setOpenSaveIntoPersonalDocumentsDialog(false)
                            if (dontShowAnymore === true) {
                                sessionStorage.setItem('notShowPersonalDocuments', true);
                            }
                        }}>
                            {t('uploadNewDocument.dialog-not-now-button')}
                        </Button>
                        <Button className={classes.dialogButton} onClick={() => {
                            setOpenSaveIntoPersonalDocumentsDialog(false)
                            setOpenUploadDialog(true)
                            if (dontShowAnymore === true) {
                                sessionStorage.setItem('notShowPersonalDocuments', true);
                            }
                        }} color="primary">
                            {t('uploadNewDocument.dialog-save-button')}
                        </Button>
                    </div>
                </Dialog>
                <DialogOperationComplete open={openSaveCompletedDialog}
                                         title={t('uploadNewDocument.dialog-save-completed')}/>
                <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                             title={t('uploadDocumentToVerify.fallback-dialog-title')}
                             description={t('uploadDocumentToVerify.fallback-dialog-description')}
                             handleClose={() => {
                                 if (window.location.href.includes('signing')) {
                                     navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                                 } else if (window.location.href.includes('refunds')) {
                                     return navigate(`/refunds`);
                                 } else if (window.location.href.includes('bank-references')) {
                                     return navigate(`/settings/people/${personId}/bank-references`);
                                 } else if (window.location.href.includes('invitation')) {
                                     return navigate(`/settings/people/${personId}/invitation/personalDocuments`);
                                 } else if (window.location.href.includes('settings')) {
                                     return navigate(`/settings/personal-documents`);
                                 }
                                 setOpenFallbackDialog(false)
                             }}
                             open={openFallbackDialog}/>
            </Fragment>
    )
}

export default withSize()(UploadNewDocument)