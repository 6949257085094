import React from 'react';

const Unchecked = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Icon_material-check-box-outline-blank" data-name="Icon material-check-box-outline-blank" d="M22.278,6.722V22.278H6.722V6.722H22.278m0-2.222H6.722A2.229,2.229,0,0,0,4.5,6.722V22.278A2.229,2.229,0,0,0,6.722,24.5H22.278A2.229,2.229,0,0,0,24.5,22.278V6.722A2.229,2.229,0,0,0,22.278,4.5Z" transform="translate(-4.5 -4.5)" fill="#545e75"/>
        </svg>
    )
}

export default Unchecked;