import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage, deleteToken} from "firebase/messaging";
import React, {useEffect} from "react";
import * as idb from "idb";

const firebaseConfig = {
    apiKey: "AIzaSyBNcLh8V77bu96k9jeFCw8PFA_pWezdIwc",
    authDomain: "pwa-push-poc-2.firebaseapp.com",
    projectId: "pwa-push-poc-2",
    messagingSenderId: "510872191983",
    appId: "1:510872191983:web:38b62f1c6255d893b1ac1a"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = () => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
        () => {
            const saveTokenUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/saveToken`;
            getToken(messaging, {vapidKey: 'BL7nUQm1yok3neiSWs3dwtNUniN5LdH4L-orJQE5G6ErUw8FFL6CFq1b81WlJ5M-XWmzlHhvGbxSCp2lRYQNiqU'}).then((currentToken) => {
                if (currentToken) {
                    sessionStorage.setItem('firebaseToken', currentToken);
                    //console.log('current token for client: ', currentToken);
                    const dbPromise = idb.openDB('appDB', 1, {});
                    dbPromise.then((db) => {
                        let tx = db.transaction('token', 'readwrite').objectStore('token');
                        return tx.get('token')
                    }).then((token) => {
                        fetch(saveTokenUrl, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token.value}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                token: currentToken,
                            }),
                        }).then((response) => {
                            if (response.ok) {
                                return response;
                            }
                        }).catch(() => {
                            console.log('save token error')
                        });
                    });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                    // shows on the UI that permission is required
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // catch error while creating client token
            });
        }, []
    );
}

export const onMessageListener = () => new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        console.log(payload)
        resolve(payload);
    });
});
